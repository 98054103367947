import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { FC, useMemo, useState } from 'react';
import { RgbColor, RgbColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import OutlineButton from '../../../../../components/OutlineButton';
import { AnnotationClass } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { generateRGBObject, generateRGBString } from '../../../../../utils/color';
import { saveAnnotationClasses, updateAnnotationClass } from '../../projectAsyncThunks';

const CLASS_NAME_LIMIT = 50;
const CLASS_HOTKEY_LIMIT = 1;
const CLASS_DESCRIPTION_LIMIT = 500;
const DEFAULT_RGB = { r: 232, g: 255, b: 47 };

export enum FormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

type Props = {
  targetClass?: AnnotationClass;
  formMode: FormMode;
  onClose: () => void;
  onCreateClassSuccess?: () => Promise<void>;
  onEditClassSuccess?: () => Promise<void>;
};

type FormState = AnnotationClass;

type FormError = {
  name: { value: boolean; message: string };
  color: { value: boolean; message: string };
  hotKey: { value: boolean; message: string };
};

const AnnotationClassSetupModal: FC<Props> = ({
  targetClass,
  formMode,
  onClose,
  onCreateClassSuccess,
  onEditClassSuccess,
}) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>(
    targetClass ||
      ({
        name: '',
        color: generateRGBString(DEFAULT_RGB),
        hotKey: '',
        description: '',
      } as AnnotationClass),
  );
  const [formError, setFormError] = useState<FormError>({
    name: { value: false, message: '' },
    color: { value: false, message: '' },
    hotKey: { value: false, message: '' },
  });
  const { annotationClasses } = useSelector((state: RootState) => state.project);

  const submitable = useMemo(() => {
    if (formMode === FormMode.CREATE) {
      if (formError.name.value || formError.hotKey.value) return false;
      return !!formState.name && !!formState.color;
    }

    if (formMode === FormMode.EDIT) {
      if (!targetClass) return false;
      if (formError.name.value || formError.hotKey.value) return false;
      return (
        targetClass.name !== formState.name ||
        targetClass.color !== formState.color ||
        targetClass.hotKey !== formState.hotKey ||
        targetClass.description !== formState.description
      );
    }
  }, [targetClass, formMode, formState, formError]);

  const dispatch = useDispatch<AppDispatch>();

  const handleClassNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;

    if (name.length > CLASS_NAME_LIMIT) {
      return;
    }

    const nameExist = annotationClasses.some((annotationClass) => {
      if (formMode === FormMode.EDIT) {
        return name === annotationClass.name && name !== targetClass?.name;
      }

      return annotationClass.name === name;
    });

    if (!name) {
      setFormError((formError) => ({
        ...formError,
        name: { value: true, message: 'Please enter class name' },
      }));
    } else if (nameExist) {
      setFormError((formError) => ({
        ...formError,
        name: { value: true, message: 'Class name has been used' },
      }));
    } else {
      setFormError((formError) => ({
        ...formError,
        name: { value: false, message: '' },
      }));
    }

    setFormState({
      ...formState,
      name,
    });
  };

  const handleClassHotkeyChange = (value: string) => {
    const regex = /[^0-9]/g;
    const hotKey = value.replace(regex, '').slice(0, CLASS_HOTKEY_LIMIT);

    if (!hotKey) {
      setFormError({
        ...formError,
        hotKey: {
          value: false,
          message: '',
        },
      });
      setFormState({
        ...formState,
        hotKey: hotKey as '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9',
      });
      return;
    }

    const hotKeyExist = annotationClasses
      .filter(({ hotKey }) => !!hotKey)
      .some(({ hotKey: _hotKey }) => {
        if (formMode === FormMode.EDIT) {
          return _hotKey === hotKey && hotKey !== targetClass?.hotKey;
        }
        return _hotKey === hotKey;
      });

    if (hotKeyExist) {
      setFormError({
        ...formError,
        hotKey: {
          value: true,
          message: 'HotKey has been used',
        },
      });
    } else {
      setFormError({
        ...formError,
        hotKey: {
          value: false,
          message: '',
        },
      });
    }

    setFormState({
      ...formState,
      hotKey: hotKey as '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9',
    });
  };

  const handleClassDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;

    if (description.length > CLASS_DESCRIPTION_LIMIT) {
      return;
    }

    setFormState({
      ...formState,
      description,
    });
  };

  const handleColorPickerToggle = () => {
    setShowColorPicker((prev) => !prev);
  };

  const handleColorChange = (newColor: RgbColor) => {
    const colorInvalid = annotationClasses.some((annotationClass) => {
      if (formMode === FormMode.EDIT) {
        return (
          annotationClass.color === generateRGBString(newColor) &&
          targetClass?.color !== generateRGBString(newColor)
        );
      }

      return annotationClass.color === generateRGBString(newColor);
    });

    if (colorInvalid) {
      setFormError((formError) => ({
        ...formError,
        color: { value: true, message: 'Color has been used' },
      }));
    } else {
      setFormError((formError) => ({
        ...formError,
        color: { value: false, message: '' },
      }));
    }

    setFormState((prev) => ({ ...prev, color: generateRGBString(newColor) }));
  };

  const handleSubmit = async () => {
    const nameEmpty = !formState.name;
    const nameConflict = annotationClasses.some((annotationClass) => {
      if (formMode === FormMode.EDIT) {
        return formState.name === annotationClass.name && formState.name !== targetClass?.name;
      }
      return formState.name === annotationClass.name;
    });
    if (nameEmpty) {
      setFormError({
        ...formError,
        name: { value: true, message: 'Please enter class name' },
      });
    }
    if (nameConflict) {
      setFormError({
        ...formError,
        name: { value: true, message: 'Class name has been used' },
      });
    }

    const colorExist = annotationClasses.some((annotationClass) => {
      if (formMode === FormMode.EDIT) {
        return annotationClass.color === formState.color && targetClass?.color !== formState.color;
      }

      return annotationClass.color === formState.color;
    });

    if (colorExist) {
      setFormError((formError) => ({
        ...formError,
        color: { value: true, message: 'Color has been used' },
      }));
    }

    const hotKeyExist = annotationClasses
      .filter(({ hotKey }) => !!hotKey)
      .some(({ hotKey: _hotKey }) => {
        if (formMode === FormMode.EDIT) {
          return _hotKey === formState.hotKey && targetClass?.hotKey !== formState.hotKey;
        }
        return _hotKey === formState.hotKey;
      });

    if (hotKeyExist) {
      setFormError((formError) => ({
        ...formError,
        hotKey: {
          value: true,
          message: 'HotKey has been used',
        },
      }));
    }

    if (nameEmpty || colorExist || hotKeyExist || nameConflict) {
      return;
    }

    setLoading(true);
    if (formMode === FormMode.CREATE) {
      await dispatch(saveAnnotationClasses(formState)).unwrap();
      onCreateClassSuccess && (await onCreateClassSuccess());
    }
    if (formMode === FormMode.EDIT) {
      await dispatch(updateAnnotationClass(formState)).unwrap();
      onEditClassSuccess && (await onEditClassSuccess());
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />

      <ModalContent borderRadius={16} bg="text.text1">
        {showColorPicker && (
          <Box
            position="absolute"
            top={0}
            width="100%"
            height="100%"
            zIndex={100}
            onClick={handleColorPickerToggle}
          />
        )}

        <ModalHeader mt={3}>
          <Text
            bgGradient="linear(to-br, gradient.default.from, gradient.default.to)"
            bgClip="text"
            fontSize="34px"
          >
            Class setup
          </Text>
        </ModalHeader>
        <ModalCloseButton color="text.text7" top="6" right="4" />
        <ModalBody mt={2} ml="24px" mr="24px" bg="background.bg2" p={6} borderRadius={16}>
          <FormControl
            isRequired
            isInvalid={formError.name.value || formError.color.value}
            position="relative"
          >
            <FormLabel>
              <Text display="inline" color="text.text7" fontSize="sm">
                Class name
              </Text>
            </FormLabel>
            <InputGroup size="xs" position="relative">
              <InputLeftElement
                h="100%"
                w="fit-content"
                ml="12px"
                children={
                  <Box
                    borderWidth="1px"
                    borderRadius="2px"
                    borderColor={formState.color}
                    bg={formState.color}
                    minW="14px"
                    w="14px"
                    h="14px"
                  />
                }
                onClick={handleColorPickerToggle}
              />
              <Input
                placeholder="Class name"
                type="text"
                borderRadius={32}
                size="sm"
                color="text.text7"
                value={formState.name}
                onChange={handleClassNameChange}
              />
            </InputGroup>
            <Flex mt={2}>
              <VStack alignItems="flex-start" gap={0}>
                {formError.name.value && (
                  <FormErrorMessage>{formError.name.message}</FormErrorMessage>
                )}
                {formError.color.value && (
                  <FormErrorMessage>{formError.color.message}</FormErrorMessage>
                )}
              </VStack>
              <Spacer />
              <Text color="text.text7" fontSize="x-small">
                {`${formState.name.length}/${CLASS_NAME_LIMIT - formState.name.length}`} characters
              </Text>
            </Flex>
            {showColorPicker && (
              <Box position="absolute" top="calc(100% - 20px)" left={0} zIndex={101}>
                <RgbColorPicker
                  color={generateRGBObject(formState.color)}
                  onChange={handleColorChange}
                />
              </Box>
            )}
          </FormControl>

          <FormControl isInvalid={formError.hotKey.value}>
            <FormLabel>
              <Text display="inline" color="text.text7" fontSize="sm">
                Hotkey
              </Text>
            </FormLabel>
            <NumberInput
              color="text.text7"
              value={formState.hotKey}
              onChange={handleClassHotkeyChange}
              size="sm"
            >
              <NumberInputField placeholder="Enter value between 0 and 9" borderRadius={32} />
            </NumberInput>
            <Box mt={2}>
              {formError.hotKey.value && (
                <FormErrorMessage>{formError.hotKey.message}</FormErrorMessage>
              )}
            </Box>
          </FormControl>

          <FormControl mt={8}>
            <FormLabel>
              <Text display="inline" color="text.text7" fontSize="sm">
                Description
              </Text>
            </FormLabel>
            <Textarea
              placeholder="Type here..."
              size="sm"
              color="text.text7"
              borderRadius={16}
              resize="none"
              rows={4}
              value={formState.description}
              onChange={handleClassDescriptionChange}
            />
            <Flex mt={2} justifyContent="flex-end">
              <Text color="text.text7" fontSize="x-small">
                {`${formState.description?.length || 0}/${
                  CLASS_DESCRIPTION_LIMIT - (formState.description?.length || 0)
                }`}{' '}
                characters
              </Text>
            </Flex>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={4} mb={4}>
          <Flex width="100%" height={10} justifyContent="space-between">
            <OutlineButton width="48%" background="background.bg4" onClick={onClose}>
              Cancel
            </OutlineButton>
            <Button
              width="48%"
              variant="gradient"
              isDisabled={loading || !submitable}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AnnotationClassSetupModal;
