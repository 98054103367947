import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

const baseStyle = definePartsStyle({
  thumb: {
    background: 'text.text4',
    _checked: {
      bgGradient: 'linear(to-br, gradient.default.from, gradient.default.to)',
    },
  },
  track: {
    bg: 'background.bg4',
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
