import { FC } from 'react';
import { Image as KonvaImage, Layer } from 'react-konva';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';
import { generateImageFromURL } from '../../../../utils/image';

type ImageLayerProps = {
  imageIndex: number;
};

export const ImageLayer: FC<ImageLayerProps> = ({ imageIndex }) => {
  const url = useSelector((state: RootState) => {
    if (!state.project.imageData) return undefined;

    return state.project.imageData[imageIndex]?.url;
  });

  if (!url) {
    return <></>;
  }

  return (
    <Layer>
      <KonvaImage image={generateImageFromURL(url)} key={url} />
    </Layer>
  );
};
