import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { Button, Tooltip } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import { fetchPrediction, recordEventTrace, update } from '../../projectAsyncThunks';
import {
  TrainingStatus,
  changeFetchingTrainingStatistics,
  changeMetricUncertaintyData,
  changePredictionVisibility,
  getFetchingInferenceResult,
  getProjectUpdatable,
} from '../../projectSlice';

const UpdateButton: FC = () => {
  const { showErrorToast } = useAppToast();
  const annotationClasses = useSelector((state: RootState) => state.project.annotationClasses);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const fetchingTrainingStatistics = useSelector(
    (state: RootState) => state.project.fetchingTrainingStatistics,
  );
  const uploading = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;
  const fetchingInferenceResult = useSelector<RootState>(getFetchingInferenceResult) as ReturnType<
    typeof getFetchingInferenceResult
  >;
  const projectUpdatable = useSelector<RootState>(getProjectUpdatable) as ReturnType<
    typeof getProjectUpdatable
  >;

  const projectInactivated = trainingStatus !== TrainingStatus.RUNNING;

  const dispatch = useDispatch<AppDispatch>();

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        metricUncertainties: string;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        dispatch(changeFetchingTrainingStatistics(false));
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.UPDATE) {
        return;
      }

      if (message.status === 'Success') {
        await dispatch(fetchPrediction());

        if (message.payload) {
          dispatch(changeMetricUncertaintyData(JSON.parse(message.payload.metricUncertainties)));
          dispatch(
            recordEventTrace({
              eventId: message.payload?.eventId,
              eventType: EventType.UPDATE,
              eventReceivedOnClientAt: Date.now(),
            }),
          ).unwrap();
        }
      } else {
        showErrorToast();
        console.error(message.payload?.message);
      }

      dispatch(changeFetchingTrainingStatistics(false));
      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  const handleUpdateClick = async () => {
    try {
      const startTime = Date.now();
      dispatch(changeFetchingTrainingStatistics(true));

      pubSubClient.subscribe(eventHandler);

      await dispatch(
        update({
          eventType: EventType.UPDATE,
          timeRecorded: startTime,
        }),
      ).unwrap();
      annotationClasses.forEach((_, index) =>
        dispatch(changePredictionVisibility({ index, value: true })),
      );
    } catch (error) {
      dispatch(changeFetchingTrainingStatistics(false));
      pubSubClient.unsubscribe(eventHandler);
      Promise.reject(error);
    }
  };

  return (
    <Tooltip label={TOOLTIP_LABEL.UPDATE} hasArrow>
      <Button
        size="sm"
        variant="gradient"
        onClick={handleUpdateClick}
        isDisabled={
          !projectUpdatable ||
          projectInactivated ||
          uploading ||
          fetchingInferenceResult ||
          liveUpdateOn
        }
        isLoading={fetchingTrainingStatistics}
      >
        Predict
      </Button>
    </Tooltip>
  );
};

export default UpdateButton;
