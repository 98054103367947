import { FC } from 'react';
import { Image as KonvaImage, Layer } from 'react-konva';
import { useSelector } from 'react-redux';
import configs from '../../../../../configs';
import { RootState } from '../../../../../store';
import { generateImageFromURL } from '../../../../../utils/image';
import { AnnotationMode } from '../../projectSlice';

export type SuperpixelsBoundariesLayerProps = {
  imageIndex: number;
};

const SuperpixelsBoundariesLayer: FC<SuperpixelsBoundariesLayerProps> = ({ imageIndex }) => {
  const mode = useSelector((state: RootState) => state.project.annotationMode);
  const superpixelsBoundary = useSelector((state: RootState) => {
    if (!state.project.superpixelsBoundaries) return undefined;

    return state.project.superpixelsBoundaries[imageIndex];
  });

  if (mode !== AnnotationMode.SUPER_PIXEL || !superpixelsBoundary) return null;

  return (
    <Layer>
      <KonvaImage
        key={superpixelsBoundary[imageIndex]}
        image={generateImageFromURL(superpixelsBoundary)}
        draggable={false}
        width={configs.IMAGE_SIZE}
        height={configs.IMAGE_SIZE}
        opacity={0.8}
      />
    </Layer>
  );
};

export default SuperpixelsBoundariesLayer;
