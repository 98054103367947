import { FC } from 'react';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

const AppError: FC<Props> = ({ error }) => {
  console.error(error);

  return (
    <div style={{ height: '100vh', width: '100vw', color: 'red' }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.message || error || 'Unknown Error'}</i>
      </p>
    </div>
  );
};

export default AppError;
