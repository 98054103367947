import {
  Box,
  Center,
  Flex,
  Hide,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';

import { BiCaretDown } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import HotKey from '../../../../../components/Hotkey';
import {
  macOS__TOOL_HOT_KEY_TEXT,
  windowsOS__TOOL_HOT_KEY_TEXT,
} from '../../../../../constants/hotkey';
import { TOOL_BUTTON_ICON, TOOL_NAME } from '../../../../../constants/tool';
import { Tool } from '../../../../../models/annotation';
import { AppDispatch, RootState } from '../../../../../store';
import { changeSelectedImage, changeTool, getProjectUpdatable } from '../../projectSlice';
import ToolButton from './ToolButton';

type DrawingToolKitProps = {
  selectedTool: Tool;
  onToolClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const DrawingToolKit: FC = () => {
  const selectedTool = useSelector((state: RootState) => state.project.selectedTool);
  const projectUpdatable = useSelector(getProjectUpdatable);

  const dispatch = useDispatch<AppDispatch>();

  const handleToolClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const tool = e.currentTarget.value as Tool;

    const toolCanUpdateProject = [Tool.BRUSH, Tool.ERASER, Tool.PEN].includes(tool);
    if (!projectUpdatable && toolCanUpdateProject) return;

    switch (tool) {
      case Tool.MOUSE:
        dispatch(changeTool(tool));
        break;

      case Tool.PEN:
        // TODO: Implement pen tool
        alert('Pen tool is not supported yet');
        break;

      case Tool.ERASER:
      case Tool.BRUSH:
        dispatch(changeSelectedImage(-1));
        dispatch(changeTool(tool));
        break;

      default:
        alert('This tool action is not supported yet');
        break;
    }
  };

  return (
    <>
      <Show above="2xl">
        <ExtendedDrawingToolKit selectedTool={selectedTool} onToolClick={handleToolClick} />
      </Show>
      <Hide above="2xl">
        <MinimizedDrawingToolKit selectedTool={selectedTool} onToolClick={handleToolClick} />
      </Hide>
    </>
  );
};

export const ExtendedDrawingToolKit: FC<DrawingToolKitProps> = ({ selectedTool, onToolClick }) => {
  const isMac = navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0;
  const HOT_KEY_TEXT = isMac ? macOS__TOOL_HOT_KEY_TEXT : windowsOS__TOOL_HOT_KEY_TEXT;

  const projectUpdatable = useSelector(getProjectUpdatable);
  return (
    <Box>
      <Flex gap="8px">
        {[Tool.MOUSE, Tool.PEN, Tool.BRUSH, Tool.ERASER].map((tool: Tool, index) => (
          <ToolButton
            key={index}
            tool={tool}
            isActive={selectedTool === tool}
            onClick={onToolClick}
            tooltipLabel={
              <Center gap={2}>
                {TOOL_NAME[tool]}
                <HotKey keystrokes={HOT_KEY_TEXT[tool]} />
              </Center>
            }
            isDisabled={[Tool.PEN, Tool.BRUSH, Tool.ERASER].includes(tool) && !projectUpdatable}
          />
        ))}
      </Flex>
    </Box>
  );
};

export const MinimizedDrawingToolKit: FC<DrawingToolKitProps> = ({ selectedTool, onToolClick }) => {
  const isMac = navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0;
  const HOT_KEY_TEXT = isMac ? macOS__TOOL_HOT_KEY_TEXT : windowsOS__TOOL_HOT_KEY_TEXT;
  const projectUpdatable = useSelector(getProjectUpdatable);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Center
          px={4}
          height="32px"
          gap={1}
          borderWidth={1}
          borderRadius="32px"
          borderColor="main.purple4"
        >
          <Icon as={TOOL_BUTTON_ICON[selectedTool]} color="main.purple4" />
          <Icon as={BiCaretDown} color="main.purple4" />
        </Center>
      </PopoverTrigger>
      <PopoverContent bg="none" width="fit-content">
        <VStack
          gap="8px"
          bg="background.bg3"
          border="main.purple4"
          rounded="md"
          p={2}
          width="fit-content"
        >
          {[Tool.MOUSE, Tool.PEN, Tool.BRUSH, Tool.ERASER].map((tool: Tool, index) => (
            <ToolButton
              key={index}
              tool={tool}
              isActive={selectedTool === tool}
              onClick={onToolClick}
              tooltipLabel={
                <Center gap={2}>
                  {TOOL_NAME[tool]}
                  <HotKey keystrokes={HOT_KEY_TEXT[tool]} />
                </Center>
              }
              isDisabled={[Tool.PEN, Tool.BRUSH, Tool.ERASER].includes(tool) && !projectUpdatable}
            />
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

DrawingToolKit.defaultProps = {
  minimizedBreakpoint: '2xl',
};

export default DrawingToolKit;
