import { AbsoluteCenter, Button, HStack, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { BiEditAlt } from 'react-icons/bi';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProjectStatusIndicator from '../../../../../components/ProjectStatus';
import { AppDispatch, RootState } from '../../../../../store';
import ProjectSetupModal, {
  ProjectSetupFormState,
} from '../../../Projects/components/ProjectSetupModal';
import { updateProject } from '../../projectAsyncThunks';
import { CompleteProjectBtn } from './CompleteProjectBtn';

const Header = () => {
  const navigate = useNavigate();

  return (
    <HStack justifyContent="space-between" w="full">
      <Button
        size="sm"
        variant="default"
        leftIcon={<BsArrowLeftCircle />}
        borderColor="background.bg5"
        borderWidth={1}
        onClick={() => navigate('/projects')}
      >
        My projects
      </Button>
      <AbsoluteCenter>
        <ProjectName />
      </AbsoluteCenter>
      <CompleteProjectBtn />
    </HStack>
  );
};

const ProjectName = () => {
  const name = useSelector((state: RootState) => state.project.name);
  const description = useSelector((state: RootState) => state.project.description);
  const status = useSelector((state: RootState) => state.project.status);

  const dispatch = useDispatch<AppDispatch>();
  const handleUpdateProject = async ({ name, description }: ProjectSetupFormState) => {
    try {
      await dispatch(updateProject({ name, description })).unwrap();
    } catch (error) {
      Promise.reject(error);
    }

    onCloseProjectSetup();
  };

  const {
    isOpen: isProjectSetupOpen,
    onOpen: onOpenProjectSetup,
    onClose: onCloseProjectSetup,
  } = useDisclosure();

  return (
    <>
      <HStack justifyContent="center">
        <Text color="text.text7" maxW={320} noOfLines={1}>
          {name}
        </Text>
        <IconButton
          aria-label="Edit Project"
          icon={<BiEditAlt />}
          color="text.text7"
          variant="icon"
          onClick={onOpenProjectSetup}
        />
        <ProjectStatusIndicator status={status} />
      </HStack>

      {isProjectSetupOpen && (
        <ProjectSetupModal
          onClose={onCloseProjectSetup}
          onSubmit={handleUpdateProject}
          defaultFormValues={{
            name: name,
            description: description,
          }}
        />
      )}
    </>
  );
};

export default Header;
