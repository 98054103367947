import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import OutlineButton from '../../../../components/OutlineButton';
import { Project } from '../../../../models/project';

const PROJECT_NAME_LIMIT = 100;
const PROJECT_DESCRIPTION_LIMIT = 1000;

type Props = {
  onClose: () => void;
  onSubmit: (payload: ProjectSetupFormState) => void;
  defaultFormValues?: ProjectSetupFormState;
};

const defaultProps = {
  defaultFormValues: {
    name: '',
    description: '',
  },
};

export type ProjectSetupFormState = Pick<Project, 'name' | 'description'>;

type FormError = {
  name: boolean;
  description: boolean;
};

const ProjectSetupModal: FC<Props> = ({
  onSubmit,
  onClose,
  defaultFormValues = defaultProps.defaultFormValues,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<ProjectSetupFormState>(defaultFormValues);
  const [formError, setFormError] = useState<FormError>({
    name: false,
    description: false,
  });

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;

    if (name.length > PROJECT_NAME_LIMIT) {
      return;
    }

    setFormError({ ...formError, name: !name });
    setFormState({
      ...formState,
      name,
    });
  };

  const handleProjectDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;

    if (description.length > PROJECT_DESCRIPTION_LIMIT) {
      return;
    }

    setFormError({ ...formError, description: !description });
    setFormState({
      ...formState,
      description,
    });
  };

  const handleSubmit = async () => {
    const { name, description } = formState;

    if (!name || !description) {
      setFormError({ name: !name, description: !description });
      return;
    }

    setLoading(true);

    await onSubmit({
      name,
      description,
    });

    setLoading(false);
  };

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16} bg="background.bg3">
        <ModalHeader mt={3}>
          <Text
            bgGradient="linear(to-br, gradient.default.from, gradient.default.to)"
            bgClip="text"
          >
            Project setup
          </Text>
        </ModalHeader>
        <ModalCloseButton color="text.text7" top="6" right="4" />
        <ModalBody mt={2}>
          <FormControl isRequired isInvalid={formError.name}>
            <FormLabel>
              <Text display="inline" color="text.text7" fontSize="sm">
                Project name
              </Text>
            </FormLabel>
            <Input
              placeholder="Project name"
              type="text"
              borderRadius={32}
              size="sm"
              color="text.text7"
              value={formState.name}
              onChange={handleProjectNameChange}
            />
            <Flex mt={2}>
              {formError.name && <FormErrorMessage>Please enter project name</FormErrorMessage>}
              <Spacer />
              <Text color="text.text4" fontSize="x-small">
                {`${formState.name.length}/${PROJECT_NAME_LIMIT - formState.name.length}`}{' '}
                characters
              </Text>
            </Flex>
          </FormControl>

          <FormControl mt={8} isRequired isInvalid={formError.description}>
            <FormLabel>
              <Text display="inline" color="text.text7" fontSize="sm">
                Description
              </Text>
            </FormLabel>
            <Textarea
              placeholder="Describe project"
              size="sm"
              color="text.text7"
              borderRadius={16}
              resize="none"
              rows={4}
              value={formState.description}
              onChange={handleProjectDescriptionChange}
            />
            <Flex mt={2}>
              {formError.description && (
                <FormErrorMessage>Please enter project description</FormErrorMessage>
              )}
              <Spacer />
              <Text color="text.text4" fontSize="x-small">
                {`${formState.description.length}/${
                  PROJECT_DESCRIPTION_LIMIT - formState.description.length
                }`}{' '}
                characters
              </Text>
            </Flex>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={4} mb={4}>
          <Flex width="100%" height={10} gap={2} justifyContent="space-between">
            <OutlineButton w="full" background="background.bg4" onClick={onClose}>
              Cancel
            </OutlineButton>
            <Button
              w="full"
              variant="gradient"
              isLoading={loading}
              isDisabled={loading}
              onClick={handleSubmit}
            >
              <Icon as={AiOutlineArrowRight} color="text.text1" mr={2} /> Save & Continue
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectSetupModal;
