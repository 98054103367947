import {
  AbsoluteCenter,
  Box,
  CircularProgress,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsArrowRight, BsArrowUp, BsSearch } from 'react-icons/bs';
import { FiBell, FiGrid, FiList, FiLogOut, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { AppDispatch, RootState } from '../../../store';
import { APP_THEME } from '../../../theme/appTheme';
import { useAuthenticatedUser } from '../../auth';
import CreateProjectButton from './components/CreateProjectButton';
import DeleteProjectModal from './components/DeleteProjectModal';
import ProjectCard from './components/ProjectCard';
import ProjectSetupModal, { ProjectSetupFormState } from './components/ProjectSetupModal';
import { createProject, fetchProjects } from './projectsSlice';
import AppIcon from '/app-icon.svg';

const Projects = () => {
  const { user } = useAuthenticatedUser();
  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const [deleteProjectId, setDeleteProjectId] = useState<string>('');
  const [isFetchingProjects, setIsFetchingProjects] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isShowSubmitIcon, setIsShowSubmitIcon] = useState<boolean>(false);

  const { projects } = useSelector((state: RootState) => state.projects);

  const [showGoToTopButton, setShowGoToTopButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    setShowGoToTopButton(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchProjectsPageData = async () => {
      setIsFetchingProjects(true);
      await dispatch(fetchProjects({})).unwrap();
      setIsFetchingProjects(false);
    };

    if (searchText === '') {
      fetchProjectsPageData();
      setIsShowSubmitIcon(false);
    }
  }, [dispatch, searchText]);

  const handleSearchProject = async () => {
    setIsSearching(true);
    await dispatch(fetchProjects(searchText ? { name: searchText } : {})).unwrap();
    setIsSearching(false);
    setIsShowSubmitIcon(false);
  };

  const handleSearchProjectPress = async (key: string) => {
    if (key === 'Enter') {
      await handleSearchProject();
    }
  };

  const handleProjectClick = (projectId: string) => {
    navigate(projectId);
  };

  const handleProjectDeleteConfirmation = (projectId: string) => {
    setDeleteProjectId(projectId);
  };

  const handleCreateProject = async ({ name, description }: ProjectSetupFormState) => {
    const payload = await dispatch(
      createProject({
        name,
        description,
      }),
    ).unwrap();

    if (payload.id) {
      navigate(payload.id);
    }
  };

  const renderHeader = () => (
    <Flex width="100%" px={6} py={4}>
      <Flex mr={4}>
        <img src={AppIcon} alt="App Icon" />
      </Flex>
      <Flex alignItems="center">
        <Text color="text.text7" fontWeight={600} fontSize={14}>
          Sioux Annotation Tool
        </Text>
      </Flex>
      <Spacer />
      <Flex alignItems="center" gap={2}>
        <Flex px={3} py={1} alignItems="center" gap={1}>
          <Icon as={FiUser} color="text.text5" height="100%" />
          <Text color="text.text7" fontSize="xs">
            {user.name}
          </Text>
        </Flex>
        <IconButton
          aria-label="Notification"
          icon={<FiBell />}
          color="text.text5"
          size="sm"
          variant="default"
        />
        <Link to="/auth/logout">
          <IconButton
            aria-label="Notification"
            icon={<FiLogOut />}
            color="text.text5"
            size="sm"
            variant="default"
          />
        </Link>
      </Flex>
    </Flex>
  );

  const renderBody = () => (
    <Flex width="100%" direction="column" pt={16}>
      <Flex width="100%" px={4} py={10} height={12} alignItems="center">
        <Flex width="100%" gap={4}>
          <Flex alignItems="center">
            <Text color="text.text7" fontWeight={600} fontSize="xl">
              My Projects
            </Text>
          </Flex>
          <InputGroup width="30%" height={10}>
            <InputLeftElement pointerEvents="none" height="100%">
              <Icon as={BsSearch} color="text.text3" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search a project"
              color="text.text7"
              focusBorderColor="transparent"
              background={`linear-gradient(${APP_THEME.colors.background.bg2} 0 0) padding-box, linear-gradient(293.31deg, #ffa05b -17.76%, #ff835b -17.75%, #d88ffb 96.55%) border-box`}
              borderWidth={1}
              borderStyle="solid"
              borderColor="transparent"
              borderRadius={32}
              _hover={{ borderColor: 'transparent' }}
              onKeyDown={async (e) => await handleSearchProjectPress(e.key)}
              onChange={(e) => {
                setSearchText(e.target.value);
                setIsShowSubmitIcon(true);
              }}
            />
            {!isSearching && isShowSubmitIcon && (
              <InputRightElement>
                <Flex
                  borderRadius={50}
                  padding={1}
                  cursor="pointer"
                  bgColor="background.bg5"
                  _hover={{ bgColor: 'background.bg4' }}
                  onClick={async () => await handleSearchProject()}
                >
                  <Icon as={BsArrowRight} fontSize="lg" />
                </Flex>
              </InputRightElement>
            )}
            {isSearching && (
              <InputRightElement>
                <Spinner color="main.purple4" size="sm" speed="0.7s" />
              </InputRightElement>
            )}
          </InputGroup>
        </Flex>
        <Flex gap={4}>
          <Flex
            width="32px"
            height="32px"
            alignItems="center"
            justifyContent="center"
            bg="background.bg3"
            borderRadius="12px"
          >
            <Icon as={FiGrid} color="text.text5" width="24px" height="24px" />
          </Flex>
          <Flex
            width="32px"
            height="32px"
            alignItems="center"
            justifyContent="center"
            bg="background.bg3"
            borderRadius="12px"
          >
            <Icon as={FiList} color="text.text5" width="24px" height="24px" />
          </Flex>
        </Flex>
      </Flex>

      {isFetchingProjects ? (
        <AbsoluteCenter>
          <CircularProgress isIndeterminate />
        </AbsoluteCenter>
      ) : (
        <Flex direction="column">
          <Grid
            justifyContent="center"
            templateColumns="repeat(auto-fit, 292px)"
            templateRows="repeat(auto-fit, 447px)"
            gap={4}
          >
            <GridItem w="100%" h="100%">
              <CreateProjectButton
                iconSize={{ width: 10, height: 10 }}
                onClick={onOpenCreateModal}
              />
            </GridItem>
            {projects.map((project) => (
              <GridItem key={project.id} w="100%" h="100%" position="relative">
                <ProjectCard
                  project={project}
                  onClick={() => handleProjectClick(project.id)}
                  onDelete={() => handleProjectDeleteConfirmation(project.id)}
                />
              </GridItem>
            ))}
          </Grid>
        </Flex>
      )}

      {showGoToTopButton && (
        <IconButton
          icon={<BsArrowUp />}
          aria-label="Go to Top"
          size="lg"
          position="fixed"
          bottom={8}
          right={8}
          onClick={handleScrollToTop}
          bg="background.bg5"
          _hover={{ bg: 'background.bg4' }}
        />
      )}
    </Flex>
  );

  return (
    <Box>
      <Layout
        header={{
          children: renderHeader(),
          optionalStyle: {
            backgroundColor: 'background.bg2',
            position: 'fixed',
            width: '100%',
            zIndex: 100,
          },
        }}
      >
        {renderBody()}
      </Layout>
      {isOpenCreateModal && (
        <ProjectSetupModal onClose={onCloseCreateModal} onSubmit={handleCreateProject} />
      )}
      {deleteProjectId && (
        <DeleteProjectModal projectId={deleteProjectId} onClose={() => setDeleteProjectId('')} />
      )}
    </Box>
  );
};

export default Projects;
