import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

/**
 * Refer to example: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
 */

const baseStyle = defineStyle({
  borderRadius: '32px',
  bg: 'none',
  color: 'text.text7',
  _hover: {
    bg: 'background.bg6',
  },
  borderWidth: '1px',
  borderColor: 'transparent',
});

const smVariant = defineStyle({
  borderRadius: '12px',
});

const gradientVariant = defineStyle({
  color: 'text.text1',
  bgGradient: 'linear(to-br, gradient.default.from, gradient.default.to)',
  _hover: {
    bg: '#ebf8ff',
    _disabled: {
      bgGradient: 'linear(to-br, gradient.default.from, gradient.default.to) !important',
    },
  },
  _disabled: {
    background: 'none !important',
    color: 'text.text3',
    borderColor: 'background.bg5',
    opacity: 1,
    borderWidth: '1px',
    cursor: 'not-allowed',
  },
});

const iconVariant = defineStyle({
  bg: 'none',
  _hover: {
    bg: 'none',
  },
});

const outlineVariant = defineStyle({
  background: 'background.bg3',
  color: 'text.text5',
  borderColor: 'background.bg5',
  opacity: 1,
  borderWidth: '1px',
  _disabled: {
    color: 'text.text3',
    cursor: 'not-allowed',
  },
  _hover: {
    bg: 'background.bg6',
  },
  _active: {
    bg: 'background.bg7',
  },
});

const variants = {
  sm: smVariant,
  gradient: gradientVariant,
  icon: iconVariant,
  outline: outlineVariant,
};

export const buttonTheme = defineStyleConfig({ baseStyle, variants });
