import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import OutlineButton from '../../../../../components/OutlineButton';
import useAppToast from '../../../../../hooks/useAppToast';
import { IdbSuperpixelsDAO } from '../../../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import {
  completeProject,
  fetchPrediction,
  fetchTrainable,
  recordEventTrace,
} from '../../projectAsyncThunks';
import {
  AnnotationMode,
  TrainingStatus,
  changeAnnotationMode,
  changeProjectStatus,
} from '../../projectSlice';

export const CompleteProjectBtn: FC = () => {
  const { showErrorToast } = useAppToast();
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const status = useSelector((state: RootState) => state.project.status);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const projectId = useSelector((state: RootState) => state.project.id);

  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [completable, setCompletable] = useState(false);
  const { isOpen: isOpenAlert, onClose: onCloseAlert, onOpen: onOpenAlert } = useDisclosure();
  const cancelAlertRef = useRef(null);

  useEffect(() => {
    const verifyProjectCompletable = async () => {
      const trainable = await dispatch(fetchTrainable()).unwrap();

      setCompletable(
        (trainable || trainingStatus === TrainingStatus.RUNNING) &&
          status === ProjectStatus.IN_PROGRESS &&
          !liveUpdateOn,
      );
    };

    verifyProjectCompletable();
  }, [dispatch, trainingStatus, liveUpdateOn, status]);

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        setLoading(false);
        dispatch(changeProjectStatus(ProjectStatus.IN_PROGRESS));
        pubSubClient.unsubscribe(eventHandler);
      }

      if (message.type !== EventType.COMPLETE) {
        return;
      }

      if (message.status === 'Success') {
        dispatch(changeProjectStatus(ProjectStatus.COMPLETED));
        await dispatch(fetchPrediction());
        await dispatch(changeAnnotationMode(AnnotationMode.SCRIBBLE));
        await IdbSuperpixelsDAO.getInstance().delete(projectId);
      } else {
        console.error(message.payload?.message);
        dispatch(changeProjectStatus(ProjectStatus.IN_PROGRESS));
        showErrorToast();
      }

      if (message.payload) {
        dispatch(
          recordEventTrace({
            eventId: message.payload.eventId,
            eventType: EventType.COMPLETE,
            eventReceivedOnClientAt: Date.now(),
          }),
        ).unwrap();
      }
      setLoading(false);
      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  const handleCompleteProject = async () => {
    if (!completable) {
      return;
    }

    try {
      const startTime = Date.now();
      setLoading(true);
      dispatch(changeProjectStatus(ProjectStatus.COMPLETING));

      pubSubClient.subscribe(eventHandler);

      await dispatch(completeProject(startTime)).unwrap();
    } catch (error) {
      setLoading(false);
      dispatch(changeProjectStatus(ProjectStatus.IN_PROGRESS));
      Promise.reject(error);
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="default"
        bg="background.bg3"
        color="main.purple5"
        fontWeight={600}
        isLoading={loading}
        onClick={onOpenAlert}
        isDisabled={!completable}
      >
        Complete
      </Button>

      {completable && (
        <AlertDialog
          isOpen={isOpenAlert}
          onClose={onCloseAlert}
          leastDestructiveRef={cancelAlertRef}
          isCentered={true}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              background="background.bg2"
              color="text.text7"
              justifyContent="center"
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold" color="main.orange4">
                Caution
              </AlertDialogHeader>
              <AlertDialogBody>
                You cannot annotate images after completing the project. This action cannot be
                undone. Are you sure you want to complete the project?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Flex height={8} gap={6}>
                  <OutlineButton
                    width="130px"
                    size="sm"
                    background="background.bg4"
                    isDisabled={loading}
                    onClick={onCloseAlert}
                  >
                    Cancel
                  </OutlineButton>
                  <Button
                    variant="gradient"
                    size="sm"
                    width="130px"
                    onClick={async () => {
                      await handleCompleteProject();
                      onCloseAlert();
                    }}
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Confirm
                  </Button>
                </Flex>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};
