import configs from '../configs';
import { blobService } from './blob';

const PREFIX_LENGTH = 5;

export const generateImageFromURL = (imgUrl: string): HTMLImageElement | undefined => {
  if (!imgUrl) {
    return;
  }

  const image = new Image();
  image.src = imgUrl;
  return image;
};

export const getImageDimensionsFromArrayBuffer = async (
  arrayBuffer: ArrayBuffer,
): Promise<{ width: number; height: number }> => {
  const image = await new Promise((resolve: (value: HTMLImageElement) => void, reject) => {
    if (!arrayBuffer.byteLength) {
      reject('Empty buffer');
    }

    const image = new Image();
    const blob = new Blob([new Uint8Array(arrayBuffer)]);
    const url = URL.createObjectURL(blob);

    image.onload = () => {
      URL.revokeObjectURL(url);
      resolve(image);
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = url;
  });

  return { width: image.width, height: image.height };
};

/**
 * @abstract Download images from Azure Blob Storage
 * @param container - Container name
 * @param blobPrefix - Blob name prefix
 * @param pagingImageIndices - Image indices to download
 */
export const downloadImages = async (
  container: string,
  blobPrefix: string,
  pagingImageIndices: number[],
): Promise<{ imageIndex: number; url: string }[]> => {
  const containerClient = blobService.getContainerClient(container);
  const blobList = containerClient.listBlobsFlat({ prefix: blobPrefix });
  const imageUrls: { imageIndex: number; url: string }[] = [];

  const promiseFetchImages: Promise<void>[] = [];

  for await (const { name } of blobList) {
    if (imageUrls.length === pagingImageIndices.length) {
      break;
    }

    const imageIndex = Number(name.split('/')[1].substring(0, PREFIX_LENGTH));

    try {
      if (!pagingImageIndices.includes(imageIndex)) {
        continue;
      }

      promiseFetchImages.push(
        (async () => {
          const newImageUrl: { imageIndex: number; url: string } = {
            imageIndex,
            url: '',
          };

          const response = await containerClient.getBlobClient(name).download();
          if (!response.blobBody) {
            return;
          }

          const blob = await response.blobBody;
          newImageUrl.url = URL.createObjectURL(blob);

          imageUrls.push(newImageUrl);
        })(),
      );
    } catch (error) {
      Promise.reject(error);
    }
  }

  await Promise.all(promiseFetchImages);

  return imageUrls;
};

export const getNumberOfSplitImages = async (images: File[]): Promise<number> => {
  const totalImages = images.length;
  let totalPredictedImages = 0;

  for (const image of images) {
    const arrayBuffer = await image.arrayBuffer();
    const { width, height } = await getImageDimensionsFromArrayBuffer(arrayBuffer);

    if (width === configs.IMAGE_SIZE && height === configs.IMAGE_SIZE) {
      totalPredictedImages += 1;
    } else {
      totalPredictedImages +=
        Math.ceil(width / configs.IMAGE_SIZE) * Math.ceil(height / configs.IMAGE_SIZE);
    }
  }

  return totalPredictedImages || totalImages;
};
