export const arrayBufferToBase64 = (arrayBuffer: Uint8Array): string => {
  const byteArray = new Uint8Array(arrayBuffer);
  let binaryString = '';
  byteArray.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
};

export const arrayBufferToString = (arrayBuffer: Uint8Array): string => {
  if (!arrayBuffer.length) {
    return '';
  }

  const byteArray = new Uint8Array(arrayBuffer);
  let binaryString = '';
  byteArray.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return binaryString;
};

export const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  const uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return uint8Array;
};
