import { AnnotationMode } from '../modules/projects/Project/projectSlice';

export enum Tool {
  // Drawing
  MOUSE = 'MOUSE',
  PEN = 'PEN',
  BRUSH = 'BRUSH',
  ERASER = 'ERASER',

  // Viewing
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT',

  // Navigation
  UNDO = 'UNDO',
  REDO = 'REDO',
}

export const DRAWING_TOOLS = [Tool.MOUSE, Tool.PEN, Tool.BRUSH, Tool.ERASER];
export const VIEWING_TOOLS = [Tool.ZOOM_IN, Tool.ZOOM_OUT];
export const NAVIGATING_TOOLS = [Tool.UNDO, Tool.REDO];

export interface Composition {
  tool: Tool;
  annotationMode: AnnotationMode;
  annotationClassId: string;
  lineSize?: number;
  linePoints?: number[];
  segmentPoints?: number[];
}

export type PixelCoordinate = [number, number];

export interface Annotation {
  _id: string;
  projectId: string;
  imageIndex: number;
  annotations: string[];
  compositions: Composition[];
  createdAt: Date;
  updatedAt: Date;
}
