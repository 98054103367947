import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

type Props = React.PropsWithChildren &
  ButtonProps & {
    textGradient?: string;
    borderGradient?: string;
  };

const OutlineButton: FC<Props> = ({
  width = '100%',
  height = '100%',
  borderRadius = '32px',
  borderGradient = 'linear-gradient(to bottom right, gradient.default.from, gradient.default.to)',
  textGradient = 'linear(to-br, gradient.default.from, gradient.default.to)',
  children,
  ...props
}) => {
  return (
    <Box position="relative" width={width} height={height}>
      <Button
        {...props}
        variant="outline"
        colorScheme="blue"
        zIndex={10}
        borderColor="transparent"
        borderRadius={borderRadius}
        width="calc(100% - 2px)"
        height="calc(100% - 2px)"
      >
        <Text bgGradient={textGradient} bgClip="text">
          {children}
        </Text>
      </Button>
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top="-1px"
        left="-1px"
        borderRadius={borderRadius}
        bgGradient={borderGradient}
      />
    </Box>
  );
};

export default OutlineButton;
