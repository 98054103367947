import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { Button, Flex, HStack, Switch, Text, Tooltip } from '@chakra-ui/react';
import { ChangeEvent, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import { fetchUncertainty, loadUncertainty, recordEventTrace } from '../../projectAsyncThunks';
import {
  TrainingStatus,
  changeAleatoricVisibility,
  changeEpistemicVisibility,
  changeFetchingUncertainty,
  changeMetricVisibility,
  getFetchingInferenceResult,
  getProjectUpdatable,
} from '../../projectSlice';
import ZapIcon from '/zap.svg';

const CheckUncertainty: FC = () => {
  const { showErrorToast } = useAppToast();
  const epistemicVisibility = useSelector((state: RootState) => state.project.epistemicVisibility);
  const aleatoricVisibility = useSelector((state: RootState) => state.project.aleatoricVisibility);
  const metricVisibility = useSelector((state: RootState) => state.project.metricVisibility);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const fetchingUncertainty = useSelector((state: RootState) => state.project.fetchingUncertainty);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const uploading = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const fetchingInferenceResult = useSelector(getFetchingInferenceResult);
  const projectUpdatable = useSelector(getProjectUpdatable);
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;

  const actionDisabled =
    trainingStatus !== TrainingStatus.RUNNING ||
    fetchingInferenceResult ||
    liveUpdateOn ||
    uploading ||
    !projectUpdatable;

  const dispatch = useDispatch<AppDispatch>();

  const handleEpistemicUncertaintyChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeEpistemicVisibility(e.target.checked));
  };

  const handleAleatoricUncertaintyChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeAleatoricVisibility(e.target.checked));
  };

  const handleMetricChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(changeMetricVisibility(e.target.checked));
  };

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        dispatch(changeFetchingUncertainty(false));
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.LOAD_UNCERTAINTY) {
        return;
      }

      if (message.status === 'Success') {
        await dispatch(fetchUncertainty()).unwrap();

        if (message.payload) {
          dispatch(
            recordEventTrace({
              eventId: message.payload?.eventId,
              eventType: EventType.LOAD_UNCERTAINTY,
              eventReceivedOnClientAt: Date.now(),
            }),
          ).unwrap();
        }
      } else {
        showErrorToast();
        console.error(message.payload?.message);
      }

      dispatch(changeFetchingUncertainty(false));
      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  const handleFetchingUncertainty = async () => {
    try {
      const startTime = Date.now();
      dispatch(changeFetchingUncertainty(true));
      pubSubClient.subscribe(eventHandler);

      await dispatch(loadUncertainty(startTime)).unwrap();
    } catch (error) {
      dispatch(changeFetchingUncertainty(false));
      pubSubClient.unsubscribe(eventHandler);
      Promise.reject(error);
    }
  };

  return (
    <Flex direction="column" bg="background.bg3" borderRadius="lg" px={4} py={3}>
      <Flex alignItems="center" justifyContent="space-between">
        <HStack>
          <img src={ZapIcon} alt="Annotation Mode Icon" />
          <Tooltip label={TOOLTIP_LABEL.CHECK_UNCERTAINTY} hasArrow>
            <Text color="text.text7" fontWeight="bold" cursor="help">
              Check uncertainty
            </Text>
          </Tooltip>
        </HStack>
        <Tooltip label={TOOLTIP_LABEL.GET_UNCERTAINTY} hasArrow>
          <Button
            ml="auto"
            size="sm"
            variant="default"
            bg="background.bg5"
            px="6"
            isDisabled={actionDisabled}
            onClick={handleFetchingUncertainty}
            isLoading={fetchingUncertainty}
          >
            Load
          </Button>
        </Tooltip>
      </Flex>
      <Flex
        borderRadius="xl"
        bg="background.bg2"
        justifyContent="space-between"
        alignItems="center"
        h="35px"
        px={4}
        py={3}
        mt={3}
      >
        <Tooltip label={TOOLTIP_LABEL.EPISTEMIC_UNCERTAINTY} hasArrow>
          <Text color="text.text7" fontSize="sm" cursor="help">
            Epistemic uncertainty
          </Text>
        </Tooltip>
        <Switch
          onChange={handleEpistemicUncertaintyChange}
          isChecked={epistemicVisibility}
          isDisabled={!projectUpdatable}
        />
      </Flex>

      <Flex
        borderRadius="xl"
        bg="background.bg2"
        justifyContent="space-between"
        alignItems="center"
        h="35px"
        px={4}
        py={3}
        mt={2}
      >
        <Tooltip label={TOOLTIP_LABEL.ALEATORIC_UNCERTAINTY} hasArrow>
          <Text color="text.text7" fontSize="sm" cursor="help">
            Aleatoric uncertainty
          </Text>
        </Tooltip>
        <Switch
          onChange={handleAleatoricUncertaintyChange}
          isChecked={aleatoricVisibility}
          isDisabled={!projectUpdatable}
        />
      </Flex>

      <Flex
        borderRadius="xl"
        bg="background.bg2"
        justifyContent="space-between"
        alignItems="center"
        h="35px"
        px={4}
        py={3}
        mt={2}
      >
        <Tooltip label={TOOLTIP_LABEL.SCORE_PER_IMAGE} hasArrow>
          <Text color="text.text7" fontSize="sm" cursor="help">
            Score per image
          </Text>
        </Tooltip>
        <Switch
          onChange={handleMetricChange}
          isChecked={metricVisibility}
          isDisabled={!projectUpdatable}
        />
      </Flex>
    </Flex>
  );
};

export default CheckUncertainty;
