import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import { projectReducer, projectsReducer } from './modules/projects';

const defaultMiddlewareConfig = {
  serializableCheck: {
    ignoredPaths: ['project.pubSubClient'],
    ignoredActions: ['project/initPubSubClient/fulfilled'],
  },
};

export const store = configureStore({
  reducer: {
    app: appReducer,
    project: projectReducer,
    projects: projectsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddlewareConfig),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
