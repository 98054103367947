export enum ProjectStatus {
  DRAFT = 'DRAFT',
  UPLOADING = 'UPLOADING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETING = 'COMPLETING',
  COMPLETED = 'COMPLETED',
}

export interface AnnotationClass {
  id: string;
  name: string;
  color: string;
  hotKey?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '';
  description?: string;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  status: ProjectStatus;
  totalImages: number;
  suggestImageIndices: number[];
  annotationClasses: AnnotationClass[];
  trainingProgress: number;
  avgDiceScore: number;
  errorDiceScore: number;
  avgPrecision: number;
  avgRecall: number;
  trainable: boolean;
  createdAt: string;
  updatedAt: string;
}
