import { IconType } from 'react-icons';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { FiPenTool } from 'react-icons/fi';
import { LuEraser, LuMousePointer2, LuRedo, LuUndo } from 'react-icons/lu';
import { Tool } from '../models/annotation';

export const TOOL_BUTTON_ICON: Record<Tool, IconType> = {
  [Tool.MOUSE]: LuMousePointer2,
  [Tool.BRUSH]: BiPencil,
  [Tool.PEN]: FiPenTool,
  [Tool.ERASER]: LuEraser,
  [Tool.ZOOM_OUT]: AiOutlineZoomOut,
  [Tool.ZOOM_IN]: AiOutlineZoomIn,
  [Tool.UNDO]: LuUndo,
  [Tool.REDO]: LuRedo,
};

export const TOOL_NAME: Record<Tool, string> = {
  [Tool.MOUSE]: 'Mouse',
  [Tool.BRUSH]: 'Brush',
  [Tool.PEN]: 'Pen',
  [Tool.ERASER]: 'Eraser',
  [Tool.ZOOM_OUT]: 'Zoom out',
  [Tool.ZOOM_IN]: 'Zoom in',
  [Tool.UNDO]: 'Undo',
  [Tool.REDO]: 'Redo',
};
