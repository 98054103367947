import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { Button, Tooltip } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import {
  fetchAnnotations,
  fetchImages,
  recordEventTrace,
  suggestImages,
} from '../../projectAsyncThunks';
import {
  TrainingStatus,
  changeFetchingImageSuggestions,
  changeImageIndices,
  changeModelMetrics,
  getFetchingInferenceResult,
  getProjectUpdatable,
  resetImageData,
} from '../../projectSlice';

const SuggestButton: FC = () => {
  const { showErrorToast } = useAppToast();
  const projectId = useSelector((state: RootState) => state.project.id);
  const uploading = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const filteringAnnotatedImages = useSelector(
    (state: RootState) => state.project.filteringAnnotatedImages,
  );
  const fetchingImageSuggestions = useSelector(
    (state: RootState) => state.project.fetchingImageSuggestions,
  );
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;

  const fetchingInferenceResult = useSelector<RootState>(getFetchingInferenceResult) as ReturnType<
    typeof getFetchingInferenceResult
  >;
  const projectUpdatable = useSelector<RootState>(getProjectUpdatable) as ReturnType<
    typeof getProjectUpdatable
  >;

  const projectInactivated = trainingStatus !== TrainingStatus.RUNNING;

  const dispatch = useDispatch<AppDispatch>();

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        suggestImageIndices: number[];
        avgDiceScore: number;
        errorDiceScore: number;
        avgPrecision: number;
        avgRecall: number;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        dispatch(changeFetchingImageSuggestions(false));
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.SUGGEST) {
        return;
      }

      if (message.status === 'Success' && message.payload) {
        const { suggestImageIndices, avgDiceScore, errorDiceScore, avgPrecision, avgRecall } =
          message.payload;

        dispatch(resetImageData());
        dispatch(changeImageIndices({ suggestImageIndices }));
        dispatch(changeModelMetrics({ avgDiceScore, errorDiceScore, avgPrecision, avgRecall }));
        await Promise.all([
          dispatch(fetchAnnotations(projectId)).unwrap(),
          dispatch(fetchImages(projectId)).unwrap(),
        ]);
        dispatch(
          recordEventTrace({
            eventId: message.payload.eventId,
            eventType: EventType.SUGGEST,
            eventReceivedOnClientAt: Date.now(),
          }),
        ).unwrap();
      } else {
        console.error(message.payload?.message);
        showErrorToast(message.payload?.message);
      }

      dispatch(changeFetchingImageSuggestions(false));

      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  const handleSuggestClick = async () => {
    try {
      const startTime = Date.now();
      dispatch(changeFetchingImageSuggestions(true));

      pubSubClient.subscribe(eventHandler);

      await dispatch(suggestImages(startTime)).unwrap();
    } catch (error) {
      dispatch(changeFetchingImageSuggestions(false));
      pubSubClient.unsubscribe(eventHandler);
      Promise.reject(error);
    }
  };

  return (
    <Tooltip label={TOOLTIP_LABEL.SUGGEST} hasArrow>
      <Button
        ml={8}
        variant="gradient"
        size="sm"
        onClick={handleSuggestClick}
        isDisabled={
          !projectUpdatable ||
          projectInactivated ||
          fetchingInferenceResult ||
          liveUpdateOn ||
          filteringAnnotatedImages ||
          uploading
        }
        isLoading={fetchingImageSuggestions}
      >
        Suggest
      </Button>
    </Tooltip>
  );
};

export default SuggestButton;
