import {
  Box,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';

export enum InfoButtonType {
  Tooltip = 'Tooltip',
  Popover = 'Popover',
}
type Props = {
  label: string;
  type?: InfoButtonType;
  color?: string;
  fontSize?: string;
};

/**
 *
 * @param label: **string** - the text to be displayed in the tooltip or popover
 * @param boxProps: **FlexProps** - the props for the box that wraps the icon
 * @param iconProps: **IconProps** - the props for the icon
 * @param type: **InfoButtonType** - the type of the info button, either tooltip or popover
 */
const InfoButton: FC<Props> = ({ label, type, color, fontSize }) => {
  switch (type) {
    case InfoButtonType.Tooltip:
      return (
        <Tooltip label={label}>
          <Box lineHeight={1} cursor="help">
            <Icon as={FiInfo} color={color} fontSize={fontSize} />
          </Box>
        </Tooltip>
      );
    case InfoButtonType.Popover:
      return (
        <Popover>
          <PopoverTrigger>
            <Box lineHeight={1} cursor="pointer">
              <Icon as={FiInfo} color={color} fontSize={fontSize} />
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>{label}</PopoverBody>
          </PopoverContent>
        </Popover>
      );
    default:
      return <></>;
  }
};

InfoButton.defaultProps = {
  type: InfoButtonType.Popover,
  color: 'text.text5',
  fontSize: '16px',
};

export default InfoButton;
