import { KonvaEventObject } from 'konva/lib/Node';
import { FC, useMemo, useState } from 'react';
import { Rect as KonvaRect, Layer } from 'react-konva';
import { useSelector } from 'react-redux';
import { Tool } from '../../../../../models/annotation';
import { RootState } from '../../../../../store';
import { AnnotationMode } from '../../projectSlice';
export type ToolSizeIndicatorLayerProps = {
  imageIndex: number;
};

const TOOL_NEED_SIZE_INDICATOR = [Tool.BRUSH, Tool.ERASER];
const ToolSizeIndicatorLayer: FC<ToolSizeIndicatorLayerProps> = ({ imageIndex }) => {
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isMouseOver, setIsMouseOver] = useState(false);

  const imageZoomLevel = useSelector(
    (state: RootState) => state.project.imageData[imageIndex]?.zoomLevel ?? 0,
  );
  const toolSize = useSelector((state: RootState) => state.project.toolSize);
  const selectedTool = useSelector((state: RootState) => state.project.selectedTool);
  const annotationMode = useSelector((state: RootState) => state.project.annotationMode);

  const handleMouseMove = (evt: KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();
    if (!stage) {
      return;
    }
    const pos = stage.getRelativePointerPosition();
    if (!pos) {
      return;
    }

    setPos(pos);
  };

  const circleSize = toolSize * Math.pow(imageZoomLevel, 1 / 256);
  const visible = useMemo(
    () =>
      isMouseOver &&
      TOOL_NEED_SIZE_INDICATOR.includes(selectedTool) &&
      annotationMode === AnnotationMode.SCRIBBLE,
    [isMouseOver, selectedTool, annotationMode],
  );
  return (
    <Layer
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      width={256}
      height={256}
    >
      <KonvaRect width={256} height={256} x={0} y={0} fill="rgba(0, 0, 0, 0)" />
      {visible && (
        <KonvaRect
          width={circleSize}
          height={circleSize}
          x={pos.x - circleSize * 0.5}
          y={pos.y - circleSize * 0.5}
          fill="rgba(0, 0, 0, 0.2)"
          cornerRadius={100}
          stroke={'white'}
          strokeWidth={0.5}
        />
      )}
    </Layer>
  );
};

export default ToolSizeIndicatorLayer;
