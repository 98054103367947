import { useAuth0 } from '@auth0/auth0-react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage.tsx';
import Loading from '../components/Loading.tsx';
import NotFound from '../components/NotFound.tsx';
import Root from '../components/Root.tsx';
import { AuthRouters } from '../modules/auth';
import { ProjectRouters } from '../modules/projects';
import { APP_THEME } from '../theme/appTheme.ts';

const App = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();

  const bodyHtml = document.getElementById('body');
  const theme = extendTheme(APP_THEME);

  if (bodyHtml) {
    bodyHtml.style.background = theme.colors.background.bg1;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route index element={<Root />} />
            <Route path="/auth/*" element={<AuthRouters />} />
            <Route path="/projects/*" element={<ProjectRouters />} />
            <Route path="/*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<AuthRouters />} />
          </>
        )}
      </Routes>
    </ChakraProvider>
  );
};

export default App;
