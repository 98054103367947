import { Button, Icon, Stack, Text, chakra } from '@chakra-ui/react';
import { FC } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

type Props = {
  iconSize: { width: number; height: number };
  onClick: () => void;
};

const CustomButton = chakra(Button, {
  baseStyle: {
    borderRadius: '12px',
    _hover: {
      bgGradient: 'linear(to-br, gradient.brand.from, gradient.brand.to)',
    },
  },
});

const CreateProjectButton: FC<Props> = ({ iconSize, onClick }) => {
  return (
    <CustomButton
      width="100%"
      height="100%"
      bgGradient="linear(to-br, gradient.default.from, gradient.default.to)"
      onClick={onClick}
    >
      <Stack direction="column" spacing={4} alignItems="center">
        <Icon
          as={AiOutlinePlus}
          color="text.text1"
          width={iconSize.width}
          height={iconSize.height}
        />
        <Text color="text.text1" fontWeight={600} fontSize="md">
          New Project
        </Text>
      </Stack>
    </CustomButton>
  );
};

export default CreateProjectButton;
