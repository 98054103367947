import {
  Box,
  Center,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  forwardRef,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { BsFillLightbulbFill, BsFillLightbulbOffFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import HotKey from '../../../../../components/Hotkey';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import { AnnotationClass } from '../../../../../models/project';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { getProjectUpdatable } from '../../projectSlice';

type ClassRowProps = {
  index: number;
  active: boolean;
  annotationClass: AnnotationClass;
  showPrediction: boolean;
  showAnnotation: boolean;
  onClick: () => void;
  onTogglePredictionVisibility: () => void;
  onToggleAnnotationVisibility: () => void;
  onClickEditButton?: () => void;
};

const MenuTrigger = forwardRef<FlexProps & ClassRowProps & { onRightClick: () => void }, 'div'>(
  (props, ref) => {
    const {
      index,
      onClick,
      active,
      annotationClass,
      showPrediction,
      showAnnotation,
      onTogglePredictionVisibility,
      onToggleAnnotationVisibility,
      onRightClick,
    } = props;

    const { name, color, hotKey } = annotationClass;

    const classNameMaxWidth = useBreakpointValue({
      base: '130px',
      xl: '130px',
      '2xl': '110px',
      '3xl': '200px',
    });

    return (
      <Flex
        {...props}
        ref={ref}
        key={index}
        justifyContent="space-between"
        role="group"
        onContextMenu={(e) => {
          e.preventDefault();
          onRightClick();
        }}
      >
        <Flex
          h="100%"
          w="full"
          padding={1}
          cursor="pointer"
          color={active ? 'gradient.default.from' : 'text.text7'}
          borderBottomWidth={1}
          borderBottomColor={'whiteAlpha.200'}
          _hover={{ color: 'gradient.default.from' }}
          onClick={onClick}
        >
          <Center w={30} minW={30}>
            <Box
              borderWidth="1px"
              borderRadius="2px"
              borderColor={color}
              bg={color}
              w="14px"
              h="14px"
              alignSelf="center"
            />
          </Center>
          <Flex width={'full'} justifyContent={'space-between'} pl={2}>
            <Text
              noOfLines={1}
              maxW={classNameMaxWidth}
              fontSize="sm"
              cursor="pointer"
              _hover={{ color: 'gradient.default.from' }}
              onClick={onClick}
            >
              {capitalizeFirstLetter(name)}
            </Text>
            <HStack
              justifyContent="space-between"
              display="none"
              _groupHover={{ display: 'flex', width: '48px' }}
            >
              <Tooltip
                label={
                  showAnnotation ? TOOLTIP_LABEL.HIDE_ANNOTATION : TOOLTIP_LABEL.SHOW_ANNOTATION
                }
              >
                <IconButton
                  icon={showAnnotation ? <AiFillEye /> : <AiFillEyeInvisible />}
                  aria-label="Annotation visibility"
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleAnnotationVisibility();
                  }}
                  variant="icon"
                  border="none"
                  minW={5}
                  h="100%"
                  color={showAnnotation ? 'text.text7' : 'text.text3'}
                />
              </Tooltip>
              <Tooltip
                label={
                  showPrediction ? TOOLTIP_LABEL.HIDE_PREDICTION : TOOLTIP_LABEL.SHOW_PREDICTION
                }
              >
                <IconButton
                  icon={showPrediction ? <BsFillLightbulbFill /> : <BsFillLightbulbOffFill />}
                  aria-label="Prediction visibility"
                  onClick={(e) => {
                    e.stopPropagation();
                    onTogglePredictionVisibility();
                  }}
                  variant="icon"
                  border="none"
                  minW={5}
                  h="100%"
                  color={showPrediction ? 'text.text7' : 'text.text3'}
                />
              </Tooltip>
            </HStack>

            {hotKey && (
              <Flex justifyContent="flex-end" width="48px" _groupHover={{ display: 'none' }}>
                <HotKey keystrokes={[hotKey]} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

const ClassRow: FC<ClassRowProps> = (props: ClassRowProps) => {
  const menuRef = useRef(null);

  const { onClickEditButton } = props;
  const projectUpdatable = useSelector(getProjectUpdatable);

  const { isOpen: isOpenMenu, onOpen: onOpenMenu, onClose: onCloseMenu } = useDisclosure();

  return (
    <>
      <Menu
        isOpen={isOpenMenu}
        onClose={onCloseMenu}
        closeOnSelect={true}
        placement="right-end"
        isLazy={true}
      >
        <MenuButton as={MenuTrigger} ref={menuRef} {...props} onRightClick={onOpenMenu} />
        <MenuList maxW="fit-content" bgColor="background.bg2" border="none" outline="none">
          <MenuItem
            bgColor="background.bg2"
            _hover={{
              bgColor: 'background.bg4',
            }}
            gap={4}
            onClick={onClickEditButton}
            isDisabled={!projectUpdatable}
          >
            <Icon as={BiEditAlt} color="text.text6" />
            <Text color="text.text6">Edit</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ClassRow;
