import { Route, Routes } from 'react-router-dom';
import { withAuthenticatedUser } from '../auth';
import Project from './Project';
import Projects from './Projects';

const Routers = withAuthenticatedUser(() => {
  return (
    <Routes>
      <Route index element={<Projects />} />
      <Route path="/:projectId" element={<Project />} />
    </Routes>
  );
});

export default Routers;
