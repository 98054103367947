import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { chunk } from 'lodash';
import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OutlineButton from '../../../../../components/OutlineButton';
import { IdbSuperpixelsDAO } from '../../../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchSuperpixels } from '../../projectAsyncThunks';
import { LoadSuperpixelsDataProgress } from './LoadSuperpixelsProgress';

export type LoadSuperpixelsDataPromptProps = {
  onCancel: () => void;
  onLoadedSuperpixelsData: () => void;
};

const LoadSuperpixelsDataPrompt: FC<LoadSuperpixelsDataPromptProps> = ({
  onCancel,
  onLoadedSuperpixelsData,
}) => {
  const { isOpen: isShowAlert, onClose: closeAlert } = useDisclosure({
    defaultIsOpen: true,
  });
  const { isOpen: isShowLoadingProgress, onOpen: showLoadingProgress } = useDisclosure({
    defaultIsOpen: false,
  });

  const dispatch = useDispatch<AppDispatch>();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const projectId = useSelector((state: RootState) => state.project.id);
  const totalNumberOfImages = useSelector((state: RootState) => state.project.totalImages);

  async function fetchSuperpixelsData() {
    const loadedIndices = await IdbSuperpixelsDAO.getInstance().getAllImageIndices(projectId);

    const missingSuperpixelsIndices = Array.from(
      { length: totalNumberOfImages },
      (_, i) => i,
    ).filter((imageIndex) => !loadedIndices.includes(imageIndex));

    const imageIndicesChunk = chunk(missingSuperpixelsIndices, 12);

    for (const chunk of imageIndicesChunk) {
      await dispatch(fetchSuperpixels({ imageIndices: chunk }));
    }
  }

  return (
    <>
      {isShowLoadingProgress && <LoadSuperpixelsDataProgress onClose={onLoadedSuperpixelsData} />}
      <AlertDialog
        isOpen={isShowAlert}
        onClose={onCancel}
        leastDestructiveRef={cancelRef}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            background="background.bg2"
            color="text.text7"
            justifyContent="center"
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="main.orange4">
              Superpixels data needed
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                It might take a few minutes to download necessary data for superpixels. Would you
                like to continue?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex height={8} gap="5%">
                <OutlineButton
                  width="130px"
                  size="sm"
                  background="background.bg4"
                  onClick={onCancel}
                >
                  Cancel
                </OutlineButton>
                <Button
                  variant="gradient"
                  size="sm"
                  width="130px"
                  onClick={() => {
                    showLoadingProgress();
                    closeAlert();
                    fetchSuperpixelsData();
                  }}
                >
                  Continue
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default LoadSuperpixelsDataPrompt;
