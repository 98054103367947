import { FC } from 'react';
import { Image as KonvaImage, Layer } from 'react-konva';
import { useSelector } from 'react-redux';

import configs from '../../../../../configs';
import { RootState } from '../../../../../store';
import { generateImageFromURL } from '../../../../../utils/image';

type AleatoricUncertaintyLayerProps = {
  imageIndex: number;
};

export const AleatoricUncertaintyLayer: FC<AleatoricUncertaintyLayerProps> = ({ imageIndex }) => {
  const aleatoricVisibility = useSelector((state: RootState) => state.project.aleatoricVisibility);
  const aleatoricUncertainty = useSelector(
    (state: RootState) => state.project.aleatoricUncertaintyData,
  );

  if (!aleatoricVisibility || !aleatoricUncertainty) {
    return;
  }

  return (
    <Layer>
      <KonvaImage
        x={0 - configs.IMAGE_SIZE}
        image={generateImageFromURL(aleatoricUncertainty[imageIndex])}
        draggable={false}
        opacity={0.8}
      />
    </Layer>
  );
};
