import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Image as KonvaImage, Line as KonvaLine, Layer, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';

import { useLiveQuery } from 'dexie-react-hooks';
import { Vector2d } from 'konva/lib/types';
import { cloneDeep, debounce } from 'lodash';
import configs from '../../../../../configs';
import { transparentRGBA } from '../../../../../constants/color';
import { IdbSuperpixelsDAO } from '../../../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { Tool } from '../../../../../models/annotation';
import { AppDispatch, RootState } from '../../../../../store';
import { RGB } from '../../../../../types/color';
import { chunkArray } from '../../../../../utils/array';
import { generateRGBObject, generateRGBString } from '../../../../../utils/color';
import { isRightMouseButton } from '../../../../../utils/event';
import { fetchSuperpixelsBoundary, saveAnnotations } from '../../projectAsyncThunks';
import {
  AnnotationMode,
  CompositionWithColor,
  DEFAULT_IMAGE_DATASET,
  changeZoomLevel,
  classToColorMap,
  getProjectUpdatable,
  recordComposition,
} from '../../projectSlice';
import { ImageLayer } from '../ImageLayer';
import { AleatoricUncertaintyLayer } from './AleatoricUncertaintyLayer';
import { EpistemicUncertaintyLayer } from './EpistemicUncertaintyLayer';
import { ImageCanvasHeader } from './ImageCanvasHeader';
import { PredictionLayer } from './PredictionLayer';
import SuperpixelsBoundariesLayer from './SuperpixelsBoundariesLayer';
import ToolSizeIndicatorLayer from './ToolSizeIndicatorLayer';

type Annotation = boolean[][];

type Props = {
  imageIndex: number;
};

const ImageCanvas: FC<Props> = ({ imageIndex }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentImageData = useSelector((state: RootState) => {
    if (!state.project.imageData || !state.project.imageData[imageIndex]) {
      return DEFAULT_IMAGE_DATASET;
    }

    return state.project.imageData[imageIndex];
  });
  const projectId = useSelector((state: RootState) => state.project.id);
  const annotationMode = useSelector((state: RootState) => state.project.annotationMode);
  const superpixelsSegments = useLiveQuery(() =>
    IdbSuperpixelsDAO.getInstance()
      .findOne({ projectId, imageIndex })
      .then((result) => result?.segments),
  );
  const superpixelsSegmentsMatrix = useLiveQuery(() =>
    IdbSuperpixelsDAO.getInstance()
      .findOne({ projectId, imageIndex })
      .then((result) => result?.segmentsMatrix),
  );
  const selectedTool = useSelector((state: RootState) => state.project.selectedTool);
  const toolSize = useSelector((state: RootState) => state.project.toolSize);
  const selectedClass = useSelector((state: RootState) => state.project.selectedClass);
  const annotationClasses = useSelector((state: RootState) => state.project.annotationClasses);
  const annotationsVisibility = useSelector(
    (state: RootState) => state.project.annotationsVisibility,
  );

  const selectedImage = useSelector((state: RootState) => state.project.selectedImage);
  const lastUndo = useSelector((state: RootState) => state.project.lastUndo);
  const lastRedo = useSelector((state: RootState) => state.project.lastRedo);
  const projectUpdatable = useSelector(getProjectUpdatable);

  const [compositions, setCompositions] = useState<CompositionWithColor[]>([]);
  const [dragging, setDragging] = useState<boolean>(false);
  const [imageOffset, setImageOffset] = useState<{ dx: number; dy: number }>({
    dx: 0,
    dy: 0,
  });

  const isDrawing = useRef<boolean>(false);
  const stageRef = useRef<Konva.Stage | null>(null);
  const compositionLayerRef = useRef<Konva.Layer | null>(null);
  const annotationRef = useRef<Annotation[]>(
    annotationClasses.map((_, i) => {
      if (!currentImageData.annotations[i]?.length) {
        return Array.from({ length: 256 }, () => Array.from({ length: 256 }, () => false));
      }

      return cloneDeep(currentImageData.annotations[i]);
    }),
  );

  const draggable = selectedImage === imageIndex;
  const classToColor = useSelector<RootState>(classToColorMap) as ReturnType<
    typeof classToColorMap
  >;
  useEffect(() => {
    if (annotationClasses.length === annotationRef.current.length) {
      return;
    }

    annotationRef.current = annotationRef.current.concat([
      Array.from({ length: 256 }, () => Array.from({ length: 256 }, () => false)),
    ]);
  }, [annotationClasses.length]);

  useEffect(() => {
    if (!currentImageData || !currentImageData.initialCompositions) return;

    if (currentImageData.initialCompositions.length > 0) {
      setCompositions((compositions) => [
        ...compositions,
        ...currentImageData.initialCompositions.map((line) => {
          const color: RGB = classToColor[line.annotationClassId] || transparentRGBA;
          return { ...line, color: generateRGBString(color) };
        }),
      ]);
    }
  }, [classToColor, currentImageData.initialCompositions]);

  useEffect(() => {
    if (annotationMode === AnnotationMode.SUPER_PIXEL)
      dispatch(fetchSuperpixelsBoundary({ imageIndices: [imageIndex] }));
  }, [annotationMode, dispatch, imageIndex]);

  useEffect(() => {
    if (
      !lastUndo ||
      lastUndo?.imageIndex !== imageIndex ||
      !lastUndo?.composition ||
      !projectUpdatable
    ) {
      return;
    }

    const updatedCompositions = compositions.slice(0, compositions.length - 1);
    setCompositions(updatedCompositions);
    handleSaveAnnotations({ newCompositions: updatedCompositions });
  }, [lastUndo]);

  useEffect(() => {
    if (
      !lastRedo ||
      lastRedo?.imageIndex !== imageIndex ||
      !lastRedo?.composition ||
      !projectUpdatable
    ) {
      return;
    }

    const color: RGB = classToColor[lastRedo.composition.annotationClassId] || transparentRGBA;
    const redoComposition: CompositionWithColor = {
      ...lastRedo.composition,
      color: generateRGBString(color),
    };

    const updateCompositions = [...compositions, redoComposition];
    setCompositions(updateCompositions);
    handleSaveAnnotations({ newCompositions: updateCompositions });
  }, [lastRedo]);

  useEffect(() => {
    if (!currentImageData) return;

    const stage = stageRef.current;

    if (!stage) {
      return;
    }

    stage.position({ x: 0, y: 0 });

    stage.batchDraw();
  }, [currentImageData]);

  const handleSaveAnnotations = useCallback(
    debounce((params: { newCompositions: CompositionWithColor[] }) => {
      if (!projectUpdatable) return;

      const { newCompositions } = params;

      const canvas = compositionLayerRef.current?.toCanvas();
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
      const pixelData = imageData.data;

      const classesRGBA = annotationClasses.map((annotationClass) =>
        generateRGBObject(annotationClass.color),
      );

      for (let y = 0; y < canvasHeight; y++) {
        for (let x = 0; x < canvasWidth; x++) {
          const pixelIndex = (y * canvasHeight + x) * 4;
          const originalX = Math.round((imageOffset.dx + x) / currentImageData.zoomLevel);
          const originalY = Math.round((imageOffset.dy + y) / currentImageData.zoomLevel);

          // totally transparent pixel
          if (pixelData[pixelIndex + 3] === 0) {
            for (let i = 0; i < annotationClasses.length; i++) {
              annotationRef.current[i][originalY][originalX] = false;
            }

            continue;
          }

          for (let i = 0; i < classesRGBA.length; i++) {
            const rgba = classesRGBA[i];
            if (
              pixelData[pixelIndex] === rgba.r &&
              pixelData[pixelIndex + 1] === rgba.g &&
              pixelData[pixelIndex + 2] === rgba.b
            ) {
              annotationRef.current[i][originalY][originalX] = true;
            }
          }
        }
      }

      const updatedAnnotations = annotationRef.current.map((annotation) =>
        annotation.flat().map((value) => (value ? 1 : 0)),
      );

      dispatch(
        saveAnnotations({
          imageIndex,
          updatedAnnotations,
          updatedCompositions: newCompositions,
        }),
      ).unwrap();
    }, configs.ANNOTATION_DEBOUNCE_TIMEOUT),
    [
      annotationClasses,
      annotationRef,
      currentImageData.zoomLevel,
      dispatch,
      imageIndex,
      imageOffset.dx,
      imageOffset.dy,
      projectUpdatable,
    ],
  );

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (
        draggable ||
        selectedTool === Tool.MOUSE ||
        !projectUpdatable ||
        annotationClasses.length === 0 ||
        !selectedClass
      ) {
        return;
      }

      isDrawing.current = true;

      if (isDrawing.current && e.evt.button === 2) {
        window.addEventListener('contextmenu', (e) => e.preventDefault());
      }

      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      const pos = stage.getRelativePointerPosition();
      if (!pos) {
        return;
      }

      if (!selectedClass) return;

      const { id, color } = selectedClass;

      switch (annotationMode) {
        case AnnotationMode.SCRIBBLE:
          setCompositions((compositions) => [
            ...compositions,
            {
              annotationMode: AnnotationMode.SCRIBBLE,
              annotationClassId: id,
              tool: isRightMouseButton(e.evt) ? Tool.ERASER : selectedTool,
              color,
              lineSize: toolSize,
              linePoints: [pos.x, pos.y],
            },
          ]);
          break;
        case AnnotationMode.SUPER_PIXEL: {
          if (!superpixelsSegments || !superpixelsSegmentsMatrix) return;

          const segmentIndex = superpixelsSegmentsMatrix
            ?.at(Math.round(pos.y))
            ?.at(Math.round(pos.x));
          if (segmentIndex === undefined) return;
          const pixels = superpixelsSegments[segmentIndex];

          setCompositions((segments) => [
            ...segments,
            {
              annotationClassId: id,
              color,
              annotationMode: AnnotationMode.SUPER_PIXEL,
              tool: isRightMouseButton(e.evt) ? Tool.ERASER : selectedTool,
              segmentPoints: pixels.flat(),
            },
          ]);
        }
      }
    },
    [
      annotationClasses,
      draggable,
      selectedClass,
      selectedTool,
      toolSize,
      annotationMode,
      superpixelsSegments,
      superpixelsSegmentsMatrix,
      projectUpdatable,
    ],
  );

  const handleMouseMove = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (
        draggable ||
        selectedTool === Tool.MOUSE ||
        !projectUpdatable ||
        !isDrawing.current ||
        annotationMode === AnnotationMode.SUPER_PIXEL
      ) {
        return;
      }

      const stage = e.target.getStage();
      if (!stage) {
        return;
      }

      const pos = stage.getRelativePointerPosition();
      if (!pos) {
        return;
      }

      const lastLine = compositions[compositions.length - 1];
      lastLine.linePoints = lastLine?.linePoints?.concat([pos.x, pos.y]);
      compositions.splice(compositions.length - 1, 1, lastLine);

      setCompositions(compositions.concat());
    },
    [draggable, compositions, selectedTool, setCompositions, isDrawing],
  );

  const handleMouseUp = useCallback(() => {
    if (!isDrawing.current || draggable || selectedTool === Tool.MOUSE || !projectUpdatable) {
      return;
    }

    isDrawing.current = false;

    const lastComposition = compositions.at(-1);
    if (lastComposition) {
      dispatch(
        recordComposition({
          imageIndex,
          composition: lastComposition,
        }),
      );
    }

    handleSaveAnnotations({ newCompositions: compositions });
  }, [
    dispatch,
    draggable,
    imageIndex,
    compositions,
    selectedTool,
    isDrawing,
    handleSaveAnnotations,
  ]);

  const handleMouseLeave = useCallback(() => {
    if (!isDrawing.current || draggable || selectedTool === Tool.MOUSE || !projectUpdatable) {
      return;
    }

    isDrawing.current = false;

    const lastComposition = compositions.at(-1);
    if (lastComposition) {
      dispatch(
        recordComposition({
          imageIndex,
          composition: lastComposition,
        }),
      );
    }

    handleSaveAnnotations({ newCompositions: compositions });
  }, [
    dispatch,
    draggable,
    imageIndex,
    compositions,
    selectedTool,
    isDrawing,
    handleSaveAnnotations,
  ]);

  const handleDragBound = useCallback(
    (pos: Vector2d) => {
      const stage = stageRef.current;

      if (!stage) {
        return { x: 0, y: 0 };
      }

      const stageWidth = stage.width();
      const stageHeight = stage.height();
      const newX = Math.max(Math.min(pos.x, 0), stageWidth - stageWidth * stage.scaleX());
      const newY = Math.max(Math.min(pos.y, 0), stageHeight - stageHeight * stage.scaleY());

      return {
        x: newX,
        y: newY,
      };
    },
    [stageRef],
  );

  const getSuperpixelsCompositionCanvas = useCallback((composition: CompositionWithColor) => {
    const matrix = Array.from({ length: 256 }, () => Array.from({ length: 256 }, () => 0));

    if (!composition.segmentPoints) return;

    chunkArray<number>(composition.segmentPoints, 2).forEach(([x, y]) => {
      matrix[y][x] = 1;
    });

    // create a png image from matrix, with 1 value is black, 0 value is white
    const image = new ImageData(256, 256);
    const data = image.data;

    const color = generateRGBObject(composition.color);
    for (let i = 0; i < matrix.length; i++) {
      for (let j = 0; j < matrix[i].length; j++) {
        const index = (i * 256 + j) * 4;
        data[index] = color.r;
        data[index + 1] = color.g;
        data[index + 2] = color.b;
        data[index + 3] = matrix[i][j] * 255;
      }
    }
    const imageCanvas = document.createElement('canvas');
    imageCanvas.width = 256;
    imageCanvas.height = 256;
    const imageCtx = imageCanvas.getContext('2d');
    if (!imageCtx) return;

    // draw the image data to the canvas
    imageCtx.putImageData(image, 0, 0);

    return imageCtx.canvas;
  }, []);

  const handleMouseWheel = useCallback(
    (e: KonvaEventObject<WheelEvent>) => {
      if (selectedTool !== Tool.MOUSE || selectedImage !== imageIndex) {
        return;
      }

      const stage = stageRef.current;

      if (!stage) {
        return { x: 0, y: 0 };
      }

      e.evt.preventDefault();

      const oldScale = stage.scaleX();
      const scaleBy = 1.25;
      const isMouseScrollUp = e.evt.deltaY > 0;

      if (isMouseScrollUp) {
        dispatch(changeZoomLevel(oldScale * scaleBy));
        return;
      }

      if (oldScale / scaleBy < 1) {
        return;
      }

      dispatch(changeZoomLevel(oldScale / scaleBy));
    },
    [dispatch, imageIndex, selectedImage, selectedTool],
  );

  return (
    <div>
      <ImageCanvasHeader imageIndex={imageIndex} />
      <Stage
        ref={stageRef}
        width={configs.IMAGE_SIZE}
        height={configs.IMAGE_SIZE}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        dragBoundFunc={handleDragBound}
        draggable={draggable}
        onWheel={handleMouseWheel}
        style={draggable ? { cursor: dragging ? 'grabbing' : 'grab' } : {}}
        {...(draggable && {
          onDragStart() {
            setDragging(true);
          },
          onDragEnd(e) {
            const pos = e.target.position();
            setImageOffset({
              dx: Math.abs(pos.x),
              dy: Math.abs(pos.y),
            });
            setDragging(false);
          },
        })}
        scale={{ x: currentImageData.zoomLevel, y: currentImageData.zoomLevel }}
      >
        <ImageLayer imageIndex={imageIndex} />

        <PredictionLayer imageIndex={imageIndex} />

        <Layer ref={compositionLayerRef}>
          {compositions.map((composition, compositionIndex) => {
            const annotationClassIndex = annotationClasses.findIndex(
              (value) => value.id === composition.annotationClassId,
            );

            if (!annotationsVisibility[annotationClassIndex]) {
              return <></>;
            }

            if (composition.annotationMode === AnnotationMode.SUPER_PIXEL) {
              if (!composition.segmentPoints) return <></>;

              const imgCanvas = getSuperpixelsCompositionCanvas(composition);

              return (
                <KonvaImage
                  image={imgCanvas}
                  globalCompositeOperation={
                    composition.tool === Tool.ERASER ? 'destination-out' : 'source-over'
                  }
                />
              );
            }

            return (
              <KonvaLine
                key={compositionIndex}
                stroke={composition.color}
                strokeWidth={composition.lineSize}
                points={composition.linePoints}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  composition.tool === Tool.ERASER ? 'destination-out' : 'source-over'
                }
              />
            );
          })}
        </Layer>

        <EpistemicUncertaintyLayer imageIndex={imageIndex} />
        <AleatoricUncertaintyLayer imageIndex={imageIndex} />
        <SuperpixelsBoundariesLayer imageIndex={imageIndex} />
        <ToolSizeIndicatorLayer imageIndex={imageIndex} />
      </Stage>
    </div>
  );
};

export default ImageCanvas;
