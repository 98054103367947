import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { KeyCode } from '../types/hotkey';

type Keystrokes = Array<KeyCode>;
type HotKey = {
  keystrokes: Keystrokes;
  action: () => void;
};

const useHotKey = (
  hotkeys: HotKey[] = [],
): { removeListener: () => void; addListener: () => void } => {
  const [pressedKeys, setPressedKeys] = useState<KeyCode[]>([]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { code } = event;
      if (!pressedKeys.includes(code as KeyCode)) {
        setPressedKeys([...pressedKeys, code as KeyCode]);
      }
    },
    [pressedKeys],
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      const { code } = event;
      const updatedKeys = pressedKeys.filter((pressedKey) => pressedKey !== (code as KeyCode));

      setPressedKeys(updatedKeys);
    },
    [pressedKeys],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    if (!pressedKeys?.length || !hotkeys?.length) return;
    for (const hotkey of hotkeys) {
      const { keystrokes, action } = hotkey;
      if (!keystrokes || !action) continue;

      if (!isEqual(keystrokes.sort(), pressedKeys.sort())) continue;
      action();
      setPressedKeys([]);
    }
  }, [pressedKeys]);

  const removeListener = () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keyup', handleKeyUp);
  };

  const addListener = () => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
  };

  return { removeListener, addListener };
};

export default useHotKey;
