import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Input,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OutlineButton from '../../../../../components/OutlineButton';
import { MODEL_FILE_EXTENSION } from '../../../../../constants/fileExtension';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { importModel } from '../../projectAsyncThunks';
import { TrainingStatus, getFetchingInferenceResult } from '../../projectSlice';

const ImportModelButton: FC = () => {
  const {
    isOpen: isOpenAlertModal,
    onOpen: onOpenAlertModal,
    onClose: onCloseAlertModal,
  } = useDisclosure();
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const projectStatus = useSelector((state: RootState) => state.project.status);
  const fetchingInferenceResult = useSelector(getFetchingInferenceResult);

  const disableImportButton =
    projectStatus !== ProjectStatus.IN_PROGRESS ||
    trainingStatus === TrainingStatus.INITIALIZING ||
    fetchingInferenceResult ||
    liveUpdateOn;

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;
    setLoading(true);
    if (!selectedFile.name.endsWith(MODEL_FILE_EXTENSION)) {
      setLoading(false);
      Promise.reject(new Error('Invalid file type'));
      return;
    }
    try {
      await dispatch(importModel(selectedFile));
      setLoading(false);
      navigate(0);
    } catch (err) {
      Promise.reject(new Error('Failed to import model'));
    }
  };

  return (
    <>
      <Tooltip label={TOOLTIP_LABEL.IMPORT_MODEL} hasArrow>
        <Flex>
          <Input
            type="file"
            accept=".pkl"
            onChange={handleFileChange}
            ref={(input) => (fileInputRef.current = input)}
            style={{ display: 'none' }}
          />
          <Button
            size="sm"
            variant="outline"
            isDisabled={disableImportButton || loading}
            isLoading={loading}
            onClick={onOpenAlertModal}
          >
            Import Model
          </Button>
        </Flex>
      </Tooltip>
      <AlertDialog
        isOpen={isOpenAlertModal}
        onClose={onCloseAlertModal}
        leastDestructiveRef={cancelRef}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            background="background.bg2"
            color="text.text7"
            justifyContent="center"
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="main.orange4">
              Caution
            </AlertDialogHeader>
            <AlertDialogBody>
              <Flex direction="column" gap={2}>
                <Text>
                  Import model will reset all annotated data, classes and the training process. Are
                  you sure to continue?
                </Text>
                <Text color="text.text5" fontSize="sm">
                  Supported type: .pkl
                </Text>
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex height={8} gap="5%">
                <OutlineButton
                  width="130px"
                  size="sm"
                  background="background.bg4"
                  onClick={onCloseAlertModal}
                >
                  Cancel
                </OutlineButton>
                <Button
                  variant="gradient"
                  size="sm"
                  width="130px"
                  onClick={() => {
                    handleClick();
                    onCloseAlertModal();
                  }}
                  isDisabled={loading}
                >
                  Confirm
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ImportModelButton;
