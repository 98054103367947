import { useAuth0 } from '@auth0/auth0-react';
import { createContext, FC, useContext, useState } from 'react';
import Loading from '../../components/Loading.tsx';
import { User } from '../../models/user.ts';

type ContextType = {
  user: User;
};

const Context = createContext<ContextType>({
  user: { id: '', name: '', email: '' },
});

export const withAuthenticatedUser = (Component: FC) => {
  return (props: Record<string, unknown>) => {
    const { user, getIdTokenClaims } = useAuth0();
    const [token, setToken] = useState('');

    if (!user) {
      setToken('');

      return <Loading />;
    }

    if (!token) {
      getIdTokenClaims().then((token) => {
        if (token) {
          setToken(token.__raw);
          localStorage.setItem('accessToken', token.__raw);
        }
      });

      return <Loading />;
    }

    return (
      <Context.Provider value={{ user: user as User }}>
        <Component {...props} />
      </Context.Provider>
    );
  };
};

export const useAuthenticatedUser = (): ContextType => useContext(Context);
