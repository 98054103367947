import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import configs from '../../../configs';
import { IdbSuperpixelsDAO } from '../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { Project } from '../../../models/project';
import { RootState } from '../../../store';
import { api } from '../../../utils/axios';
import { blobService } from '../../../utils/blob';

export type ProjectListItem = Omit<
  Project,
  'annotationClasses' | 'predictions' | 'annotations' | 'lines'
> & {
  thumbnail?: string;
};

export interface ProjectsState {
  projects: ProjectListItem[];
}

const initialState: ProjectsState = {
  projects: [],
};

const generateProjectThumbnail = async (project: ProjectListItem): Promise<ProjectListItem> => {
  const containerClient = blobService.getContainerClient(configs.AZURE_PUBLIC_CONTAINER_NAME);

  if (!(await containerClient.exists())) {
    throw new Error('Blob service error: container does not exist');
  }

  for await (const blobItem of containerClient.listBlobsFlat({
    prefix: `thumbnails/${project.id}`,
  })) {
    const res = await containerClient.getBlobClient(blobItem.name).download();

    if (res.blobBody) {
      const blob = await res.blobBody;
      project.thumbnail = URL.createObjectURL(blob);
    }

    break;
  }

  return project;
};

export const fetchProjects = createAsyncThunk<
  ProjectListItem[],
  {
    name?: string;
  },
  { state: RootState }
>('projects/fetchProjects', async ({ name }): Promise<ProjectListItem[]> => {
  try {
    const response = await api.get<ProjectListItem[]>(
      `/projects${name ? `?name=${encodeURIComponent(name)}` : ''}`,
    );

    const projects = response.data;

    const promises: Promise<ProjectListItem>[] = projects.map(generateProjectThumbnail);

    const projectsWithThumbnail = await Promise.all(promises);

    return projectsWithThumbnail;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const createProject = createAsyncThunk<
  Project,
  { name: string; description: string },
  { state: RootState }
>('projects/createProject', async ({ name, description }): Promise<Project> => {
  try {
    const response = await api.post<Project>('/projects', {
      name,
      description,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const deleteProject = createAsyncThunk<string, string, { state: RootState }>(
  'projects/deleteProject',
  async (projectId: string): Promise<string> => {
    try {
      await IdbSuperpixelsDAO.getInstance().delete(projectId);
      await api.delete(`/projects/${projectId}`);

      return projectId;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });

    builder.addCase(createProject.fulfilled, (state, action) => {
      state.projects = [...state.projects, action.payload];
    });

    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.projects = state.projects.filter(({ id }) => id !== action.payload);
    });
  },
});

export default projectsSlice.reducer;
