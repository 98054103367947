import { Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { BsFillLightbulbFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import { AppDispatch, RootState } from '../../../../../store';
import { toggleAllAnnotationVisibility, toggleAllPredictionVisibility } from '../../projectSlice';

const ClassHeader: FC = () => {
  const annotationsVisibility = useSelector(
    (state: RootState) => state.project.annotationsVisibility,
  );
  const predictionsVisibility = useSelector(
    (state: RootState) => state.project.predictionsVisibility,
  );

  const dispatch = useDispatch<AppDispatch>();

  const showAllAnnotation = useMemo(() => {
    return Object.values(annotationsVisibility).every((value) => value);
  }, [annotationsVisibility]);

  const showAllPrediction = useMemo(() => {
    return Object.values(predictionsVisibility).every((value) => value);
  }, [predictionsVisibility]);

  const handleAllClassVisibilityToggle = () => {
    dispatch(toggleAllAnnotationVisibility());
  };

  const handleAllPredictionVisibilityToggle = () => {
    dispatch(toggleAllPredictionVisibility());
  };

  return (
    <Flex justifyContent="space-between" padding={1}>
      <HStack gap={2}>
        <Text align="center" fontSize={10} width={30} color="text.text4">
          Color
        </Text>
        <Text fontSize={10} color="text.text4">
          Name
        </Text>
      </HStack>
      <HStack justifyContent="space-between">
        <Tooltip
          label={
            showAllAnnotation
              ? TOOLTIP_LABEL.HIDE_ALL_ANNOTATION
              : TOOLTIP_LABEL.SHOW_ALL_ANNOTATION
          }
        >
          <IconButton
            icon={<AiFillEye />}
            aria-label="Annotation visibility"
            onClick={handleAllClassVisibilityToggle}
            variant="icon"
            border="none"
            minW={5}
            h="100%"
            color={showAllAnnotation ? 'text.text7' : 'text.text3'}
          />
        </Tooltip>
        <Tooltip
          label={
            showAllPrediction
              ? TOOLTIP_LABEL.HIDE_ALL_PREDICTION
              : TOOLTIP_LABEL.SHOW_ALL_PREDICTION
          }
        >
          <IconButton
            icon={<BsFillLightbulbFill />}
            aria-label="Prediction visibility"
            onClick={handleAllPredictionVisibilityToggle}
            variant="icon"
            border="none"
            minW={5}
            h="100%"
            color={showAllPrediction ? 'text.text7' : 'text.text3'}
          />
        </Tooltip>
      </HStack>
    </Flex>
  );
};

export default ClassHeader;
