import '@fontsource/poppins';
import { buttonTheme } from './components/button';
import { popoverTheme } from './components/popover';
import { switchTheme } from './components/switch';
import { textTheme } from './components/text';
import {
  BACKGROUND_COLORS_TOKEN,
  INDICATOR_COLORS_TOKEN,
  MAIN_COLORS_TOKEN,
  TEXT_COLORS_TOKEN,
} from './designToken';

export const APP_THEME = {
  /** For built-in chakra-ui component **/
  components: {
    Button: buttonTheme,
    Text: textTheme,
    Popover: popoverTheme,
    Switch: switchTheme,
  },
  colors: {
    main: {
      ...MAIN_COLORS_TOKEN,
    },
    background: {
      ...BACKGROUND_COLORS_TOKEN,
    },
    gradient: {
      default: {
        from: MAIN_COLORS_TOKEN.purple4,
        to: MAIN_COLORS_TOKEN.orange4,
      },
    },
    text: {
      ...TEXT_COLORS_TOKEN,
    },
    indicator: {
      ...INDICATOR_COLORS_TOKEN,
    },
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
  breakpoints: {
    xl: '1440px',
    '2xl': '1680px',
    '3xl': '1920px',
  },
};
