import { createAsyncThunk } from '@reduxjs/toolkit';
import pako from 'pako';
import FileResizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';

import { AxiosError } from 'axios';
import configs from '../../../configs';
import { MODEL_FILE_EXTENSION } from '../../../constants/fileExtension';
import { Annotation, Composition } from '../../../models/annotation';
import { AnnotationClass, Project, ProjectStatus } from '../../../models/project';
import { RootState } from '../../../store';
import { addNumberPadding } from '../../../utils/addNumberPadding';
import { api } from '../../../utils/axios';
import { blobService } from '../../../utils/blob';
import { getErrorMessage } from '../../../utils/error';
import { loadModel } from '../../../utils/file';
import { downloadImages, getImageDimensionsFromArrayBuffer } from '../../../utils/image';
import { PubSubClient } from '../../../utils/pubsubClient';
import { TrainingStatus, getPagingImageIndices } from './projectSlice';

type FetchSuperpixelsRespDto = {
  projectId: string;
  imageIndex: number;
  segments: string;
  segmentsMatrix: string;
};

export const fetchProject = createAsyncThunk<Project, string>(
  'project/fetchProject',
  async (projectId) => {
    try {
      const response = await api.get<Project>(`projects/${projectId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const fetchModelMetrics = createAsyncThunk<
  Pick<Project, 'avgDiceScore' | 'errorDiceScore' | 'avgPrecision' | 'avgRecall'>,
  void,
  { state: RootState }
>('project/fetchModelMetrics', async (_, { getState }) => {
  try {
    const { id: projectId } = getState().project;

    const response = await api.get<
      Pick<Project, 'avgDiceScore' | 'errorDiceScore' | 'avgPrecision' | 'avgRecall'>
    >(`projects/${projectId}`, {
      params: {
        select: 'avgDiceScore errorDiceScore avgPrecision avgRecall',
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const fetchAnnotations = createAsyncThunk<Annotation[], string, { state: RootState }>(
  'project/fetchAnnotations',
  async (projectId, { getState }) => {
    try {
      const pagingImageIndices = getPagingImageIndices(getState());

      const response = await api.get<Annotation[]>('annotations', {
        params: {
          projectId,
          imageIndices: JSON.stringify(pagingImageIndices),
          select: 'annotations compositions imageIndex',
        },
      });

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);

export const fetchAnnotationWithFilter = createAsyncThunk<
  Annotation[],
  boolean | undefined,
  { state: RootState }
>('project/fetchAnnotationWithFilter', async (isAnnotated, { getState }) => {
  try {
    const { id } = getState().project;

    const response = await api.get<Annotation[]>('annotations', {
      params: {
        projectId: id,
        select: 'compositions imageIndex',
        isAnnotated,
      },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const fetchImages = createAsyncThunk<
  { imageIndex: number; url: string }[],
  string,
  { state: RootState }
>('project/fetchImages', async (projectId, { getState }) => {
  const pagingImageIndices = getPagingImageIndices(getState());

  const containerClient = blobService.getContainerClient(configs.AZURE_PUBLIC_CONTAINER_NAME);
  const blobList = containerClient.listBlobsFlat({ prefix: projectId });
  const imageUrls: { imageIndex: number; url: string }[] = [];

  const promiseFetchImages: Promise<void>[] = [];

  let imageIndex = 0;
  for await (const { name } of blobList) {
    if (imageUrls.length === pagingImageIndices.length) {
      break;
    }

    try {
      if (!pagingImageIndices.includes(imageIndex)) {
        imageIndex++;
        continue;
      }

      promiseFetchImages.push(
        (async () => {
          const newImageUrl: { imageIndex: number; url: string } = {
            imageIndex,
            url: '',
          };

          const response = await containerClient.getBlobClient(name).download();

          if (!response.blobBody) {
            return;
          }

          const blob = await response.blobBody;
          newImageUrl.url = URL.createObjectURL(blob);

          imageUrls.push(newImageUrl);
        })(),
      );
      imageIndex++;
    } catch (error) {
      Promise.reject(error);
    }
  }

  await Promise.all(promiseFetchImages);

  return imageUrls;
});

export const saveAnnotations = createAsyncThunk<
  void,
  {
    imageIndex: number;
    updatedAnnotations: number[][];
    updatedCompositions: Composition[];
  },
  { state: RootState }
>(
  'project/saveAnnotations',
  async ({ imageIndex, updatedAnnotations, updatedCompositions }, { getState }) => {
    try {
      const { id, status } = getState().project;

      let compressedAnnotations;
      if (
        updatedAnnotations.some((annotationByClass) => annotationByClass.some((point) => point))
      ) {
        compressedAnnotations = updatedAnnotations.map((annotationByClass) => {
          const compressedAnnotation = pako.gzip(
            JSON.stringify(annotationByClass),
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            { to: 'string' },
          );

          const base64CompressedAnnotation = btoa(
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            String.fromCharCode.apply(null, compressedAnnotation),
          );

          return base64CompressedAnnotation;
        });
      } else {
        compressedAnnotations = updatedAnnotations.map(() => '');
      }

      await api.put(
        '/annotations',
        {
          annotations: compressedAnnotations,
          compositions: updatedCompositions,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            projectId: id,
            imageIndex,
            trainable: status !== ProjectStatus.UPLOADING && status !== ProjectStatus.DRAFT,
          },
        },
      );
    } catch (error) {
      Promise.reject(error);
    }
  },
);

export const updateAnnotationClass = createAsyncThunk<
  AnnotationClass[],
  AnnotationClass,
  { state: RootState }
>('project/saveAnnotationClass', async (annotationClass, { getState }) => {
  try {
    const { id } = getState().project;

    const response = await api.put<AnnotationClass[]>(`/projects/${id}/annotation-class`, {
      annotationClass,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const saveAnnotationClasses = createAsyncThunk<
  AnnotationClass[],
  AnnotationClass,
  { state: RootState }
>('project/saveAnnotationClasses', async (newAnnotationClass, { getState }) => {
  try {
    const { id, annotationClasses } = getState().project;

    const updatedAnnotationClasses = [...annotationClasses, newAnnotationClass];

    const response = await api.put<AnnotationClass[]>(`/projects/${id}/annotation-classes`, {
      annotationClasses: updatedAnnotationClasses,
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const fetchPrediction = createAsyncThunk<
  { imageIndex: number; url: string }[],
  void,
  { state: RootState }
>('project/fetchPrediction', async (_, { getState }) => {
  const { id: projectId } = getState().project;
  const pagingImageIndices = getPagingImageIndices(getState());

  const imageUrls = await downloadImages(
    configs.AZURE_PREDICTION_CONTAINER_NAME,
    projectId,
    pagingImageIndices,
  );

  return imageUrls;
});

export const fetchUncertainty = createAsyncThunk<
  { imageIndex: number; url: string }[],
  void,
  { state: RootState }
>('project/fetchUncertainty', async (_, { getState }) => {
  const { id: projectId } = getState().project;
  const pagingImageIndices = getPagingImageIndices(getState());

  const imageUrls = await downloadImages(
    configs.AZURE_UNCERTAINTY_CONTAINER_NAME,
    projectId,
    pagingImageIndices,
  );

  return imageUrls;
});

export const fetchTrainingProgress = createAsyncThunk<number, void, { state: RootState }>(
  'project/fetchTrainingProgress',
  async (_, { getState }) => {
    try {
      const { id } = getState().project;

      if (!id) {
        return 0;
      }

      const response = await api.get(`projects/${id}`, {
        params: {
          select: 'trainingProgress',
        },
      });

      return response.data.trainingProgress;
    } catch (error) {
      console.error('Error fetching training progress', error);
    }
  },
);

export const fetchTrainingStatus = createAsyncThunk<TrainingStatus, void, { state: RootState }>(
  'project/fetchTrainingStatus',
  async (_, { getState }) => {
    try {
      const { id } = getState().project;

      if (!id) {
        return TrainingStatus.STOP;
      }

      const response = await api.get(`projects/${id}`, {
        params: {
          select: 'trainingStatus',
        },
      });

      return response.data.trainingStatus;
    } catch (error) {
      console.error('Error fetching training status', error);
    }
  },
);

export const fetchTrainable = createAsyncThunk<boolean, void, { state: RootState }>(
  'project/fetchTrainable',
  async (_, { getState }) => {
    const { id } = getState().project;

    if (!id) {
      return false;
    }

    const response = await api.get<{
      trainable: boolean;
    }>(`projects/${id}`, {
      params: {
        select: 'trainable',
      },
    });

    return response.data.trainable;
  },
);

export const fetchSuperpixels = createAsyncThunk<
  FetchSuperpixelsRespDto[],
  { imageIndices: number[] },
  { state: RootState }
>('project/fetchSuperpixels', async ({ imageIndices }, { getState }) => {
  try {
    const { id } = getState().project;

    const response = await api.get<FetchSuperpixelsRespDto[]>(`projects/${id}/superpixels`, {
      params: {
        imageIndices: JSON.stringify(imageIndices),
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`${error}`);
  }
});

export const fetchSuperpixelsBoundary = createAsyncThunk<
  { imageIndex: number; url: string }[],
  { imageIndices: number[] },
  { state: RootState }
>('project/fetchSuperpixelsBoundary', async ({ imageIndices }, { getState }) => {
  const { id: projectId } = getState().project;
  // const pagingImageIndices = getPagingImageIndices(getState());

  const imageUrls = await downloadImages(
    configs.AZURE_SUPERPIXELS_BOUNDARY_CONTAINER_NAME,
    projectId,
    imageIndices,
  );

  return imageUrls;
});

export const uploadImages = createAsyncThunk<
  void,
  { projectId: string; images: File[] },
  { state: RootState }
>('project/uploadImages', async ({ projectId, images }) => {
  try {
    const originalContainerClient = blobService.getContainerClient(
      configs.AZURE_ORIGINAL_CONTAINER_NAME,
    );
    const publicContainerClient = blobService.getContainerClient(
      configs.AZURE_PUBLIC_CONTAINER_NAME,
    );

    if (!(await originalContainerClient.exists())) {
      await originalContainerClient.create();
    }
    if (!(await publicContainerClient.exists())) {
      await publicContainerClient.create();
    }

    const blobList = publicContainerClient.listBlobsFlat({ prefix: projectId });
    const existingPrefixes: string[] = [];

    for await (const { name } of blobList) {
      const urlParts = name.split('/');
      const prefix = urlParts[1].split('_')[0];

      if (!existingPrefixes.includes(prefix)) {
        existingPrefixes.push(prefix);
      }
    }

    const fileNames: string[] = [];
    const numberPaddingMaxValue = 10_000;
    const skipSplitImageFileNames: string[] = [];

    const uploadImagePromises: Promise<unknown>[] = images.map(async (image, index) => {
      const prefix = addNumberPadding(
        existingPrefixes.length > 0 ? existingPrefixes.length + index : index,
        numberPaddingMaxValue,
      );

      const uniqueFileName = `${prefix}_${uuidv4()}_${image.name.replace(/\s+/g, '-')}`;

      const arrayBuffer = await image.arrayBuffer();
      const imageSize = await getImageDimensionsFromArrayBuffer(arrayBuffer);

      if (imageSize.width === imageSize.height && imageSize.width === configs.IMAGE_SIZE) {
        const uniqueFileNameForSkipSplitImages = `${uniqueFileName.replace(
          /\.(jpg|jpeg|png|bmp)$/,
          '_0_0.$1',
        )}`;

        const blobPublicClient = publicContainerClient.getBlockBlobClient(
          `${projectId}/${uniqueFileNameForSkipSplitImages}`,
        );

        await blobPublicClient.uploadData(arrayBuffer);
        skipSplitImageFileNames.push(uniqueFileName);
      }

      fileNames.push(uniqueFileName);

      const blobClient = originalContainerClient.getBlockBlobClient(
        `${projectId}/${uniqueFileName}`,
      );

      return await blobClient.uploadData(arrayBuffer);
    });

    // upload thumbnail
    uploadImagePromises.push(
      (async () => {
        const firstImage = images[0];
        const format = firstImage.type.split('/')[1] || 'jpg';

        const whenURIReady = (uri: string | Blob | File | ProgressEvent<FileReader>) => {
          if (existingPrefixes.length) {
            return;
          }

          fetch(uri as string).then(async (value) => {
            const blobClient = publicContainerClient.getBlockBlobClient(
              `thumbnails/${projectId}.${format}`,
            );
            return blobClient.uploadData(await value.blob());
          });
        };

        FileResizer.imageFileResizer(
          new Blob([await firstImage.arrayBuffer()]),
          configs.THUMBNAIL_SIZE,
          configs.THUMBNAIL_SIZE,
          format,
          100,
          0,
          whenURIReady,
          'base64',
          configs.THUMBNAIL_SIZE,
          configs.THUMBNAIL_SIZE,
        );
      })(),
    );

    await Promise.all(uploadImagePromises);

    const response = await api.post(`/projects/${projectId}/images`, {
      projectId,
      fileNames,
      skipSplitImageFileNames,
    });

    return response.data;
  } catch (error) {
    Promise.reject(error);
  }
});

export const updateProject = createAsyncThunk<Project, Partial<Project>, { state: RootState }>(
  'project/updateProject',
  async (project, { getState }) => {
    const { id } = getState().project;

    const updatedProject = await api.put(`projects/${id}`, project);

    return updatedProject.data;
  },
);

export const exportModel = createAsyncThunk<void, number, { state: RootState }>(
  'project/exportModel',
  async (timeRecorded, { getState }) => {
    try {
      const { id } = getState().project;

      await api.post(`projects/${id}/export-model-event`, {
        timeRecorded,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      throw new AxiosError(
        getErrorMessage(axiosError),
        axiosError.code,
        axiosError.config,
        axiosError.request,
        axiosError.response,
      );
    }
  },
);

export const importModel = createAsyncThunk<void, File, { state: RootState }>(
  'project/importModel',
  async (file, { getState }) => {
    const { id: projectId } = getState().project;
    const containerClient = blobService.getContainerClient(
      configs.AZURE_IMPORT_MODEL_CONTAINER_NAME,
    );

    const blobClient = containerClient.getBlockBlobClient(`${projectId}.${MODEL_FILE_EXTENSION}`);

    await blobClient.uploadData(file, {
      blobHTTPHeaders: {
        blobContentType: file.type,
      },
    });

    const annotationClasses = (await loadModel(file))['annotationClasses'] as AnnotationClass[];

    await api.post(`projects/${projectId}/import-model`, {
      annotationClasses,
    });
  },
);

export const update = createAsyncThunk<
  void,
  { eventType: string; timeRecorded: number },
  { state: RootState }
>('project/update', async (params, { getState }) => {
  try {
    const { id } = getState().project;
    const { eventType, timeRecorded } = params;
    const pagingImageIndices = getPagingImageIndices(getState());

    await api.post(`projects/${id}/update-event`, {
      imageIndices: pagingImageIndices,
      eventType,
      timeRecorded,
    });
  } catch (error) {
    const axiosError = error as AxiosError;
    throw new AxiosError(
      getErrorMessage(axiosError),
      axiosError.code,
      axiosError.config,
      axiosError.request,
      axiosError.response,
    );
  }
});

export const loadUncertainty = createAsyncThunk<void, number, { state: RootState }>(
  'project/loadUncertainty',
  async (timeRecorded, { getState }) => {
    try {
      const { id } = getState().project;
      const pagingImageIndices = getPagingImageIndices(getState());

      await api.post(`projects/${id}/load-uncertainty-event`, {
        imageIndices: pagingImageIndices,
        timeRecorded,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      throw new AxiosError(
        getErrorMessage(axiosError),
        axiosError.code,
        axiosError.config,
        axiosError.request,
        axiosError.response,
      );
    }
  },
);

export const suggestImages = createAsyncThunk<void, number, { state: RootState }>(
  'project/suggestImages',
  async (timeRecorded, { getState }) => {
    try {
      const { id } = getState().project;

      await api.post(`projects/${id}/suggest-images-event`, {
        timeRecorded,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      throw new AxiosError(
        getErrorMessage(axiosError),
        axiosError.code,
        axiosError.config,
        axiosError.request,
        axiosError.response,
      );
    }
  },
);

export const calculateModelMetric = createAsyncThunk<void, number, { state: RootState }>(
  'project/calculateModelMetric',
  async (timeRecorded, { getState }) => {
    try {
      const { id } = getState().project;

      await api.post(`projects/${id}/calculate-metrics-event`, {
        timeRecorded,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      throw new AxiosError(
        getErrorMessage(axiosError),
        axiosError.code,
        axiosError.config,
        axiosError.request,
        axiosError.response,
      );
    }
  },
);

export const completeProject = createAsyncThunk<void, number, { state: RootState }>(
  'project/completeProject',
  async (timeRecorded, { getState }) => {
    try {
      const { id } = getState().project;

      await api.post(`projects/${id}/complete-event`, {
        timeRecorded,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      throw new AxiosError(
        getErrorMessage(axiosError),
        axiosError.code,
        axiosError.config,
        axiosError.request,
        axiosError.response,
      );
    }
  },
);

export const initPubSubClient = createAsyncThunk<PubSubClient, string, { state: RootState }>(
  'project/initPubSubClient',
  async (projectId: string) => {
    const response = await api.get(`projects/${projectId}/pub-sub-token`);
    const pubSubClient = new PubSubClient(response.data);

    pubSubClient.start().then(() => {
      pubSubClient.joinGroup(projectId);
    });

    return pubSubClient;
  },
);

export const testSentry = createAsyncThunk<void, void, { state: RootState }>(
  'project/testSentry',
  async () => {
    try {
      api.post('projects/test-sentry');
    } catch (error) {
      Promise.reject(error);
    }
  },
);

export const recordEventTrace = createAsyncThunk<
  void,
  {
    eventId: string;
    eventType: string;
    eventReceivedOnClientAt: number;
  },
  { state: RootState }
>('project/recordEventTrace', async (params, { getState }) => {
  try {
    const { id } = getState().project;
    const { eventId, eventType, eventReceivedOnClientAt } = params;
    await api.post(`traces/?projectId=${id}&eventId=${eventId}`, {
      eventType,
      eventReceivedOnClientAt,
    });
  } catch (error) {
    Promise.reject(error);
  }
});
