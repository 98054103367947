import { Box, Divider, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../store';

type ImageCanvasHeaderProps = {
  imageIndex: number;
};

export const ImageCanvasHeader: FC<ImageCanvasHeaderProps> = ({ imageIndex }) => {
  const suggestImageIndices = useSelector((state: RootState) => state.project.suggestImageIndices);
  const metricVisibility = useSelector((state: RootState) => state.project.metricVisibility);
  const metricUncertainty = useSelector((state: RootState) => {
    if (!state.project.metricUncertaintyData) {
      return 1;
    }

    return state.project.metricUncertaintyData[imageIndex] ?? 1;
  });

  return (
    <HStack bg="background.bg3" h={5} p={1} gap="4px">
      {suggestImageIndices.length > 0 && suggestImageIndices.includes(imageIndex) && (
        <Box
          bgColor="indicator.error4"
          borderColor="background.bg4"
          borderWidth={0.5}
          w={3}
          h={3}
        />
      )}

      <Text color="text.text7" fontSize="12px" fontWeight="bold">
        {imageIndex + 1}
      </Text>

      {metricVisibility && (
        <>
          <Divider orientation="vertical" color="background.bg4" />
          <Text color="main.purple7" fontSize="12px" fontWeight={500}>
            {metricUncertainty?.toFixed(4)}
          </Text>
        </>
      )}
    </HStack>
  );
};
