import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import Loading from '../../components/Loading.tsx';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } }).then();
  }, []);

  return <Loading />;
};

export default Logout;
