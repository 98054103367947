import { Box, Button, Flex, Icon, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import configs from '../../../../../configs';
import { ANNOTATION_CLASS_HOT_KEY_MAP } from '../../../../../constants/hotkey';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useHotKey from '../../../../../hooks/useHotKey';
import { AnnotationClass } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchAnnotations, fetchProject } from '../../projectAsyncThunks';
import {
  changeAnnotationVisibility,
  changeClass,
  changePredictionVisibility,
  getProjectUpdatable,
} from '../../projectSlice';
import AnnotationClassSetupModal, { FormMode } from './AnnotationClassSetupModal';
import ClassHeader from './ClassHeader';
import ClassRow from './ClassRow';
import ClassesIcon from '/classes.svg';

const Classes: FC = () => {
  const {
    isOpen: isOpenClassCreateModal,
    onOpen: onOpenClassCreateModal,
    onClose: onCloseClassCreateModal,
  } = useDisclosure();

  const {
    isOpen: isOpenClassEditModal,
    onOpen: onOpenClassEditModal,
    onClose: onCloseClassEditModal,
  } = useDisclosure();

  const projectId = useSelector((state: RootState) => state.project.id);
  const annotationClasses = useSelector((state: RootState) => state.project.annotationClasses);
  const selectedClass = useSelector((state: RootState) => state.project.selectedClass);
  const annotationsVisibility = useSelector(
    (state: RootState) => state.project.annotationsVisibility,
  );
  const predictionsVisibility = useSelector(
    (state: RootState) => state.project.predictionsVisibility,
  );
  const projectUpdatable = useSelector<RootState>(getProjectUpdatable) as ReturnType<
    typeof getProjectUpdatable
  >;

  const [targetClass, setTargetClass] = useState<AnnotationClass>({} as AnnotationClass);

  const dispatch = useDispatch<AppDispatch>();

  const annotationClassesReachLimit = annotationClasses.length >= configs.ANNOTATION_CLASSES_LIMIT;

  const { removeListener: removeHotKeyListener, addListener: addHotKeyListener } = useHotKey(
    annotationClasses.map((annotationClass) => {
      if (!annotationClass.hotKey) return { keystrokes: [], action: () => {} };

      const keystrokes = ANNOTATION_CLASS_HOT_KEY_MAP[annotationClass.hotKey];
      return {
        keystrokes,
        action: () => {
          dispatch(changeClass(annotationClass));
        },
      };
    }),
  );

  const handleClassChange = (value: AnnotationClass) => {
    dispatch(changeClass(value));
  };

  const handleAnnotationClassCreateModalOpen = () => {
    onOpenClassCreateModal();
    removeHotKeyListener();
  };

  const handleAnnotationClassCreateModalClose = () => {
    onCloseClassCreateModal();
    addHotKeyListener();
  };

  const handleAnnotationClassEditModalOpen = () => {
    onOpenClassEditModal();
    removeHotKeyListener();
  };

  const handleAnnotationClassEditModalClose = () => {
    onCloseClassEditModal();
    addHotKeyListener();
  };

  const handleClassVisibilityToggle = (index: number) => {
    dispatch(changeAnnotationVisibility({ index, value: !annotationsVisibility[index] }));
  };

  const handlePredictionVisibilityToggle = (index: number) => {
    dispatch(changePredictionVisibility({ index, value: !predictionsVisibility[index] }));
  };

  const handleCreateClassSuccess = async () => {
    if (annotationClasses.length === 0) {
      await dispatch(fetchProject(projectId));
    }
    onCloseClassCreateModal();
    addHotKeyListener();

    return;
  };

  const handleEditClassSuccess = async () => {
    await dispatch(fetchAnnotations(projectId));
    onCloseClassEditModal();
    addHotKeyListener();
  };

  return (
    <>
      <Flex height="full" bg="background.bg3" borderRadius="lg" flexDirection="column" p="12px">
        <Flex justifyContent="space-between">
          <Flex>
            <Box alignSelf="center">
              <img src={ClassesIcon} alt="Classes Icon" />
            </Box>
            <Text alignSelf="center" color="text.text7" fontSize="md" fontWeight="bold" ml={2}>
              Classes
            </Text>
          </Flex>
          <Tooltip
            label={
              (annotationClassesReachLimit && TOOLTIP_LABEL.ANNOTATION_CLASS_LIMIT) ||
              (!projectUpdatable && TOOLTIP_LABEL.CANNOT_CREATE_CLASS_SINCE_PROJECT_IS_COMPLETED) ||
              ''
            }
          >
            <Button
              width="50px"
              height="32px"
              variant="outline"
              borderRadius="32px"
              borderColor="background.bg5"
              isDisabled={annotationClassesReachLimit || !projectUpdatable}
              _hover={{
                backgroundColor: 'background.bg4',
                borderColor: 'gradient.default.from',
                '> *': { color: 'gradient.default.from' },
              }}
              onClick={handleAnnotationClassCreateModalOpen}
            >
              <Icon as={AiOutlinePlus} color="text.text4" />
            </Button>
          </Tooltip>
        </Flex>
        <Stack mt={2}>
          <ClassHeader />
          {annotationClasses.length === 0 && (
            <Text color="text.text5" fontSize="sm" textAlign="center">
              No classes
            </Text>
          )}
          {annotationClasses.length &&
            Object.keys(selectedClass).length &&
            annotationClasses.map(({ id, name, color, hotKey, description }, index) => (
              <ClassRow
                key={id}
                index={index}
                onClick={() => handleClassChange({ id, name, color })}
                active={selectedClass.id === id}
                annotationClass={{ id, name, color, hotKey, description }}
                showPrediction={predictionsVisibility[index]}
                showAnnotation={annotationsVisibility[index]}
                onClickEditButton={() => {
                  setTargetClass({ id, name, color, hotKey, description });
                  handleAnnotationClassEditModalOpen();
                }}
                onTogglePredictionVisibility={() => handlePredictionVisibilityToggle(index)}
                onToggleAnnotationVisibility={() => handleClassVisibilityToggle(index)}
              />
            ))}
        </Stack>
      </Flex>

      {isOpenClassCreateModal && (
        <AnnotationClassSetupModal
          formMode={FormMode.CREATE}
          onCreateClassSuccess={async () => await handleCreateClassSuccess()}
          onClose={handleAnnotationClassCreateModalClose}
        />
      )}

      {isOpenClassEditModal && (
        <AnnotationClassSetupModal
          formMode={FormMode.EDIT}
          targetClass={targetClass}
          onEditClassSuccess={async () => await handleEditClassSuccess()}
          onClose={handleAnnotationClassEditModalClose}
        />
      )}
    </>
  );
};

export default Classes;
