import { FC, Fragment } from 'react';
import { Image as KonvaImage, Layer } from 'react-konva';
import { useSelector } from 'react-redux';

import configs from '../../../../../configs';
import { RootState } from '../../../../../store';
import { generateImageFromURL } from '../../../../../utils/image';

type PredictionLayerProps = {
  imageIndex: number;
};

export const PredictionLayer: FC<PredictionLayerProps> = ({ imageIndex }) => {
  const predictionsVisibility = useSelector(
    (state: RootState) => state.project.predictionsVisibility,
  );
  const epistemicVisibility = useSelector((state: RootState) => state.project.epistemicVisibility);
  const aleatoricVisibility = useSelector((state: RootState) => state.project.aleatoricVisibility);

  const prediction = useSelector((state: RootState) => state.project.predictionData);
  const annotationClasses = useSelector((state: RootState) => state.project.annotationClasses);

  if (!prediction || epistemicVisibility || aleatoricVisibility) {
    return;
  }

  return (
    <Layer>
      {annotationClasses.map((_, index) => (
        <Fragment key={index}>
          <KonvaImage
            key={index}
            x={0 - configs.IMAGE_SIZE * index}
            y={0}
            image={generateImageFromURL(prediction[imageIndex])}
            opacity={0.8}
            visible={predictionsVisibility[index]}
          />
        </Fragment>
      ))}
    </Layer>
  );
};
