import {
  Box,
  HStack,
  Hide,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { BiCaretDown } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { TOOL_RESIZER_HOT_KEY_MAP } from '../../../../../constants/hotkey';
import useHotKey from '../../../../../hooks/useHotKey';
import { AppDispatch, RootState } from '../../../../../store';
import { changeToolSize } from '../../projectSlice';

const Resizer: FC = () => {
  return (
    <>
      <Show above="xl">
        <ExtendedResizer />
      </Show>
      <Hide above="xl">
        <MinimizedResizer />
      </Hide>
    </>
  );
};

const ExtendedResizer: FC = () => {
  const toolSize = useSelector((state: RootState) => state.project.toolSize);
  const dispatch = useDispatch<AppDispatch>();

  const handleResizerChange = (value: number) => {
    dispatch(changeToolSize(value));
  };

  const { removeListener: removeHotkeyListener, addListener: addHotkeyListener } = useHotKey([
    {
      keystrokes: TOOL_RESIZER_HOT_KEY_MAP.Decrease,
      action: () => handleResizerChange(Math.max(toolSize - 5, 1)),
    },
    {
      keystrokes: TOOL_RESIZER_HOT_KEY_MAP.Increase,
      action: () => handleResizerChange(Math.min(toolSize + 5, 100)),
    },
  ]);

  useEffect(() => {
    addHotkeyListener();
    return () => {
      removeHotkeyListener();
    };
  }, [addHotkeyListener, removeHotkeyListener]);

  return (
    <Tooltip label={toolSize + 'pt'} hasArrow>
      <Box flex={1}>
        <Slider
          aria-label="resizer"
          colorScheme="purple"
          min={1}
          max={100}
          defaultValue={toolSize}
          value={toolSize}
          onChange={(value) => handleResizerChange(value)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb border="none" _focus={{ boxShadow: 'none' }} />
        </Slider>
      </Box>
    </Tooltip>
  );
};

const MinimizedResizer: FC = () => {
  const toolSize = useSelector((state: RootState) => state.project.toolSize);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack gap={1} cursor="default">
          <Box w={4} h={4} bg="text.text7" rounded="full" />
          <Text color="text.text7" w={14} textAlign="center">
            {toolSize}pt
          </Text>
          <Icon as={BiCaretDown} color="text.text7" />
        </HStack>
      </PopoverTrigger>
      <PopoverContent bg="none">
        <Box bg="background.bg3" p={1}>
          <ExtendedResizer />
        </Box>
      </PopoverContent>
    </Popover>
  );
};

Resizer.defaultProps = {
  minimizedBreakpoint: 'xl',
};
export default Resizer;
