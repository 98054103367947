import { AbsoluteCenter, Box, CircularProgress, Container, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/Layout';
import configs from '../../../configs';
import { Tool } from '../../../models/annotation';
import { ProjectStatus } from '../../../models/project';
import { AppDispatch, RootState } from '../../../store';
import ControlPanel from './components/ControlPanel';
import ProjectHeader from './components/Header';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import TileSegmentation from './components/TileSegmentation';
import {
  fetchAnnotations,
  fetchImages,
  fetchModelMetrics,
  fetchPrediction,
  fetchProject,
  initPubSubClient,
} from './projectAsyncThunks';
import {
  changeFetchingPageData,
  changePagination,
  changeTool,
  getPagingImageIndices,
  reset,
} from './projectSlice';
import AppIcon from '/app-icon.svg';

const Project = () => {
  const projectId = useParams().projectId || '';

  const status = useSelector((state: RootState) => state.project.status);
  const fetchingPageData = useSelector((state: RootState) => state.project.fetchingPageData);
  const totalImages = useSelector((state: RootState) => state.project.totalImages);
  const imageData = useSelector((state: RootState) => state.project.imageData);
  const pagingImageIndices = useSelector(getPagingImageIndices);
  const fetchProjectIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const currentImageDataRef = useRef(imageData);

  const dispatch = useDispatch<AppDispatch>();

  const fetchPageDataFunc = useCallback(async () => {
    const project = await dispatch(fetchProject(projectId)).unwrap();
    await Promise.all([dispatch(fetchImages(projectId)), dispatch(fetchAnnotations(projectId))]);
    if (project.status === ProjectStatus.COMPLETED) {
      await Promise.all([dispatch(fetchPrediction()), dispatch(fetchModelMetrics())]);
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(changeFetchingPageData(true));
    fetchPageDataFunc().then(() => {
      dispatch(changeFetchingPageData(false));
    });
    dispatch(initPubSubClient(projectId));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, fetchPageDataFunc, projectId]);

  useEffect(() => {
    if (status === ProjectStatus.UPLOADING && !fetchProjectIntervalRef.current) {
      fetchProjectIntervalRef.current = setInterval(async () => {
        if (
          !currentImageDataRef.current[configs.PAGE_SIZE - 1] ||
          currentImageDataRef.current[configs.PAGE_SIZE - 1].url === ''
        ) {
          await fetchPageDataFunc();
        }

        await dispatch(fetchProject(projectId));
      }, configs.FETCH_PAGE_DATA_INTERVAL);
    }

    if (status !== ProjectStatus.UPLOADING) {
      fetchProjectIntervalRef.current && clearInterval(fetchProjectIntervalRef.current);
      fetchProjectIntervalRef.current = null;
    }

    if ([ProjectStatus.COMPLETING, ProjectStatus.COMPLETED].includes(status)) {
      dispatch(changeTool(Tool.MOUSE));
    }

    return () => {
      fetchProjectIntervalRef.current && clearInterval(fetchProjectIntervalRef.current);
    };
  }, [dispatch, fetchPageDataFunc, projectId, status]);

  useEffect(() => {
    if (
      status === ProjectStatus.UPLOADING &&
      pagingImageIndices[pagingImageIndices.length - 1] < configs.PAGE_SIZE - 1 &&
      totalImages >= configs.PAGE_SIZE
    ) {
      dispatch(changePagination({ startIndex: 0, endIndex: configs.PAGE_SIZE - 1 }));
    }

    currentImageDataRef.current = imageData;
  }, [status, totalImages, pagingImageIndices, dispatch, fetchPageDataFunc, imageData]);

  return (
    <Layout
      header={{
        children: (
          <Flex width="100%" px={6} py={4}>
            <Flex mr={4}>
              <img src={AppIcon} alt="App Icon" />
            </Flex>
            <ProjectHeader />
          </Flex>
        ),
      }}
    >
      {status === ProjectStatus.UPLOADING && (
        <Flex width="full" minH="calc(100vh - 134px)" direction="row" p={2}>
          <AbsoluteCenter>
            <Flex direction="column" gap={4} alignItems="center" justifyContent="center">
              <CircularProgress isIndeterminate />
              <Box fontSize="sm" mt={4} textColor="whiteAlpha.500">
                {`Uploading ${localStorage.getItem(projectId)} images...`}
              </Box>
            </Flex>
          </AbsoluteCenter>
        </Flex>
      )}

      {status !== ProjectStatus.UPLOADING && (
        <>
          <ControlPanel />
          <Flex width="full" minH="calc(100vh - 134px)" direction="row" p={2}>
            <LeftPanel />
            <Container minW="max-content">
              {fetchingPageData ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                  }}
                  w={{ base: '820px', xl: '820px', '2xl': '1084px' }}
                >
                  <CircularProgress isIndeterminate />
                </Box>
              ) : (
                <TileSegmentation />
              )}
            </Container>
            <RightPanel />
          </Flex>
        </>
      )}
    </Layout>
  );
};

export default Project;
