import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import OutlineButton from '../../../../components/OutlineButton';
import { AppDispatch } from '../../../../store';
import { deleteProject } from '../projectsSlice';

type Props = {
  projectId: string;
  onClose: () => void;
};

const DeleteProjectModal: FC<Props> = ({ projectId, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const handelDeleteProject = async () => {
    setLoading(true);
    await dispatch(deleteProject(projectId)).unwrap();
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16} bg="background.bg3">
        <ModalHeader mt={4}>
          <Text color="text.text7">Delete project</Text>
        </ModalHeader>
        <ModalCloseButton color="text.text7" top="6" right="4" />
        <ModalBody mt={2}>
          <Text color="text.text7">
            This action will completely delete the project. Are you sure to continue?
          </Text>
        </ModalBody>

        <ModalFooter mt={4} mb={4}>
          <Flex width="100%" height={10} justifyContent="space-between">
            <OutlineButton width="48%" background="background.bg4" onClick={onClose}>
              Cancel
            </OutlineButton>
            <Button
              width="48%"
              variant="gradient"
              isDisabled={loading}
              onClick={handelDeleteProject}
            >
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProjectModal;
