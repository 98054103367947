import { useToast } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import AppError from '../app/AppError';
import configs from '../configs';
import { ErrorEvent, getErrorMessage } from '../utils/error';
import NotFound from './NotFound';

const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<ErrorEvent>();
  const toast = useToast();

  useEffect(() => {
    window.addEventListener(
      'unhandledrejection',
      function (event) {
        event.promise.catch((error) => {
          delete error.stack;

          toast({
            title: getErrorMessage(error),
            status: 'error',
            duration: configs.ERROR_TOAST_DURATION,
            isClosable: true,
            position: 'top',
          });
        });
      },
      false,
    );

    return () => {
      window.removeEventListener('unhandledrejection', () => {});
    };
  }, [toast]);

  useEffect(() => {
    document.addEventListener(ErrorEvent.NOT_FOUND_EVENT, () => {
      setError(ErrorEvent.NOT_FOUND_EVENT);
    });

    return () => {
      document.removeEventListener(ErrorEvent.NOT_FOUND_EVENT, () => {});
    };
  }, []);

  if (error === ErrorEvent.NOT_FOUND_EVENT) {
    return <NotFound />;
  }

  return (
    <ReactErrorBoundary fallbackRender={(props) => <AppError error={props.error} />}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
