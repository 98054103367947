export enum ServerAppErrorCode {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  MISSING_UPDATE_FILTERS_ERROR = 'MISSING_UPDATE_FILTERS_ERROR',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  PROJECT_UPDATE_ERROR = 'PROJECT_UPDATE_ERROR',
  EVENT_ERROR = 'EVENT_ERROR',
  PROJECT_COMPLETE_ERROR = 'PROJECT_COMPLETE_ERROR',
  TRAINING_STATISTICS_ALREADY_CREATED_ERROR = 'TRAINING_STATISTICS_ALREADY_CREATED_ERROR',
  AKS_NOT_AVAILABLE_ERROR = 'AKS_NOT_AVAILABLE_ERROR',
  JWT_ERROR = 'JWT_ERROR',
}

export class ServerAppError extends Error {
  public code: ServerAppErrorCode;

  constructor(message: string, code: ServerAppErrorCode) {
    super(message);
    this.code = code;
    this.name = code; // name is to show the title of Sentry event
  }
}
export enum ErrorEvent {
  NOT_FOUND_EVENT = 'NOT_FOUND_EVENT',
}

export const showNotFoundPage = (): void => {
  const event = new Event(ErrorEvent.NOT_FOUND_EVENT);
  document.dispatchEvent(event);
};

export const getErrorMessage = (error: unknown): string => {
  return (error as Error)?.message || 'Something went wrong';
};
