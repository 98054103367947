import { Flex, Radio, RadioGroup, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useLiveQuery } from 'dexie-react-hooks';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import { IdbSuperpixelsDAO } from '../../../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { AppDispatch, RootState } from '../../../../../store';
import { AnnotationMode, changeAnnotationMode, getProjectUpdatable } from '../../projectSlice';
import LoadSuperpixelsDataPrompt from './LoadSuperpixelsDataPrompt';
import AnnotationModeIcon from '/annotation-mode.svg';

const AnnotationModes: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const annotationMode = useSelector((state: RootState) => state.project.annotationMode);
  const projectId = useSelector((state: RootState) => state.project.id);
  const totalImages = useSelector((state: RootState) => state.project.totalImages);
  const projectUpdatable = useSelector(getProjectUpdatable);
  const loadedSuperpixelsDataImages = useLiveQuery(() => {
    return IdbSuperpixelsDAO.getInstance().imageIndicesCount(projectId);
  }, [projectId]);
  const loadedAllSuperpixelsData = useMemo(() => {
    return loadedSuperpixelsDataImages === totalImages;
  }, [loadedSuperpixelsDataImages, totalImages]);

  const {
    isOpen: isShowPromptToLoadSuperpixelsData,
    onOpen: showPromptToLoadSuperPixelsDataPrompt,
    onClose: hidePromptToLoadSuperPixelsDataPrompt,
  } = useDisclosure({ defaultIsOpen: false });

  const handleAnnotationModeChange = (value: AnnotationMode) => {
    if (value === AnnotationMode.SUPER_PIXEL) {
      if (!loadedAllSuperpixelsData) return showPromptToLoadSuperPixelsDataPrompt();
      else handleOnLoadedSuperpixelsData();
    }

    dispatch(changeAnnotationMode(value));
  };

  const handleOnLoadedSuperpixelsData = () => {
    dispatch(changeAnnotationMode(AnnotationMode.SUPER_PIXEL));
    hidePromptToLoadSuperPixelsDataPrompt();
  };

  return (
    <Flex
      bg="background.bg3"
      borderRadius="lg"
      flexDirection="column"
      p="12px"
      height="fit-content"
    >
      {isShowPromptToLoadSuperpixelsData && (
        <LoadSuperpixelsDataPrompt
          onCancel={hidePromptToLoadSuperPixelsDataPrompt}
          onLoadedSuperpixelsData={handleOnLoadedSuperpixelsData}
        />
      )}
      <Flex gap={2} flexWrap="wrap">
        <img src={AnnotationModeIcon} alt="Annotation Mode Icon" />
        <Text color="text.text7" fontSize="md" fontWeight="bold">
          Annotation Modes
        </Text>
      </Flex>
      <RadioGroup
        onChange={handleAnnotationModeChange}
        value={annotationMode}
        isDisabled={!projectUpdatable}
      >
        <Stack mt={2}>
          <Tooltip label={TOOLTIP_LABEL.PIXEL_BASED} shouldWrapChildren={true}>
            <Radio value={AnnotationMode.SCRIBBLE} colorScheme="purple">
              <Text color="text.text7" fontSize="sm">
                Pixel based
              </Text>
            </Radio>
          </Tooltip>
          <Tooltip label={TOOLTIP_LABEL.SUPER_PIXEL} shouldWrapChildren={true}>
            <Radio value={AnnotationMode.SUPER_PIXEL} colorScheme="purple">
              <Text color="text.text7" fontSize="sm">
                Super pixel
              </Text>
            </Radio>
          </Tooltip>
          <Tooltip label={TOOLTIP_LABEL.ACCEPT_PREDICTION} shouldWrapChildren={true}>
            <Radio value={AnnotationMode.ACCEPT_PREDICTION} colorScheme="purple" isDisabled={true}>
              <Text color="text.text7" fontSize="sm">
                Accept prediction
              </Text>
            </Radio>
          </Tooltip>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default AnnotationModes;
