import { Flex } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

type Props = React.PropsWithChildren & {
  header: {
    children: ReactNode;
    optionalStyle?: React.CSSProperties;
  };
  optionalStyle?: React.CSSProperties;
};

const Layout: FC<Props> = ({ header, children, optionalStyle }) => {
  const { children: headerChildren, optionalStyle: headerOptionalStyle } = header;
  return (
    <Flex direction="column">
      <Flex position="relative" sx={{ ...headerOptionalStyle }}>
        {headerChildren}
      </Flex>
      <Flex
        direction="column"
        minHeight="calc(100vh - 50px)"
        sx={{
          ...optionalStyle,
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;
