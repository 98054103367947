import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';

import { BsFillImageFill } from 'react-icons/bs';
import { LuLayoutGrid } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import HotKey from '../../../../../components/Hotkey';
import OutlineButton from '../../../../../components/OutlineButton';
import configs from '../../../../../configs';
import {
  macOS__TOOL_HOT_KEY_MAP,
  macOS__TOOL_HOT_KEY_TEXT,
  windowsOS__TOOL_HOT_KEY_MAP,
  windowsOS__TOOL_HOT_KEY_TEXT,
} from '../../../../../constants/hotkey';
import { TOOL_NAME } from '../../../../../constants/tool';
import useHotKey from '../../../../../hooks/useHotKey';
import { Tool } from '../../../../../models/annotation';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import {
  TrainingStatus,
  changeSelectedImage,
  changeTool,
  changeZoomLevel,
  getProjectUpdatable,
  redo,
  undo,
} from '../../projectSlice';
import UploadImageModal from '../UploadImageModal';
import DrawingToolKit from './DrawingToolKit';
import ImageFilterSelection from './ImageFilterSelection';
import LiveUpdateSwitcher from './LiveUpdateSwitcher';
import Pagination from './Pagination';
import Resizer from './Resizer';
import SuggestButton from './SuggestButton';
import ToolButton from './ToolButton';
import UpdateButton from './UpdateButton';

const ControlPanel: FC = () => {
  const isMac = navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0;
  const HOT_KEY_MAP = isMac ? macOS__TOOL_HOT_KEY_MAP : windowsOS__TOOL_HOT_KEY_MAP;
  const HOT_KEY_TEXT = isMac ? macOS__TOOL_HOT_KEY_TEXT : windowsOS__TOOL_HOT_KEY_TEXT;

  const {
    isOpen: isOpenUploadImgModal,
    onOpen: onOpenUploadImgModal,
    onClose: onCloseUploadImgModal,
  } = useDisclosure();
  const {
    isOpen: isOpenAlertModal,
    onOpen: onOpenAlertModal,
    onClose: onCloseAlertModal,
  } = useDisclosure();

  const projectId = useSelector((state: RootState) => state.project.id);
  const totalImages = useSelector((state: RootState) => state.project.totalImages);
  const imageIndices = useSelector((state: RootState) => state.project.imageIndices);
  const selectedTool = useSelector((state: RootState) => state.project.selectedTool);
  const undoStack = useSelector((state: RootState) => state.project.undoStack);
  const redoStack = useSelector((state: RootState) => state.project.redoStack);
  const imageData = useSelector((state: RootState) => state.project.imageData);
  const selectedImage = useSelector((state: RootState) => state.project.selectedImage);
  const fetchingPageData = useSelector((state: RootState) => state.project.fetchingPageData);
  const projectUpdatable = useSelector(getProjectUpdatable);

  const uploadingImages = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const initializing = useSelector(
    (state: RootState) => state.project.trainingStatus === TrainingStatus.INITIALIZING,
  );

  const dispatch = useDispatch<AppDispatch>();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { removeListener: removeToolHotKeyListener, addListener: addToolHotKeyListener } =
    useHotKey(
      Object.values(Tool).map((tool) => {
        const toolHotKey = HOT_KEY_MAP[tool];
        return {
          keystrokes: toolHotKey ?? [],
          action: () => {
            switch (tool) {
              case Tool.UNDO:
                dispatch(undo());
                break;
              case Tool.REDO:
                dispatch(redo());
                break;
              case Tool.ZOOM_IN:
                if (selectedImage !== -1)
                  dispatch(changeZoomLevel(imageData[selectedImage].zoomLevel + configs.ZOOM_STEP));
                break;
              case Tool.ZOOM_OUT:
                if (selectedImage !== -1)
                  dispatch(changeZoomLevel(imageData[selectedImage].zoomLevel - configs.ZOOM_STEP));
                break;
              case Tool.BRUSH:
              case Tool.ERASER:
                dispatch(changeSelectedImage(-1));
                dispatch(changeTool(tool));
                break;
              case Tool.MOUSE:
                dispatch(changeTool(tool));
                break;
              default:
                alert('This tool action is not supported yet');
                break;
            }
          },
        };
      }),
    );

  useEffect(() => {
    addToolHotKeyListener();
    return () => {
      removeToolHotKeyListener();
    };
  }, [addToolHotKeyListener, removeToolHotKeyListener]);

  const handleImagesSubmit = async () => {
    onCloseUploadImgModal();
    window.location.reload();
  };

  const handleOpenModal = () => {
    if (totalImages > 0) {
      onOpenAlertModal();
    } else {
      onOpenUploadImgModal();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const tool = e.currentTarget.value as Tool;

    const toolCanUpdateProject = [Tool.UNDO, Tool.REDO].includes(tool);
    if (!projectUpdatable && toolCanUpdateProject) return;

    switch (tool) {
      case Tool.UNDO:
        dispatch(undo());
        break;

      case Tool.REDO:
        dispatch(redo());
        break;
      case Tool.ZOOM_IN:
        dispatch(changeZoomLevel(imageData[selectedImage].zoomLevel + configs.ZOOM_STEP));
        break;

      case Tool.ZOOM_OUT:
        dispatch(changeZoomLevel(imageData[selectedImage].zoomLevel - configs.ZOOM_STEP));
        break;
      default:
        alert('This tool action is not supported yet');
        break;
    }
  };

  return (
    <>
      <Flex w="100%" p={2} justifyContent="space-between" gap={4}>
        <HStack
          flex={1}
          justifyContent="space-between"
          borderRadius="3xl"
          padding={2}
          bg="background.bg3"
        >
          <Button
            size="sm"
            leftIcon={<BsFillImageFill />}
            variant="gradient"
            onClick={handleOpenModal}
            isDisabled={fetchingPageData || !projectUpdatable || uploadingImages || initializing}
          >
            Add images
          </Button>

          {totalImages > 0 && (
            <>
              <Box height="100%" borderRightWidth="1px" borderRightColor="ControlPanel.divider" />
              <DrawingToolKit />

              <Box height="100%" borderRightWidth="1px" borderRightColor="background.bg5" />
              <Box>
                <Flex gap="8px">
                  {[Tool.UNDO, Tool.REDO, Tool.ZOOM_IN, Tool.ZOOM_OUT].map((tool: Tool, index) => (
                    <ToolButton
                      key={index}
                      tool={tool}
                      isActive={selectedTool === tool}
                      onClick={handleClick}
                      isDisabled={
                        (tool === Tool.UNDO && undoStack.length === 0) ||
                        (tool === Tool.REDO && redoStack.length === 0) ||
                        ([Tool.UNDO, Tool.REDO].includes(tool) && !projectUpdatable)
                      }
                      tooltipLabel={
                        <Center gap={2}>
                          {TOOL_NAME[tool]}
                          <HotKey keystrokes={HOT_KEY_TEXT[tool]} />
                        </Center>
                      }
                    />
                  ))}
                </Flex>
              </Box>

              <Box height="100%" borderRightWidth="1px" borderRightColor="background.bg5" />
              <Resizer />

              <Box height="100%" borderRightWidth="1px" borderRightColor="background.bg5" />
              <ImageFilterSelection />

              {imageIndices.length > 0 && <Pagination />}
            </>
          )}

          {
            // temporary hide layout button
          }
          <Button display="none" size="sm" leftIcon={<LuLayoutGrid />} variant="default">
            Layout
          </Button>
        </HStack>

        {totalImages > 0 && (
          <Flex borderRadius="3xl" alignItems="center" px={4} py={2} bg="background.bg3" gap={4}>
            <LiveUpdateSwitcher />
            <UpdateButton />
            <SuggestButton />
          </Flex>
        )}
      </Flex>
      <AlertDialog
        isOpen={isOpenAlertModal}
        onClose={onCloseAlertModal}
        leastDestructiveRef={cancelRef}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            background="background.bg2"
            color="text.text7"
            justifyContent="center"
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="main.orange4">
              Caution
            </AlertDialogHeader>
            <AlertDialogBody>
              Adding more images will reset the training process. Are you sure to continue?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex height={8} gap="5%">
                <OutlineButton
                  width="130px"
                  size="sm"
                  background="background.bg4"
                  onClick={onCloseAlertModal}
                >
                  Cancel
                </OutlineButton>
                <Button
                  variant="gradient"
                  size="sm"
                  width="130px"
                  onClick={() => {
                    onOpenUploadImgModal();
                    onCloseAlertModal();
                  }}
                  isDisabled={fetchingPageData}
                >
                  Confirm
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <UploadImageModal
        projectId={projectId}
        isOpen={isOpenUploadImgModal}
        onClose={onCloseUploadImgModal}
        onSubmit={handleImagesSubmit}
      />
    </>
  );
};

export default ControlPanel;
