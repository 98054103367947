import { KeyCode } from '../types/hotkey';
import { Tool } from './../models/annotation';

/******* ANNOTATION CLASS *******/
export const ANNOTATION_CLASS_HOT_KEY_MAP: Record<string, KeyCode[]> = {
  '1': [KeyCode.Digit1],
  '2': [KeyCode.Digit2],
  '3': [KeyCode.Digit3],
  '4': [KeyCode.Digit4],
  '5': [KeyCode.Digit5],
  '6': [KeyCode.Digit6],
  '7': [KeyCode.Digit7],
  '8': [KeyCode.Digit8],
  '9': [KeyCode.Digit9],
  '0': [KeyCode.Digit0],
};
export const ANNOTATION_CLASS_HOT_KEY = [...Object.values(ANNOTATION_CLASS_HOT_KEY_MAP)];

/******* ANNOTATION TOOL*******/
export const windowsOS__TOOL_HOT_KEY_MAP: Partial<Record<Tool, KeyCode[]>> = {
  [Tool.MOUSE]: [KeyCode.KeyV],
  [Tool.BRUSH]: [KeyCode.KeyB],
  [Tool.ERASER]: [KeyCode.KeyE],
  [Tool.PEN]: [],
  [Tool.ZOOM_IN]: [KeyCode.Equal],
  [Tool.ZOOM_OUT]: [KeyCode.Minus],
  [Tool.UNDO]: [KeyCode.ControlLeft, KeyCode.KeyZ],
  [Tool.REDO]: [KeyCode.ControlLeft, KeyCode.ShiftLeft, KeyCode.KeyZ],
};

export const macOS__TOOL_HOT_KEY_MAP: Partial<Record<Tool, KeyCode[]>> = {
  [Tool.MOUSE]: [KeyCode.KeyV],
  [Tool.BRUSH]: [KeyCode.KeyB],
  [Tool.ERASER]: [KeyCode.KeyE],
  [Tool.PEN]: undefined,
  [Tool.ZOOM_IN]: [KeyCode.Equal],
  [Tool.ZOOM_OUT]: [KeyCode.Minus],
  [Tool.UNDO]: [KeyCode.MetaLeft, KeyCode.KeyZ],
  [Tool.REDO]: [KeyCode.MetaLeft, KeyCode.ShiftLeft, KeyCode.KeyZ],
};

export const windowsOS__TOOL_HOT_KEYS = [...Object.values(windowsOS__TOOL_HOT_KEY_MAP)];

export const macOS__TOOL_HOT_KEYS = [...Object.values(macOS__TOOL_HOT_KEY_MAP)];

export const windowsOS__TOOL_HOT_KEY_TEXT: Record<Tool, string[]> = {
  [Tool.MOUSE]: ['V'],
  [Tool.BRUSH]: ['B'],
  [Tool.ERASER]: ['E'],
  [Tool.PEN]: [],
  [Tool.ZOOM_IN]: ['+'],
  [Tool.ZOOM_OUT]: ['-'],
  [Tool.UNDO]: ['^', 'Z'],
  [Tool.REDO]: ['^', '⇧', 'Z'],
};

export const macOS__TOOL_HOT_KEY_TEXT: Record<Tool, string[]> = {
  [Tool.MOUSE]: ['V'],
  [Tool.BRUSH]: ['B'],
  [Tool.ERASER]: ['E'],
  [Tool.PEN]: [],
  [Tool.ZOOM_IN]: ['+'],
  [Tool.ZOOM_OUT]: ['-'],
  [Tool.UNDO]: ['⌘', 'Z'],
  [Tool.REDO]: ['⌘', '⇧', 'Z'],
};

/******* TOOL SIZE *******/
export const TOOL_RESIZER_HOT_KEY_MAP: Record<string, KeyCode[]> = {
  Decrease: [KeyCode.BracketLeft],
  Increase: [KeyCode.BracketRight],
};
