import { Grid, GridItem } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import { Tool } from '../../../../models/annotation';
import { AppDispatch, RootState } from '../../../../store';
import { changeSelectedImage, getPagingImageIndices } from '../projectSlice';
import ImageCanvas from './ImageCanvas';

const TileSegmentation: React.FC = () => {
  const selectedTool = useSelector((state: RootState) => state.project.selectedTool);
  const selectedImage = useSelector((state: RootState) => state.project.selectedImage);
  const pagingImageIndices = useSelector(getPagingImageIndices);
  const imageData = useSelector((state: RootState) => state.project.imageData);

  const dispatch = useDispatch<AppDispatch>();

  return (
    <Grid
      templateColumns={{
        base: 'repeat(3,260px)',
        xl: 'repeat(3,260px)',
        '2xl': 'repeat(4,260px)',
      }}
      gap={1}
      justifyContent="center"
    >
      {pagingImageIndices.map((pagingImageIndex) => (
        <GridItem
          key={pagingImageIndex}
          height="280px"
          border="2px"
          borderStyle="solid"
          position="relative"
          {...(selectedImage === pagingImageIndex
            ? { borderColor: 'gradient.default.from' }
            : { borderColor: 'border.grey' })}
          {...(selectedTool === Tool.MOUSE && {
            _hover: { borderColor: 'gradient.default.from' },
            onClick: () => dispatch(changeSelectedImage(pagingImageIndex)),
          })}
        >
          {imageData[pagingImageIndex] && <ImageCanvas imageIndex={pagingImageIndex} />}
        </GridItem>
      ))}
    </Grid>
  );
};

export default TileSegmentation;
