import axios, { AxiosError } from 'axios';
import configs from '../configs';
import { ServerAppError, ServerAppErrorCode } from './error';

const axiosClient = axios.create({
  baseURL: configs.BACK_END_URL,
  timeout: Number(configs.API_TIME_OUT),
});

axiosClient.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  async (config) => {
    return config;
  },
  (error: AxiosError) => {
    const responseError = error.response?.data as ServerAppError;

    const message = responseError?.message ?? 'Something went wrong!';
    const newError = new ServerAppError(
      message,
      responseError?.code ?? ServerAppErrorCode.INTERNAL_SERVER_ERROR,
    );

    return Promise.reject(newError);
  },
);

export { axiosClient as api };
