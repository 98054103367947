import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { FormControl, FormLabel, Switch, Tooltip } from '@chakra-ui/react';
import { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import { fetchPrediction, recordEventTrace, update } from '../../projectAsyncThunks';
import {
  TrainingStatus,
  changeLiveUpdateOn,
  changeMetricUncertaintyData,
  changePredictionVisibility,
  getFetchingInferenceResult,
  getProjectUpdatable,
} from '../../projectSlice';

const LiveUpdateSwitcher: FC = () => {
  const { showErrorToast } = useAppToast();
  const annotationClasses = useSelector((state: RootState) => state.project.annotationClasses);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const fetchingInferenceResult = useSelector<RootState>(getFetchingInferenceResult) as ReturnType<
    typeof getFetchingInferenceResult
  >;
  const projectUpdatable = useSelector<RootState>(getProjectUpdatable) as ReturnType<
    typeof getProjectUpdatable
  >;
  const uploading = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;

  const projectInactivated = trainingStatus !== TrainingStatus.RUNNING;

  const dispatch = useDispatch<AppDispatch>();

  const liveUpdateOnRef = useRef<boolean>(true);

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        metricUncertainties: string;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        dispatch(changeLiveUpdateOn(false));
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.LIVE_UPDATE) {
        return;
      }

      if (message.status === 'Success') {
        if (liveUpdateOnRef.current) {
          const timeRecorded = Date.now();
          await dispatch(
            update({
              eventType: EventType.LIVE_UPDATE,
              timeRecorded,
            }),
          ).unwrap();
        }
        await dispatch(fetchPrediction());

        if (message.payload) {
          dispatch(changeMetricUncertaintyData(JSON.parse(message.payload.metricUncertainties)));
          dispatch(
            recordEventTrace({
              eventId: message.payload?.eventId,
              eventType: EventType.LIVE_UPDATE,
              eventReceivedOnClientAt: Date.now(),
            }),
          ).unwrap();
        }
      } else {
        console.error(message.payload?.message);
        showErrorToast();
        dispatch(changeLiveUpdateOn(false));
        pubSubClient.unsubscribe(eventHandler);
      }
    },
    [pubSubClient],
  );

  const handleLiveUpdateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const startTime = Date.now();
      dispatch(changeLiveUpdateOn(e.target.checked));
      liveUpdateOnRef.current = e.target.checked;

      if (e.target.checked) {
        pubSubClient.subscribe(eventHandler);

        await dispatch(
          update({
            eventType: EventType.LIVE_UPDATE,
            timeRecorded: startTime,
          }),
        ).unwrap();
        annotationClasses.forEach((_, index) =>
          dispatch(changePredictionVisibility({ index, value: true })),
        );
      } else {
        pubSubClient.unsubscribe(eventHandler);
      }
    } catch (error) {
      dispatch(changeLiveUpdateOn(false));
      Promise.reject(error);
    }
  };

  return (
    <Tooltip label={TOOLTIP_LABEL.LIVE_UPDATE} hasArrow>
      <FormControl
        display="flex"
        borderRadius="xl"
        gap={2}
        px={4}
        bg="background.bg2"
        height="full"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        width="fit-content"
        isDisabled={
          (!projectUpdatable || projectInactivated || fetchingInferenceResult || uploading) &&
          !liveUpdateOn
        }
      >
        <FormLabel
          htmlFor="liveUpdateSwitch"
          color={'main.purple5'}
          _disabled={{ color: 'text.text4' }}
          fontWeight={600}
          fontSize="sm"
          m={0}
        >
          Live Predict
        </FormLabel>
        <Switch id="liveUpdateSwitch" isChecked={liveUpdateOn} onChange={handleLiveUpdateChange} />
      </FormControl>
    </Tooltip>
  );
};

export default LiveUpdateSwitcher;
