import { Button, Icon, Tooltip } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { TOOL_BUTTON_ICON } from '../../../../../constants/tool';
import { Tool } from '../../../../../models/annotation';

type Props = {
  tool: Tool;
  isActive: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean;
  tooltipLabel?: ReactNode;
};

const ToolButton: FC<Props> = ({ onClick, isActive, tool, isDisabled, tooltipLabel }) => {
  return (
    <ToolButtonWrapper tooltipLabel={tooltipLabel}>
      <Button
        width="50px"
        height="32px"
        variant="outline"
        borderRadius="32px"
        isActive={isActive}
        borderColor="background.bg5"
        value={tool}
        _active={{
          backgroundColor: 'background.bg4',
          borderColor: 'gradient.default.from',
          '> *': { color: 'gradient.default.from' },
        }}
        _hover={{
          backgroundColor: 'background.bg4',
          borderColor: 'gradient.default.from',
          '> *': { color: 'gradient.default.from' },
        }}
        onClick={onClick}
        isDisabled={isDisabled}
      >
        <Icon as={TOOL_BUTTON_ICON[tool]} color="text.text4" />
      </Button>
    </ToolButtonWrapper>
  );
};

const ToolButtonWrapper: FC<{
  tooltipLabel?: ReactNode;
  children: ReactNode;
}> = ({ tooltipLabel, children }) => {
  return tooltipLabel ? (
    <Tooltip label={tooltipLabel} hasArrow>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

ToolButton.defaultProps = {
  isDisabled: false,
};

export default ToolButton;
