import Dexie, { Table } from 'dexie';
import { IDb_Superpixels } from './superpixels/superpixels-indexedDb.model';

export class SATIndexDb extends Dexie {
  superpixels!: Table<IDb_Superpixels, [string, number]>;

  constructor() {
    super('siouxAnnotationTool');
    this.version(1).stores({
      superpixels: '[projectId+imageIndex]',
    });
  }
}

export const db = new SATIndexDb();
