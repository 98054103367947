import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import AnnotationModes from './AnnotationModes';
import Classes from './Classes';

const LeftPanel: FC = () => {
  const totalImages = useSelector((state: RootState) => state.project.totalImages);

  if (totalImages <= 0) {
    return <></>;
  }

  return (
    <Flex flex={1} gap={3} minW="fit-content" flexDirection="column" justifyContent="space-between">
      <AnnotationModes />
      <Classes />
    </Flex>
  );
};

export default LeftPanel;
