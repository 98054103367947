import {
  Center,
  Flex,
  Hide,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Show,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import configs from '../configs';
import { BreakPoint } from '../types/breakpoint';

type Props = {
  startIndex: number;
  endIndex: number;
  total: number;
  onChange: (startIndex: number, endIndex: number) => void;
  minimizedBreakpoint?: BreakPoint;
  isDisabled?: boolean;
};

const Pagination: FC<Props> = (props) => {
  const { minimizedBreakpoint } = props;
  return (
    <>
      <Show above={minimizedBreakpoint}>
        <ExtendedPagination {...props} />
      </Show>
      <Hide above={minimizedBreakpoint}>
        <MinimizedPagination {...props} />
      </Hide>
    </>
  );
};

const ExtendedPagination: FC<Props> = ({
  startIndex,
  endIndex,
  total,
  onChange,
  isDisabled = false,
}) => {
  const handleDoubleLeftClick = () => {
    onChange(0, configs.PAGE_SIZE - 1);
  };

  const handleDoubleRightClick = () => {
    if (total % configs.PAGE_SIZE === 0) {
      onChange(total - configs.PAGE_SIZE, total - 1);
      return;
    }

    const maxPage = Math.floor(total / configs.PAGE_SIZE);
    const start = maxPage * configs.PAGE_SIZE;
    onChange(start, total - 1);
  };

  const handleLeftClick = () => {
    const start = startIndex - configs.PAGE_SIZE;
    onChange(start > 0 ? start : 0, startIndex - 1);
  };

  const handleRightClick = () => {
    const end = endIndex + configs.PAGE_SIZE;
    onChange(endIndex + 1, end < total - 1 ? end : total - 1);
  };

  return (
    <Flex
      alignItems="center"
      background="background.bg2"
      borderRadius="3xl"
      px={2}
      height="32px"
      width="fit-content"
    >
      <Tooltip label="First page" hasArrow>
        <IconButton
          icon={<FaAngleDoubleLeft />}
          aria-label="Double Left"
          onClick={handleDoubleLeftClick}
          variant="icon"
          isDisabled={startIndex === 0 || isDisabled}
        />
      </Tooltip>

      <Tooltip label="Previous page" hasArrow>
        <IconButton
          icon={<FaAngleLeft />}
          aria-label="Left"
          onClick={handleLeftClick}
          variant="icon"
          isDisabled={startIndex === 0 || isDisabled}
        />
      </Tooltip>

      <Center mx={1}>
        <Text fontSize="sm" color="text.text7" fontWeight="bold">
          {startIndex + 1} - {endIndex + 1} /
        </Text>
        <Text fontSize="sm" color="text.text7" ml={1}>
          {total}
        </Text>
      </Center>

      <Tooltip label="Next page" hasArrow>
        <IconButton
          icon={<FaAngleRight />}
          aria-label="Right"
          onClick={handleRightClick}
          variant="icon"
          isDisabled={endIndex >= total - 1 || isDisabled}
        />
      </Tooltip>

      <Tooltip label="Last page" hasArrow>
        <IconButton
          icon={<FaAngleDoubleRight />}
          aria-label="Double Right"
          onClick={handleDoubleRightClick}
          variant="icon"
          isDisabled={endIndex >= total - 1 || isDisabled}
        />
      </Tooltip>
    </Flex>
  );
};

const MinimizedPagination: FC<Props> = (props) => {
  const { startIndex, endIndex, total } = props;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex
          alignItems="center"
          background="background.bg2"
          borderRadius="3xl"
          px={2}
          height="32px"
        >
          <Text fontSize="sm" color="text.text7" fontWeight="bold">
            {startIndex + 1} - {endIndex + 1} /
          </Text>
          <Text fontSize="sm" color="text.text7" ml={1}>
            {total}
          </Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent background="none" border="none" w="fit-content">
        <ExtendedPagination {...props} />
      </PopoverContent>
    </Popover>
  );
};

Pagination.defaultProps = {
  minimizedBreakpoint: '2xl',
};
export default Pagination;
