import { RGB } from '../types/color';

export const generateRGBString = (color: RGB) => `rgb(${color.r}, ${color.g}, ${color.b})`;

export const generateRGBObject = (color: string): RGB => {
  const rgbaValues = color.replace('rgb(', '').replace(')', '').split(', ');

  if (rgbaValues.length !== 3) {
    throw new Error('Color is not in RGB format');
  }

  return {
    r: Number(rgbaValues[0]),
    g: Number(rgbaValues[1]),
    b: Number(rgbaValues[2]),
  };
};

export const generateRGBAImageData = (color: string): number[] => {
  const rgba = generateRGBObject(color);
  return [rgba.r, rgba.g, rgba.b, 255];
};
