import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaEllipsisV } from 'react-icons/fa';
import { PiStackBold } from 'react-icons/pi';
import ProjectStatusIndicator from '../../../../components/ProjectStatus';
import configs from '../../../../configs';
import { ProjectStatus } from '../../../../models/project';
import { ProjectListItem } from '../projectsSlice';

type Props = {
  project: ProjectListItem;
  onClick: () => void;
  onDelete: () => void;
};

const ProjectCard: FC<Props> = ({ project, onClick, onDelete }) => {
  const updatedAt = new Date(project.updatedAt);
  const [showOverLay, setShowOverlay] = useState<boolean>(false);

  const deletable = project.status !== ProjectStatus.UPLOADING;

  const handleMouseEnter = () => {
    setShowOverlay(true);
  };

  const handleMouseLeave = () => {
    setShowOverlay(false);
  };

  return (
    <Box
      position="relative"
      p={1}
      bg="background.bg4"
      borderRadius="12px"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {showOverLay && (
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          borderRadius="12px"
          cursor="pointer"
          zIndex={10}
        >
          <AbsoluteCenter axis="both">
            <Button variant="gradient" boxShadow="0px 0px 10px grey">
              <Text fontWeight="bold" fontSize="md">
                Open
              </Text>
            </Button>
          </AbsoluteCenter>
        </Box>
      )}

      <Menu>
        <MenuButton
          position="absolute"
          top="8px"
          right="8px"
          as={IconButton}
          aria-label="Options"
          icon={
            <Icon position="absolute" top="8px" right="8px" as={FaEllipsisV} color="text.text4" />
          }
          variant="outline"
          bg="background.bg4"
          border="none"
          borderRadius="12px"
          w="32px"
          minW="32px"
          h="32px"
          zIndex={10}
          isDisabled={!deletable}
          _hover={{ backgroundColor: 'background.bg6' }}
          _expanded={{ backgroundColor: 'background.bg6' }}
          _disabled={{
            backgroundColor: 'background.bg4',
            cursor: 'not-allowed',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <MenuList
          position="absolute"
          top="-4px"
          right="-40px"
          minW="30px"
          p={0}
          bg="background.bg3"
          border="none"
          borderRadius="8px"
          _hover={{
            backgroundColor: 'background.bg5',
            '> *': { backgroundColor: 'background.bg5' },
          }}
          zIndex={10}
          onMouseEnter={() => setShowOverlay(false)}
        >
          <MenuItem
            w="fit-content"
            borderRadius="8px"
            bg="background.bg3"
            onClick={(e) => {
              onDelete();
              e.stopPropagation();
            }}
          >
            <Text variant="gradient" fontWeight="medium" fontSize="sm">
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      {project.thumbnail ? (
        <Image
          src={project.thumbnail}
          alt="Project Overview"
          width={configs.THUMBNAIL_SIZE}
          height={configs.THUMBNAIL_SIZE}
          style={{ borderRadius: '12px' }}
          fit="cover"
          {...(showOverLay && {
            filter: 'brightness(0.6)',
            transitionDuration: '0.3s',
            transform: 'scale(1.02)',
          })}
        />
      ) : (
        <Flex
          width={configs.THUMBNAIL_SIZE}
          height={configs.THUMBNAIL_SIZE}
          borderRadius="12px"
          justifyContent="center"
          alignItems="center"
          bg="background.bg1"
        >
          <Box>
            <Text color="text.text3" fontSize="lg">
              No images
            </Text>
          </Box>
        </Flex>
      )}

      <Box height="140px" mt="15px" pl="16px" overflowX="hidden">
        <Text
          color="text.text7"
          fontSize="xl"
          fontWeight={600}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {project.name}
        </Text>
        <Flex height="24px" mt="2px">
          <Icon as={PiStackBold} color="text.text6" height="100%" mr={1} />
          <Text
            color="text.text6"
            lineHeight="24px"
            fontWeight={500}
            flexWrap="nowrap"
            fontSize="xs"
          >
            {project.totalImages} items
          </Text>
        </Flex>
        <Flex height="24px" mt="2px">
          <Icon as={AiOutlineClockCircle} color="text.text6" height="100%" mr={1} />
          <Text
            color="text.text6"
            lineHeight="24px"
            fontWeight={500}
            flexWrap="nowrap"
            fontSize="xs"
          >
            Updated on{' '}
            {`${updatedAt.getDate()}-${updatedAt.getMonth() + 1}-${updatedAt.getFullYear()}`}
          </Text>
        </Flex>
        <ProjectStatusIndicator status={project.status} mt="10px" />
      </Box>
    </Box>
  );
};

export default ProjectCard;
