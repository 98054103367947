import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { Button, Tooltip } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODEL_FILE_EXTENSION } from '../../../../../constants/fileExtension';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { downloadFile, generateFileUrl, verifyFileExist } from '../../../../../utils/file';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import { exportModel, recordEventTrace } from '../../projectAsyncThunks';
import { TrainingStatus, getFetchingInferenceResult } from '../../projectSlice';

const ExportModelButton: FC = () => {
  const { showErrorToast } = useAppToast();
  const projectId = useSelector((state: RootState) => state.project.id);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const projectStatus = useSelector((state: RootState) => state.project.status);
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const fetchingInferenceResult = useSelector(getFetchingInferenceResult);

  const disableExportButton =
    [ProjectStatus.UPLOADING, ProjectStatus.COMPLETING].includes(projectStatus) ||
    fetchingInferenceResult ||
    liveUpdateOn;

  const [loading, setLoading] = useState(false);
  const [canExport, setCanExport] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        message: string;
      }>;

      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        setLoading(false);
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.EXPORT_MODEL) {
        return;
      }

      if (message.status === 'Success') {
        const fileUrl = await generateFileUrl(projectId, MODEL_FILE_EXTENSION);
        downloadFile(fileUrl, `${projectId}.${MODEL_FILE_EXTENSION}`);

        if (message.payload?.eventId) {
          dispatch(
            recordEventTrace({
              eventId: message.payload?.eventId,
              eventType: EventType.EXPORT_MODEL,
              eventReceivedOnClientAt: Date.now(),
            }),
          );
        }
      } else {
        console.error(message.payload?.message);
        showErrorToast();
      }

      setLoading(false);
      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  useEffect(() => {
    const checkAvailableToExport = async () => {
      try {
        if (trainingStatus === TrainingStatus.RUNNING) {
          setCanExport(true);
        } else {
          setCanExport(await verifyFileExist(projectId, MODEL_FILE_EXTENSION));
        }
      } catch (error) {
        Promise.reject(error);
      }
    };

    checkAvailableToExport();
  }, [projectId, trainingStatus]);

  const handleClick = async () => {
    setLoading(true);

    if (trainingStatus === TrainingStatus.RUNNING) {
      try {
        const startTime = Date.now();
        pubSubClient.subscribe(eventHandler);

        await dispatch(exportModel(startTime)).unwrap();
      } catch (error) {
        console.error(error);
        setLoading(false);
        pubSubClient.unsubscribe(eventHandler);
        Promise.reject(error);
      }
    } else {
      try {
        const fileUrl = await generateFileUrl(projectId, MODEL_FILE_EXTENSION);

        downloadFile(fileUrl, `${projectId}.${MODEL_FILE_EXTENSION}`);
        setLoading(false);
        return;
      } catch (error) {
        console.error(error);
        setLoading(false);
        Promise.reject(error);
      }
    }
  };

  return (
    <Tooltip label={TOOLTIP_LABEL.EXPORT_MODEL} hasArrow>
      <Button
        size="sm"
        variant="outline"
        isDisabled={loading || disableExportButton || !canExport}
        isLoading={loading}
        onClick={handleClick}
      >
        Export Model
      </Button>
    </Tooltip>
  );
};

export default ExportModelButton;
