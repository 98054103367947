import { db } from '../db';
import { CreateIndexedDbSuperpixelsParams } from './superpixels-indexedDb.dto';

export class IdbSuperpixelsDAO {
  // Singleton implementation
  private static singleInstance: IdbSuperpixelsDAO;

  private constructor() {}

  public static getInstance() {
    if (!IdbSuperpixelsDAO.singleInstance) {
      IdbSuperpixelsDAO.singleInstance = new IdbSuperpixelsDAO();
    }

    return IdbSuperpixelsDAO.singleInstance;
  }

  // CRUD operations
  public async find(filters?: { projectId?: string; imageIndices?: number[] }) {
    const { projectId, imageIndices } = filters ?? {};
    if (projectId && imageIndices) {
      return db.superpixels
        .where(['projectId+imageIndex'])
        .anyOf(imageIndices.map((imageIndex) => [projectId, imageIndex.toString()]))
        .toArray();
    }

    if (projectId && !imageIndices) {
      return db.superpixels.where('projectId').equals(projectId).toArray();
    }

    if (!projectId && imageIndices) {
      return db.superpixels.where('imageIndex').anyOf(imageIndices).toArray();
    }

    return db.superpixels.toArray();
  }

  public async findOne(filter: { projectId: string; imageIndex: number }) {
    const { projectId, imageIndex } = filter;
    return db.superpixels.where(['projectId+imageIndex']).equals([projectId, imageIndex]).first();
  }

  public async getAllImageIndices(projectId: string) {
    const keySets = await db.superpixels.where('projectId').equals(projectId).primaryKeys();

    return keySets.map(([, imageIndex]) => imageIndex);
  }

  public async imageIndicesCount(projectId: string) {
    return db.superpixels.where('projectId').equals(projectId).count();
  }

  public async create(item: CreateIndexedDbSuperpixelsParams) {
    return db.table('superpixels').add(item);
  }

  public async update(index: [string, number], changes: CreateIndexedDbSuperpixelsParams) {
    return db.superpixels.update(index, changes);
  }

  public async upsert(item: CreateIndexedDbSuperpixelsParams) {
    const { projectId, imageIndex } = item;

    return db.superpixels
      .where(['projectId+imageIndex'])
      .equals([projectId, imageIndex])
      .first()
      .then((result) => {
        if (result) {
          db.superpixels.update([result.projectId, result.imageIndex], item);
        } else {
          db.superpixels.add(item);
        }
      });
  }

  public async delete(projectId: string): Promise<void>;
  public async delete(projectId: string, imageIndex: number): Promise<void>;
  public async delete(projectId: string, imageIndex?: number) {
    if (imageIndex) {
      return db.superpixels.delete([projectId, imageIndex]);
    }

    return db.superpixels.where('projectId').equals(projectId).delete();
  }
}
