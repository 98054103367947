import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  //
});

const gradientVariant = defineStyle({
  bgGradient: 'linear(to-br, gradient.default.from, gradient.default.to)',
  bgClip: 'text',
});

const variants = {
  gradient: gradientVariant,
};

export const textTheme = defineStyleConfig({ baseStyle, variants });
