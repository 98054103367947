import { OnGroupDataMessageArgs } from '@azure/web-pubsub-client';
import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoButton from '../../../../../components/InfoButton';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import useAppToast from '../../../../../hooks/useAppToast';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { PubSubMessage } from '../../../../../types/pubsubMessage';
import { EventType, PubSubClient } from '../../../../../utils/pubsubClient';
import { calculateModelMetric, recordEventTrace } from '../../projectAsyncThunks';
import {
  TrainingStatus,
  changeFetchingModelMetric,
  changeModelMetrics,
  getFetchingInferenceResult,
  getProjectUpdatable,
} from '../../projectSlice';
import PercentIcon from '/percent.svg';

const ModelMetrics: FC = () => {
  const { showErrorToast } = useAppToast();
  const fetchingModelMetric = useSelector((state: RootState) => state.project.fetchingModelMetric);
  const avgDiceScore = useSelector((state: RootState) => state.project.avgDiceScore);
  const avgPrecision = useSelector((state: RootState) => state.project.avgPrecision);
  const avgRecall = useSelector((state: RootState) => state.project.avgRecall);
  const errorDiceScore = useSelector((state: RootState) => state.project.errorDiceScore);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const uploading = useSelector(
    (state: RootState) => state.project.status === ProjectStatus.UPLOADING,
  );
  const pubSubClient = useSelector<RootState>(
    (state: RootState) => state.project.pubSubClient,
  ) as PubSubClient;

  const fetchingInferenceResult = useSelector(getFetchingInferenceResult);
  const projectUpdatable = useSelector(getProjectUpdatable);

  const actionDisabled =
    trainingStatus !== TrainingStatus.RUNNING ||
    fetchingInferenceResult ||
    liveUpdateOn ||
    uploading ||
    !projectUpdatable;

  const dispatch = useDispatch<AppDispatch>();

  const eventHandler = useCallback(
    async (e: OnGroupDataMessageArgs): Promise<void> => {
      const message = e.message.data as PubSubMessage<{
        eventId: string;
        avgDiceScore: number;
        errorDiceScore: number;
        avgPrecision: number;
        avgRecall: number;
        message: string;
      }>;
      if (message.type === EventType.ERROR) {
        showErrorToast(message.payload?.message);
        dispatch(changeFetchingModelMetric(false));
        pubSubClient.unsubscribe(eventHandler);
        return;
      }

      if (message.type !== EventType.CALCULATE) {
        return;
      }

      if (message.status === 'Success' && message.payload) {
        const { avgDiceScore, errorDiceScore, avgPrecision, avgRecall } = message.payload;
        dispatch(changeModelMetrics({ avgDiceScore, errorDiceScore, avgPrecision, avgRecall }));
        dispatch(
          recordEventTrace({
            eventId: message.payload.eventId,
            eventType: EventType.CALCULATE,
            eventReceivedOnClientAt: Date.now(),
          }),
        ).unwrap();
      } else {
        console.error(message.payload?.message);
        showErrorToast(message.payload?.message);
      }

      dispatch(changeFetchingModelMetric(false));
      pubSubClient.unsubscribe(eventHandler);
    },
    [pubSubClient],
  );

  const handleCalculateMetric = async () => {
    try {
      const startTime = Date.now();
      dispatch(changeFetchingModelMetric(true));

      pubSubClient.subscribe(eventHandler);

      await dispatch(calculateModelMetric(startTime)).unwrap();
    } catch (error) {
      dispatch(changeFetchingModelMetric(false));
      pubSubClient.unsubscribe(eventHandler);
      Promise.reject(error);
    }
  };
  return (
    <Flex direction="column" bg="background.bg3" borderRadius="lg" px={4} py={3}>
      <Flex gap="0.5rem" alignItems="center" justifyContent="space-around" w="100%">
        <img src={PercentIcon} alt="Percent Icon" />
        <Tooltip label={TOOLTIP_LABEL.MODEL_METRIC} hasArrow>
          <Text color="text.text7" fontWeight="bold" cursor="help">
            Model metrics
          </Text>
        </Tooltip>
        <Tooltip label={TOOLTIP_LABEL.CALCULATE} hasArrow>
          <Button
            ml="auto"
            size="sm"
            variant="default"
            bg="background.bg5"
            onClick={handleCalculateMetric}
            isDisabled={actionDisabled}
            isLoading={fetchingModelMetric}
          >
            Calculate
          </Button>
        </Tooltip>
      </Flex>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        h={10}
        borderBottomWidth="1px"
        borderBottomColor="text.text2"
      >
        <Text fontSize="sm" color="text.text7">
          Expected average dice score
        </Text>
        <Flex gap={2} alignItems="center">
          <Text fontSize="sm" color="indicator.success4">
            {avgDiceScore?.toFixed(4)} +/- {errorDiceScore?.toFixed(4)}
          </Text>
          <InfoButton label="The average expected dice score over the entire dataset. The error is the standard deviation of the dice score of the model's prediction on the dataset." />
        </Flex>
      </Flex>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        h={10}
        borderBottomWidth="1px"
        borderBottomColor="text.text2"
      >
        <Text fontSize="sm" color="text.text7">
          Expected average precision
        </Text>
        <Flex gap={2} alignItems="center">
          <Text fontSize="sm" color="indicator.success4">
            {avgPrecision?.toFixed(4)}
          </Text>
          <InfoButton label="The average expected precision over the entire dataset. The precision shows how correctly the model's predictions are." />
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" h={10}>
        <Text fontSize="sm" color="text.text7">
          Expected average recall
        </Text>
        <Flex gap={2} alignItems="center">
          <Text fontSize="sm" color="indicator.success4">
            {avgRecall?.toFixed(4)}
          </Text>
          <InfoButton label="The average expected recall over the entire dataset. The recall shows how many items of the dataset that the model can predict." />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ModelMetrics;
