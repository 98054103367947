import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

const NotFound: FC = () => {
  return (
    <Box textAlign="center" mt="10">
      <Text fontSize="28px" fontWeight="bold" color="white">
        404 Not Found
      </Text>
      <Text fontSize="lg" mt="4" color="white">
        Oops! The page you're looking for doesn't exist.
      </Text>
    </Box>
  );
};

export default NotFound;
