import { OnGroupDataMessageArgs, WebPubSubClient } from '@azure/web-pubsub-client';

export enum EventType {
  UPDATE = 'UPDATE',
  LIVE_UPDATE = 'LIVE_UPDATE',
  LOAD_UNCERTAINTY = 'LOAD_UNCERTAINTY',
  SUGGEST = 'SUGGEST',
  CALCULATE = 'CALCULATE',
  EXPORT_MODEL = 'EXPORT_MODEL',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

type EventHandler = (args: OnGroupDataMessageArgs) => Promise<void>;

export class PubSubClient {
  private webPubSubClient: WebPubSubClient | null = null;
  private eventHandlers: EventHandler[] = [];

  constructor(clientAccessUrl: string) {
    this.webPubSubClient = new WebPubSubClient({
      getClientAccessUrl: clientAccessUrl,
    });
  }

  async start(): Promise<void> {
    await this.webPubSubClient?.start();
  }

  stop(): void {
    this.webPubSubClient?.stop();
  }

  joinGroup(groupName: string): void {
    this.webPubSubClient?.joinGroup(groupName).catch((error) => {
      console.error('Error joining pubsub group: ', error);
    });
  }

  subscribe(onGroupDataMessage: EventHandler): void {
    this.webPubSubClient?.on('group-message', onGroupDataMessage);
    this.eventHandlers.push(onGroupDataMessage);
  }

  unsubscribe(onGroupDataMessage: EventHandler): void {
    this.webPubSubClient?.off('group-message', onGroupDataMessage);
    const index = this.eventHandlers.indexOf(onGroupDataMessage);
    if (index !== -1) {
      this.eventHandlers.splice(index, 1);
    }
  }

  destruct(): void {
    this.eventHandlers.forEach((eventHandler) => {
      this.webPubSubClient?.off('group-message', eventHandler);
    });
    this.eventHandlers = [];
    this.webPubSubClient?.stop();
    this.webPubSubClient = null;
  }
}
