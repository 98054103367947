export const MAIN_COLORS_TOKEN = {
  orange1: '#FFA05B1A',
  orange2: '#FFA05B66',
  orange3: '#FFA05B99',
  orange4: '#FFA05B',
  orange5: '#FEBA88',
  orange6: '#FFD0AD',
  orange7: '#FFE5D1',
  //
  purple1: '#D88FFB1A',
  purple2: '#D88FFB66',
  purple3: '#D88FFB99',
  purple4: '#D88FFB',
  purple5: '#E0A9FB',
  purple6: '#E5B9FA',
  purple7: '#EDCBFE',
};

export const TEXT_COLORS_TOKEN = {
  text1: '#161616',
  text2: '#383F41',
  text3: '#646E71',
  text4: '#7C878A',
  text5: '#A4B2B7',
  text6: '#D3D9DB',
  text7: '#F7F8F9',
};

export const BACKGROUND_COLORS_TOKEN = {
  bg1: '#161616',
  bg2: '#282828',
  bg3: '#363636',
  bg4: '#474747',
  bg5: '#565656',
  bg6: '#676767',
  bg7: '#1616164d',
};

export const INDICATOR_COLORS_TOKEN = {
  error1: '#F03F331A',
  error2: '#F03F3366',
  error3: '#F03F3399',
  error4: '#F03F33',
  //
  warning1: '#FFBC581A',
  warning2: '#FFBC5866',
  warning3: '#FFBC5899',
  warning4: '#FFBC58',
  //
  success1: '#4EFFAA1A',
  success2: '#4EFFAA66',
  success3: '#4EFFAA99',
  success4: '#4EFFAA',
};
