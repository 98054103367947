import { Auth0Provider } from '@auth0/auth0-react';
import {
  BrowserTracing as SentryBrowserTracing,
  init as SentryInit,
  Replay as SentryReplay,
  reactRouterV6Instrumentation,
  withProfiler as withSentryProfiler,
} from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './app/App.tsx';
import ErrorBoundary from './components/ErrorBoundary.tsx';
import configs from './configs.ts';
import { store } from './store.ts';
import { sentryEnvironmentFilter, sentryServerAppErrorFilter } from './utils/sentry.ts';

SentryInit({
  dsn: configs.SENTRY.DSN,
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_RELEASE,
  beforeSend: async (event, hint) => {
    // TODO: Change to function pipe - value = pipe(filterFn1, filterFn2, ...)
    const { event: filteredEvent } = sentryEnvironmentFilter(
      sentryServerAppErrorFilter({ event, hint }),
    );

    return filteredEvent;
  },
  integrations: [
    new SentryBrowserTracing({
      tracePropagationTargets: [configs.SENTRY.TRACES_PROPAGATION_BE],
      routingInstrumentation: reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new SentryReplay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const AppWithProvider = () => (
  <Auth0Provider
    domain={configs.AUTH0.DOMAIN}
    clientId={configs.AUTH0.CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </Auth0Provider>
);

const AppWithSentryProfiler = withSentryProfiler(AppWithProvider);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppWithSentryProfiler />,
  </React.StrictMode>,
);
