import { FC } from 'react';
import { Image as KonvaImage, Layer } from 'react-konva';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../store';
import { generateImageFromURL } from '../../../../../utils/image';

type EpistemicUncertaintyLayerProps = {
  imageIndex: number;
};

export const EpistemicUncertaintyLayer: FC<EpistemicUncertaintyLayerProps> = ({ imageIndex }) => {
  const epistemicVisibility = useSelector((state: RootState) => state.project.epistemicVisibility);
  const epistemicUncertainty = useSelector(
    (state: RootState) => state.project.aleatoricUncertaintyData,
  );

  if (!epistemicVisibility || !epistemicUncertainty) {
    return;
  }

  return (
    <Layer>
      <KonvaImage
        x={0}
        image={generateImageFromURL(epistemicUncertainty[imageIndex])}
        draggable={false}
        opacity={0.8}
      />
    </Layer>
  );
};
