import { Flex, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiInfo } from 'react-icons/fi';

import configs from '../../../../../configs';
import { TOOLTIP_LABEL } from '../../../../../constants/tooltip';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchTrainingProgress, fetchTrainingStatus } from '../../projectAsyncThunks';
import { TrainingStatus } from '../../projectSlice';
import CheckUncertainty from './CheckUncertainty';
import ExportModelButton from './ExportModelButton';
import ImportModelButton from './ImportModelButton';
import ModelMetrics from './ModelMetrics';

// enum TrainingIndicator {
//   ANNOTATE_MORE = 'Annotate more',
//   TRAIN_MORE = 'Train more',
//   ENOUGH_DATA = 'Enough data',
// }

// const getTrainingIndicator = (trainingProgress: number) => {
//   switch (true) {
//     case trainingProgress < configs.TRAINING_PROGRESS_LOWER_THRESHOLD:
//       return { text: TrainingIndicator.TRAIN_MORE, color: 'main.orange4' };
//     case trainingProgress > configs.TRAINING_PROGRESS_UPPER_THRESHOLD:
//       return { text: TrainingIndicator.ANNOTATE_MORE, color: 'main.orange4' };
//     case trainingProgress >= configs.TRAINING_PROGRESS_LOWER_THRESHOLD &&
//       trainingProgress <= configs.TRAINING_PROGRESS_UPPER_THRESHOLD:
//       return { text: TrainingIndicator.ENOUGH_DATA, color: 'indicator.success4' };
//     default:
//       return { text: '', color: '' };
//   }
// };

const getTrainingStatus = (trainingStatus: TrainingStatus) => {
  switch (trainingStatus) {
    case TrainingStatus.INITIALIZING:
      return { text: 'Initializing', color: 'main.purple4' };
    case TrainingStatus.RUNNING:
      return { text: 'Running', color: 'indicator.success4' };
    case TrainingStatus.STOP:
    default:
      return { text: 'Stop', color: 'indicator.error4' };
  }
};

const RightPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectId = useSelector((state: RootState) => state.project.id);
  const totalImages = useSelector((state: RootState) => state.project.totalImages);
  // const trainingProgress = useSelector((state: RootState) => state.project.trainingProgress);
  const status = useSelector((state: RootState) => state.project.status);
  const trainingStatus = useSelector((state: RootState) => state.project.trainingStatus);
  const trainable = useSelector((state: RootState) => state.project.trainable);

  // const trainingIndicator = getTrainingIndicator(trainingProgress);
  const trainingStatusInfo = getTrainingStatus(trainingStatus);

  useEffect(() => {
    if (status === ProjectStatus.DRAFT) {
      return;
    }

    const fetchTrainingInfo = async () => {
      try {
        await dispatch(fetchTrainingProgress());
        await dispatch(fetchTrainingStatus());
      } catch (error) {
        if (!projectId) return;
        Promise.reject(error);
      }
    };

    const intervalId = setInterval(fetchTrainingInfo, configs.FETCH_TRAINING_STATUS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, projectId, status]);

  return totalImages > 0 ? (
    <Flex flex={1.5} minW="fit-content" gap={3} minH="calc(100% - 134px)" direction="column">
      {!trainable && status !== ProjectStatus.COMPLETED && (
        <HStack gap={2}>
          <Icon as={FiInfo} color="indicator.warning4" fontSize={14} />
          <Text fontSize="sm" color="text.text7">
            Please annotate at least 2 classes to start the training
          </Text>
        </HStack>
      )}

      <Flex justifyContent="space-between">
        <Flex flex={1} direction="column" bg="background.bg3" borderRadius="lg" px={4} py={3}>
          <Text color="text.text7" fontWeight="bold">
            Training Status
          </Text>
          <Flex height="full" alignItems="center">
            <Text color={trainingStatusInfo.color} fontWeight={600}>
              {trainingStatusInfo.text}
            </Text>
          </Flex>
        </Flex>

        {/* Temporary hide for Sioux China to demonstration */}
        {/* <Spacer flex={0.1} />
        <Flex flex={2} flexDirection="column" bg="background.bg3" borderRadius="lg" px={4} py={3}>
          <HStack justifyContent="space-between">
            <Text color="text.text7" fontWeight="bold">
              Model Status
            </Text>
            <InfoButton label={TOOLTIP_LABEL.MODEL_STATUS} />
          </HStack>
          <Flex alignItems="center" mt={4}>
            <CircularProgress
              color={trainingIndicator.color}
              mr={4}
              thickness="6px"
              trackColor="text.text3"
              size="50px"
              min={0}
              max={1}
              value={trainingProgress}
            >
              <CircularProgressLabel color={trainingIndicator.color} fontWeight={800} fontSize="sm">
                {trainingProgress?.toFixed(2)}
              </CircularProgressLabel>
            </CircularProgress>

            <Text color={trainingIndicator.color} fontWeight={600}>
              {trainingIndicator.text}
            </Text>
          </Flex>
        </Flex> */}
      </Flex>

      <CheckUncertainty />

      <ModelMetrics />

      <Flex direction="column" bg="background.bg3" borderRadius="lg" px={4} py={3} gap={2}>
        <Tooltip label={TOOLTIP_LABEL.MODEL_METRIC} hasArrow>
          <Text color="text.text7" fontWeight="bold" alignSelf="start" cursor="help">
            Model
          </Text>
        </Tooltip>
        <HStack>
          <ImportModelButton />
          <ExportModelButton />
        </HStack>
      </Flex>
    </Flex>
  ) : (
    <></>
  );
};

export default RightPanel;
