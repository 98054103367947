import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react';
import { useLiveQuery } from 'dexie-react-hooks';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IdbSuperpixelsDAO } from '../../../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { RootState } from '../../../../../store';
import { MAIN_COLORS_TOKEN, TEXT_COLORS_TOKEN } from '../../../../../theme/designToken';

type LoadSuperpixelsDataProgressProps = {
  onClose: () => void;
};
export const LoadSuperpixelsDataProgress: FC<LoadSuperpixelsDataProgressProps> = ({ onClose }) => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const totalNumberOfImages = useSelector((state: RootState) => state.project.totalImages);
  const loadedSuperpixelsDataImageCount = useLiveQuery(() => {
    return IdbSuperpixelsDAO.getInstance().imageIndicesCount(projectId);
  }, []);

  const progress = useMemo(() => {
    if (!loadedSuperpixelsDataImageCount) return 0;

    return (loadedSuperpixelsDataImageCount / totalNumberOfImages) * 100;
  }, [loadedSuperpixelsDataImageCount, totalNumberOfImages]);

  useEffect(() => {
    if (progress === 100) {
      onClose();
    }
  }, [onClose, progress]);

  const radius = 42;
  const circumference = 2 * Math.PI * radius;

  return (
    <Flex
      pos="fixed"
      top={0}
      left={0}
      bg="blackAlpha.700"
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      zIndex={99999}
    >
      <Box w={300} bg="transparent" pos="relative">
        <svg viewBox="0 0 100 100" style={{ transform: 'rotate(90deg) scaleX(-1)' }}>
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke-width="2"
            fill="transparent"
            stroke={TEXT_COLORS_TOKEN.text4}
          ></circle>
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            stroke-width="2"
            stroke-dashoffset={circumference * ((100 - progress) / 100)}
            stroke-dasharray={circumference}
            stroke="url(#grad)"
            style={{ transition: 'stroke-dashoffset 0.5s' }}
          ></circle>
          <defs>
            <linearGradient id="grad" x1="0" x2="1" y1="1" y2="0">
              <stop offset="0%" stop-color={MAIN_COLORS_TOKEN.orange4}></stop>
              {/* <stop offset="50%" stop-color={MAIN_COLORS_TOKEN.orange5}></stop> */}
              <stop offset="100%" stop-color={MAIN_COLORS_TOKEN.purple4}></stop>
            </linearGradient>
          </defs>
        </svg>
        <Flex
          direction="column"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text color="white" fontSize={34} fontWeight={600} textAlign="center">
            {Math.floor(progress)}
            <Text as="span" fontSize={20}>
              %
            </Text>
          </Text>
          <Text color="white" fontSize={12} textAlign="center">
            Loading necessary data for superpixels
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
export default CircularProgress;
