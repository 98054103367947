const configs = {
  BACK_END_URL: import.meta.env.VITE_BACK_END_URL || '',
  API_TIME_OUT: import.meta.env.VITE_API_TIME_OUT || 5000,
  AZURE_BLOB_SAS_URL: import.meta.env.VITE_AZURE_BLOB_SAS_URL || '',
  AZURE_PUBLIC_CONTAINER_NAME: import.meta.env.VITE_AZURE_PUBLIC_CONTAINER_NAME || '',
  AZURE_ORIGINAL_CONTAINER_NAME: import.meta.env.VITE_AZURE_ORIGINAL_CONTAINER_NAME || '',
  AZURE_IMPORT_MODEL_CONTAINER_NAME: import.meta.env.VITE_AZURE_IMPORT_MODEL_CONTAINER_NAME || '',
  AZURE_EXPORT_MODEL_CONTAINER_NAME: import.meta.env.VITE_AZURE_EXPORT_MODEL_CONTAINER_NAME || '',
  AZURE_PREDICTION_CONTAINER_NAME: import.meta.env.VITE_AZURE_PREDICTION_CONTAINER_NAME || '',
  AZURE_UNCERTAINTY_CONTAINER_NAME: import.meta.env.VITE_AZURE_UNCERTAINTY_CONTAINER_NAME || '',
  AZURE_SUPERPIXELS_BOUNDARY_CONTAINER_NAME:
    import.meta.env.VITE_AZURE_SUPERPIXELS_BOUNDARY_CONTAINER_NAME || '',
  IMAGE_SIZE: 256,
  THUMBNAIL_SIZE: 284,
  IMAGE_DIMENSIONS_LIMIT: Number(import.meta.env.VITE_IMAGE_DIMENSIONS_LIMIT) || 7000 * 7000,
  IMAGE_MEMORY_SIZE_LIMIT: Number(import.meta.env.VITE_IMAGE_MEMORY_SIZE_LIMIT) || 50_000_000, // 50MB
  IMAGES_MEMORY_SIZE_LIMIT: Number(import.meta.env.VITE_IMAGES_MEMORY_SIZE_LIMIT) || 100_000_000, // 100MB
  IMAGE_DATASET_SIZE_LIMIT: Number(import.meta.env.VITE_IMAGE_DATASET_SIZE_LIMIT) || 800,
  PAGE_SIZE: 12,
  ZOOM_STEP: 0.25,
  AUTH0: {
    DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN || '',
    CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
  },
  FETCH_PAGE_DATA_INTERVAL: 3000,
  FETCH_TRAINING_STATUS_INTERVAL: 2000,
  LIVE_UPDATE_TRAINING_STATISTIC_DELAY: 2000,
  TRAINING_PROGRESS_UPPER_THRESHOLD: 0.6,
  TRAINING_PROGRESS_LOWER_THRESHOLD: 0.4,
  ANNOTATION_HISTORY_STACK_SIZE: 20,
  ERROR_TOAST_DURATION: 5000,
  ANNOTATION_CLASSES_LIMIT: import.meta.env.ANNOTATION_CLASSES_LIMIT || 10,
  ANNOTATION_DEBOUNCE_TIMEOUT: 1000,
  SENTRY: {
    DSN: import.meta.env.VITE_SENTRY_DSN || '',
    TRACES_PROPAGATION_BE: import.meta.env.VITE_SENTRY_TRACES_PROPAGATION_BE || '',
  },
};

export default configs;
