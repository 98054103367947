import { Parser } from 'pickleparser';
import configs from '../configs';
import { blobService } from './blob';

export const verifyFileExist = async (
  projectId: string,
  fileExtension: string,
): Promise<boolean> => {
  try {
    const containerClient = blobService.getContainerClient(
      configs.AZURE_EXPORT_MODEL_CONTAINER_NAME,
    );

    const blobClient = containerClient.getBlobClient(`${projectId}.${fileExtension}`);

    return await blobClient.exists();
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const generateFileUrl = async (
  projectId: string,
  fileExtension: string,
): Promise<string> => {
  try {
    const containerClient = blobService.getContainerClient(
      configs.AZURE_EXPORT_MODEL_CONTAINER_NAME,
    );

    const blobClient = containerClient.getBlobClient(`${projectId}.${fileExtension}`);

    if (!(await verifyFileExist(projectId, fileExtension))) {
      throw new Error('File not found');
    }

    const response = await blobClient.download();

    const blob = await response.blobBody;

    if (!blob) {
      throw new Error('Blob is empty');
    }

    const fileUrl = URL.createObjectURL(blob);

    return fileUrl;
  } catch (error) {
    throw new Error(`${error}`);
  }
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', '');
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
};

export const loadModel = async (file: File): Promise<{ [key: string]: unknown }> => {
  let data: { [key: string]: unknown } = {};
  const reader = new FileReader();

  reader.onload = (event) => {
    if (!event.target) return;
    const buffer = new Uint8Array(event.target.result as ArrayBuffer);
    const parser = new Parser();
    const jsonObject = parser.parse<JSON>(buffer);

    data = {
      ...jsonObject,
    };
  };

  reader.readAsArrayBuffer(file);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(data);
    };

    reader.onerror = () => {
      reject('Load model error');
    };
  });
};
