import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ProjectStatus } from '../models/project';

const colorOf: Record<ProjectStatus, string> = {
  [ProjectStatus.DRAFT]: 'text.text5',
  [ProjectStatus.UPLOADING]: 'main.purple4',
  [ProjectStatus.IN_PROGRESS]: 'indicator.warning4',
  [ProjectStatus.COMPLETING]: 'indicator.warning4',
  [ProjectStatus.COMPLETED]: 'indicator.success4',
};

const borderColorOf: Record<ProjectStatus, string> = {
  [ProjectStatus.DRAFT]: 'text.text4',
  [ProjectStatus.UPLOADING]: 'main.purple4',
  [ProjectStatus.IN_PROGRESS]: 'indicator.warning2',
  [ProjectStatus.COMPLETING]: 'indicator.warning2',
  [ProjectStatus.COMPLETED]: 'indicator.success2',
};

const bgColorOf: Record<ProjectStatus, string> = {
  [ProjectStatus.DRAFT]: 'background.bg2',
  [ProjectStatus.UPLOADING]: 'main.purple1',
  [ProjectStatus.IN_PROGRESS]: 'indicator.warning1',
  [ProjectStatus.COMPLETING]: 'indicator.warning1',
  [ProjectStatus.COMPLETED]: 'indicator.success1',
};

const textOf: Record<ProjectStatus, string> = {
  [ProjectStatus.DRAFT]: 'Draft',
  [ProjectStatus.UPLOADING]: 'Uploading',
  [ProjectStatus.IN_PROGRESS]: 'In-progress',
  [ProjectStatus.COMPLETING]: 'Completing',
  [ProjectStatus.COMPLETED]: 'Completed',
};

type Props = FlexProps & {
  status: ProjectStatus;
};

const ProjectStatusIndicator: FC<Props> = ({ status, ...props }) => {
  return (
    <Flex
      position="relative"
      minWidth="80px"
      width="fit-content"
      height="28px"
      borderRadius="24px"
      borderWidth="1px"
      borderColor={borderColorOf[status]}
      padding="5px 4px"
      cursor="pointer"
      justifyContent="center"
      bg={bgColorOf[status]}
      {...props}
    >
      <Text zIndex={2} fontSize="x-small" fontWeight={600} color={colorOf[status]}>
        {textOf[status]}
      </Text>
    </Flex>
  );
};

export default ProjectStatusIndicator;
