import configs from '../configs';

export const TOOLTIP_LABEL = {
  SHOW_ALL_ANNOTATION: 'Click to show all annotation',
  HIDE_ALL_ANNOTATION: 'Click to hide all annotation',
  SHOW_ALL_PREDICTION: 'Click to show all prediction',
  HIDE_ALL_PREDICTION: 'Click to hide all prediction',
  SHOW_ANNOTATION: 'Click to show annotation',
  HIDE_ANNOTATION: 'Click to hide annotation',
  SHOW_PREDICTION: 'Click to show prediction',
  HIDE_PREDICTION: 'Click to hide prediction',
  ANNOTATION_CLASS_LIMIT: `You can only create up to ${configs.ANNOTATION_CLASSES_LIMIT} classes`,
  CANNOT_CREATE_CLASS_SINCE_PROJECT_IS_COMPLETED:
    'Cannot create more classes because your project is completed',
  ADD_CLASS: 'Add new class',

  // Refer to: https://sioux.atlassian.net/browse/SAT-106
  PIXEL_BASED: 'Annotate by assigning pixels to classes.',
  SUPER_PIXEL: 'Annotate regions based on pixel’s similarity.',
  ACCEPT_PREDICTION: 'Turn predictions into annotations',
  MODEL_STATUS:
    'Indicate if training alone is enough to improve the model or more annotations are needed.',
  CHECK_UNCERTAINTY: 'Display uncertainty score and overlay per image',
  EPISTEMIC_UNCERTAINTY: 'Uncertainty due to lack of data',
  ALEATORIC_UNCERTAINTY: 'Uncertainty due to potential user inconsistencies',
  SCORE_PER_IMAGE: 'Uncertainty score per image',
  MODEL_METRIC: 'Estimated overall performance',
  SET_THRESHOLDS:
    'Threshold is the minimum accepted precision score. Greater score is marked as green. Lower score is marked as red.',
  CALCULATE: 'Recalculate performance given most recent updated model..',
  EXPECTED_AVERAGE_DICE_SCORE:
    "The average expected dice score over the entire dataset. The error is the standard deviation of the dice score of the model's prediction on the dataset.",
  EXPECTED_AVERAGE_PRECISION:
    "The average expected precision over the entire dataset. The precision shows how correctly the model's predictions are",
  EXPECTED_AVERAGE_RECALL:
    'The average expected recall over the entire dataset. The recall shows how many items of the dataset that the model can predict.',
  SUGGEST:
    'Prioritize images that are most helpful to the model training when annotated. Recommended ones will be marked by a red dot.',
  MODEL:
    "Algorithm that can learn to segment images for several classes given user's annotation input.",
  IMPORT_MODEL: 'Load a trained model',
  EXPORT_MODEL: 'Save the current model',
  LIVE_UPDATE: 'Automatically get the latest prediction',
  UPDATE: 'Get the latest prediction',
  GET_UNCERTAINTY: 'Get uncertainty data',
};
