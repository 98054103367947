import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchAnnotationWithFilter, fetchImages, fetchPrediction } from '../../projectAsyncThunks';
import {
  changeFetchingPageData,
  changeFilteringAnnotatedImages,
  resetImageData,
} from '../../projectSlice';

enum ImageFilterOption {
  ALL_IMAGES = 'All Images',
  ANNOTATED_IMAGES = 'Annotated Images',
  UNANNOTATED_IMAGES = 'Unannotated Images',
}

const ImageFilterSelection: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [imageFilter, setImageFilter] = useState<ImageFilterOption>(ImageFilterOption.ALL_IMAGES);

  const projectId = useSelector((state: RootState) => state.project.id);
  const status = useSelector((state: RootState) => state.project.status);

  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => (isOpen ? onClose() : onOpen());

  const handleSelect = async (value: ImageFilterOption) => {
    setImageFilter(value);
    onClose();
    dispatch(changeFetchingPageData(true));
    dispatch(resetImageData());
    try {
      switch (value) {
        case ImageFilterOption.ANNOTATED_IMAGES:
          await dispatch(fetchAnnotationWithFilter(true)).unwrap();
          await dispatch(fetchImages(projectId)).unwrap();
          dispatch(changeFilteringAnnotatedImages(true));
          break;
        case ImageFilterOption.UNANNOTATED_IMAGES:
          await dispatch(fetchAnnotationWithFilter(false)).unwrap();
          await dispatch(fetchImages(projectId)).unwrap();
          dispatch(changeFilteringAnnotatedImages(false));
          break;
        default:
          await dispatch(fetchAnnotationWithFilter()).unwrap();
          await dispatch(fetchImages(projectId)).unwrap();
          dispatch(changeFilteringAnnotatedImages(false));
          break;
      }

      if (status === ProjectStatus.COMPLETED) {
        dispatch(fetchPrediction());
      }
    } catch (error) {
      Promise.reject(error);
    } finally {
      dispatch(changeFetchingPageData(false));
    }
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          width="148px"
          height="32px"
          borderWidth={1}
          borderRadius="24px"
          borderColor="background.bg5"
          variant="default"
          isDisabled={status === ProjectStatus.UPLOADING}
          rightIcon={
            <Icon
              as={BiChevronDown}
              color="text.text6"
              fontWeight={800}
              width="18px"
              height="18px"
            />
          }
          onClick={handleClick}
        >
          <Text
            color="text.text6"
            fontWeight={400}
            fontSize="12px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {imageFilter}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="none" width="fit-content">
        <VStack bg="background.bg2" rounded="md" p={2}>
          {[
            ImageFilterOption.ALL_IMAGES,
            ImageFilterOption.ANNOTATED_IMAGES,
            ImageFilterOption.UNANNOTATED_IMAGES,
          ].map((imageFilter: ImageFilterOption, index) => (
            <Flex
              key={index}
              width="100%"
              px={2}
              py={1}
              borderBottomWidth={1}
              borderBottomColor="background.bg4"
              cursor="pointer"
              onClick={async () => await handleSelect(imageFilter)}
            >
              <Text
                noOfLines={1}
                fontSize="14px"
                color="text.text6"
                _hover={{ color: 'main.purple4' }}
              >
                {imageFilter}
              </Text>
            </Flex>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export default ImageFilterSelection;
