import { Center, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

export type HotKeyProps = {
  keystrokes: string[];
};

const HotKey: FC<HotKeyProps> = ({ keystrokes }) => {
  if (!keystrokes?.length) return null;
  return (
    <Flex gap={2}>
      {keystrokes.map((key, index) => (
        <Center
          key={index}
          border="1px"
          w={4}
          h={4}
          rounded="sm"
          borderColor="text.text3"
          alignSelf="center"
          display="flex"
        >
          <Text fontSize={10} lineHeight={1} textAlign="center">
            {key}
          </Text>
        </Center>
      ))}
    </Flex>
  );
};

export default HotKey;
