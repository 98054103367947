import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  popoverAnatomy.keys,
);

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = definePartsStyle({
  body: {
    background: 'background.bg2',
    color: 'text.text7',
    outline: 'none !important',
  },
  closeButton: {
    color: 'text.text7',
  },
  arrow: {
    background: 'background.bg2',
    color: 'background.bg2',
    bg: 'background.bg2',
    boxShadow: 'none',
  },
  content: {
    [$arrowBg.variable]: 'background.bg2',
    outline: 'none',
    borderColor: 'background.bg2',
    overflow: 'hidden',
  },
});

export const popoverTheme = defineMultiStyleConfig({ baseStyle });
