import { useToast } from '@chakra-ui/react';

import configs from '../configs';
import { ERROR_MESSAGE } from '../constants/errorMessage';

const useAppToast = () => {
  const toast = useToast();

  const showErrorToast = (message?: string) => {
    toast({
      title: 'ERROR',
      description: message ?? ERROR_MESSAGE,
      status: 'error',
      duration: configs.ERROR_TOAST_DURATION,
      isClosable: true,
      position: 'top',
    });
  };

  //TODO: showSuccessToast

  return { showErrorToast };
};

export default useAppToast;
