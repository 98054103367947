import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import Logout from './Logout.tsx';

const Routers = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect().then();

    return;
  }

  return (
    <Routes>
      <Route index element={<>authorize</>} />
      <Route path="logout" element={<Logout />} />
    </Routes>
  );
};

export default Routers;
